import { useState } from "react";
import Modal from "../../../shared/components/UIElements/Modal";
import sendRequest from "../../../shared/sendRequest";

const SetBookingFormSubmitionDt = ({programmingId,onSet}) => {

    const [showModal,setShowModal ] = useState(false);
    const [date, setDate] = useState((new Date()).toISOString().split('T')[0])

    const setBookingSubmissionDate = async () => {
        await sendRequest('PATCH',`coordinator/programmings/${programmingId}/set-booking-submission-dt`,{date})
        onSet()
    }

    return <div>
    
        <button onClick={() => setShowModal(true)}>Set booking form submission date</button>

        {showModal && <Modal onSubmit={ e => e.preventDefault()} hideModal={() => setShowModal(false)}>
        <input type="date" value={date} onChange={ e => setDate(e.target.value)} />
        <button onClick={setBookingSubmissionDate} className="ml20">Set date</button>
        </Modal>}
    </div>
        
}

export default SetBookingFormSubmitionDt

