import { useState } from "react";
import Modal from "../../../shared/components/UIElements/Modal";
import sendRequest from "../../../shared/sendRequest";

const EditContainer = ({onUpdate, className, inspectionId, container}) => {
    const [showModal,setShowModal ] = useState(false);
    const [curContainer,setCurContainer ] = useState(container || {
        container_nbr:'',
        kind:'',
        status:'',
        seal_nbr:''
    });

    const ContainerCreationHandler = async e => {
        e.preventDefault()
        const payload = (({id,container_nbr,kind,status,seal_nbr}) => ({id, container_nbr,kind,status,seal_nbr}))(curContainer)
        await sendRequest('PUT',`translator/inspections/${inspectionId}/container`,payload)
        onUpdate()
        setShowModal(false)
    }

    return <div className={` ${className}`}>
        <div>
            <button className="" onClick={() => setShowModal(true)}>edit</button>
        </div>
        {showModal && <Modal onSubmit={ContainerCreationHandler} hideModal={() => setShowModal(false)}>
            <h4 className="mt0">Create Container</h4>
            <label>
                Container number
                <input placeholder="container number" value={curContainer.container_nbr} onChange={ e => setCurContainer({...curContainer, container_nbr:e.target.value})} />
            </label>
            <label>
                Seal number
                <input placeholder="seal number" value={curContainer.seal_nbr} onChange={ e => setCurContainer({...curContainer, seal_nbr:e.target.value})} />
            </label>
            <label>
                Container kind
                <select placeholder="container number" value={curContainer.kind} onChange={ e => setCurContainer({...curContainer, kind:e.target.value})} >
                    <option value="">Choose kind</option>
                    <option value="20'ST">20'ST</option>
                    <option value="40'ST">40'ST</option>
                    <option value="40'HC">40'HC</option>
                    <option value="45'HC">45'HC</option>
                </select>
            </label>
            <label>
                Container status
                <select placeholder="container number" value={curContainer.status} onChange={ e => setCurContainer({...curContainer, status:e.target.value})} >
                <option value="">Choose status</option>
                <option value="good">Good</option>
                <option value="damaged">Damaged</option>
                <option value="seriousDamaged">Serious Damaged</option>
                </select>
            </label>
            <button type="submit">Update</button>
        </Modal>}
    </div>
}

export default EditContainer