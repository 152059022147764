import { useCallback, useState, useEffect } from "react"
import { Link } from "react-router-dom"

import Paginator from "../../shared/components/UIElements/Paginator"
import sendRequest from "../../shared/sendRequest"

const CurrenciesPage = () => {

    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(25)
    const [pagination, setPagination] = useState({
        page:0,
        perPage:0,
        pages:0,
        count:0
    })

    const [programmings, setProgrammings] = useState([])

    const getProgrammings = useCallback( async() => {
        const {currencies,pagination} = await sendRequest('GET',`currencies?perPage=${perPage}&page=${page}`);
        setPagination(pagination)
        setProgrammings(currencies)
    },[perPage,page]);

    useEffect( () => {
        getProgrammings()
    },[getProgrammings])

    return <div className="page">
        <header>
            <div  style={{flex:1}}>
                <div style={{ display:"flex", alignItems: 'center', justifyContent:'space-between'}}>
                    <h3>Currencies</h3>
                    <Link to="/currency"><button className="blue">+ Create Currency</button></Link>
                </div>
            
                <Paginator pagination={pagination} onChangePage={setPage} onChangePerPage={setPerPage} />
            </div>
    
        </header>
        <main className="scrollable">

<table className="card">
    <thead>
        <tr>
            <th></th>
            <th>Name</th>
            <th>Code</th>
            <th>Symbol</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
    {programmings.map( (po, index) => <tr key={po.id} >
        <td className={`center `}>{(perPage*(page-1)) + index + 1}</td>
        <td>{po.name}</td>
        <td className={`center `}>{po.code}</td>
        <td className={`center `}>{po.symbol}</td>

        <td><Link to={`/currency/${po.id}`}><button>edit</button></Link></td>
    </tr>)}
    </tbody>
</table>
</main>
    </div>
}

export default CurrenciesPage