import { Route, Routes } from "react-router-dom"

import DailyReports from "./pages/DailyReports"
import Structure from "./pages/Structure"
import AnalystAssignment from "./pages/AnalystAssignment"
import Analysis from "./pages/Analysis"
import BackOfficeAnalysis from "./pages/BackOfficeAnalysis"

const DailyReportsRoutes = () => {
    return <Routes>
        <Route path="/daily-reports" element={<DailyReports />}/>

        <Route path="/daily-reports/structure" element={<Structure />}/>
        <Route path="/daily-reports/analysis" element={<Analysis />}/>
        <Route path="/daily-reports/analyst-assignment" element={<AnalystAssignment />}/>

        <Route path="/daily-reports/back-office-analysis" element={<BackOfficeAnalysis />}/>

    </Routes>
}
export default DailyReportsRoutes