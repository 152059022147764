import { useCallback, useEffect, useState } from "react";
import UploadPOPlacement from "../components/UploadPOPlacement";
import sendRequest from "../../shared/sendRequest";
import DownloadButton from "../../shared/components/DownloadButton";
import { Button } from "../../shared/components/UIElements/Dumbs";
import moment from "moment";

function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
const POPlacements = () => {
    const [pops, setPOPs] = useState([])

    const [busy, setBusy] = useState(false)
    const [customer, setCustomer] = useState()
    const [customers, setCustomers] = useState([])

    const getCustomers = useCallback( async (e) => {
        try {
            const response = await sendRequest('GET','customers?perPage=99999')
            setCustomers(response.customers)
            if(!customer) setCustomer(response.customers.find( customer => customer.primary).id)
        }catch(er){ console.log(er)} 
    },[customer])

    useEffect( () => {
        getCustomers()
    },[getCustomers])


    const getPOPs = useCallback( async (e) => {
        try {
            const response = await sendRequest('GET','po-placements')
            setPOPs(response.pops)
        }catch(er){ console.log(er)} 
    },[])

    const deleteFile = async (popId) => {
        if(!window.confirm('Confirm')) return false
        await sendRequest('DELETE','po-placements/' + popId)
        getPOPs()
    }
    const revertImport = async (popId) => {
        await sendRequest('PUT','po-placements/revert-import/' + popId)
        getPOPs()
    }

    const processCompanies = async (popId) => {
        try {
            await sendRequest('POST','po-placements/process-companies',{popId})
            getPOPs()
        }catch(er){ console.log(er)} 
    }
    const processCountries = async (popId) => {
        try {
            await sendRequest('POST','po-placements/process-countries',{popId})
            getPOPs()
        }catch(er){ console.log(er)} 
    }
    const processPorts = async (popId) => {
        try {
            await sendRequest('POST','po-placements/process-ports',{popId})
            getPOPs()
        }catch(er){ console.log(er)} 
    }
    const processProviders = async (popId) => {
        try {
            await sendRequest('POST','po-placements/process-providers',{popId})
            getPOPs()
        }catch(er){ console.log(er)} 
    }
    const processPOModels = async (popId) => {
        try {
            await sendRequest('POST','po-placements/process-po-models',{popId})
            getPOPs()
        }catch(er){ console.log(er)} 
    }
    const processDuplicity = async (popId) => {
        try {
            await sendRequest('POST','po-placements/process-duplicity',{popId})
            getPOPs()
        }catch(er){ console.log(er)} 
    }
    // const processPOs = async (popId) => {
    //     try {
    //         await sendRequest('POST','po-placements/process-pop',{popId})
    //         getPOPs()
    //     }catch(er){ console.log(er)} 
    // }

    const parseExcel = async (popId) => {
        try {
            await sendRequest('POST','po-placements/parse-excel',{popId})
            getPOPs()

        }catch(er){ console.log(er)} 
    }
    
    
    const makeAssignment = async (popId) => {
        setBusy(true)
        try {
            await sendRequest('GET','po-placements/make-assignments/' + popId)
            getPOPs()
            alert('Assignation completed')
        }catch(er){ console.log(er)} 
        setBusy(false)
    }
    const generateAssignations = (popId) => {
        _downloadExcel( 'po-placements/generate-assignations/' + popId )
    }
    const generateFiltered = (popId) => {
        _downloadExcel( 'po-placements/generate-filtered/' + popId )
    }

    const _downloadExcel = async url => {
        try {
            const response = await sendRequest('GET',url)
            const buff = Buffer(response.buffer,'base64');
            var blob = new Blob([buff], {'type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            var downloadURL = URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = downloadURL;
            a.download = response.name;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }catch(er){ console.log(er)} 
    }

    useEffect( () => {
        getPOPs()
    },[getPOPs])

    return <div className="page">
        <header><h3>PO Placements</h3></header>
        <main className="scrollable">
            <div className="mx20">
                <select value={customer} onChange={ e => setCustomer( e.target.value )}>
                    {customers.map(customer => <option key={customer.id} value={customer.id}> {customer.alias && `(${customer.alias}) `} {customer.name}</option>)}
                </select>
                <UploadPOPlacement customer={customer} addToPops={getPOPs} />
            </div>
        
        <table className="card">
            <thead>
                <tr>
                    <th>File</th>
                    <th>Assignation Date</th>
                    <th>Customer</th>
                    <th>Size</th>
                    <th>POs</th>
                    <th>Models</th>
                    <th></th>
                    <th></th>
                    <th>Imported</th>

                    <th>Companies processed</th>
                    <th>Countries processed</th>
                    <th>Ports processed</th>
                    <th>Providers processed</th>
                    <th>PO-Model</th>
                    <th>Duplicity</th>

                    <th>Filtered</th>
                    <th>Assignations</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {pops?.map( pop => <tr key={pop.id}>
                    <td>{pop.name}</td>
                    <td>{pop.assignation}</td>
                    <td>{pop.customer?.alias}<br/>{pop.customer?.name}</td>
                    <td className="center noWrap"><small>{pop.size && formatBytes(pop.size)}</small></td>
                    <td className="center">{pop.pos}</td>
                    <td className="center">{pop.models}</td>
                    <td className="center">
                        {!pop.imported  && <button onClick={ () => deleteFile(pop.id)}>Delete</button>}
                        {pop.imported && !pop.processed && <button onClick={ () => revertImport(pop.id)}>Revert Import</button>}
                    </td>
                    <td><DownloadButton url={`po-placements/download-excel/${pop.id}`} filename={pop.name} /></td>

                    <td className="center">
                        {pop.imported ? 
                            <div>yes <br/>{!pop.processed && <button className="link" onClick={() => parseExcel(pop.id)}>re-import</button>}</div> 
                            :   <button onClick={() => parseExcel(pop.id)}>Import</button>
                        }
                    </td>
                    <td className="center">

                        {pop.companiesProcessed ? 
                            <div>yes</div> 
                            : (pop.imported && <button onClick={() => processCompanies(pop.id)}>Process Companies</button>)
                        }
                    </td>
                    <td className="center">
                        {pop.countriesProcessed ? 
                            <div>yes </div> 
                            : (pop.imported && <button onClick={() => processCountries(pop.id)}>Process Countries</button>)
                        }
                    </td>
                    <td className="center">
                        {pop.portsProcessed ? 
                            <div>yes </div> 
                            : (pop.imported && <button onClick={() => processPorts(pop.id)}>Process Ports</button>)
                        }
                    </td>
                    <td className="center">
                        {pop.providersProcessed ? 
                            <div>yes <br/><button className="link" onClick={() => processProviders(pop.id)}>re-process Providers</button></div> 
                            : (pop.imported && <button onClick={() => processProviders(pop.id)}>Process Providers</button>)
                        }
                    </td>
                    <td className="center">
                        {pop.poModelProcessed ? 
                            <div>yes</div> 
                            : (pop.imported && <button onClick={() => processPOModels(pop.id)}>Process PO-Model</button>)
                        }
                    </td>
                    <td className="center">
                        {pop.checkDupicityProcessed ? 
                            <div>yes</div> 
                            : (pop.imported && <button onClick={() => processDuplicity(pop.id)}>Process Duplicity</button>)

                        }
                    </td>
                    <td className="center">{pop.processed && <button onClick={() => generateFiltered(pop.id)}>Generate</button>}</td>
                    <td className="center">{pop.processed && <button onClick={() => generateAssignations(pop.id)}>Generate</button>}</td>
                    <td className="center">
                        {pop.assigned ? 
                            <div>{moment(pop.assigned).format('DD/MMM/Y')} <br/><Button disabled={busy} className="link" onClick={() => makeAssignment(pop.id)}>re-assign</Button></div> 
                            : (pop.processed && <Button disabled={busy} onClick={() => makeAssignment(pop.id)}>Assign</Button>)
                        }
                    </td>
                </tr>)}
            </tbody>
        </table>
        </main>
    </div>
}
export default POPlacements