import { useState } from "react"
import sendRequest from "../../../shared/sendRequest"

const CreatePOs = ({onPOAdd, port, company, country, provider}) => {

    const [po, setPO] = useState('')



    const handleKeyDown = (e) => {
        if (e.code === 'Enter') createPO()
    }
    
    const createPO = async () => {
        const {po : _po} = await sendRequest('POST',`coordinator/pos/manual`,{
            _provider:provider?.id,
            _country:country?.id,
            _company:company?.id,
            _port:port?.id,
            po_nbr:po
        })
        onPOAdd(_po)
        setPO('')
    }
    return <>
        <div className="card">

            <h4>Filters</h4>
            <div style={{display:'flex'}}>
             
                <label className="ml10">
                    Country
                    <input disabled defaultValue={country?.name} />
                </label>
                <label className="ml10">
                    Provider
                    <input disabled defaultValue={provider?.name} />
                </label>
                <label className="ml10">
                    Company
                    <input disabled defaultValue={company?.name} />
                </label>
                <label className="ml10">
                    Port
                    <input disabled defaultValue={port?.name} />
                </label>
                
            </div>
            <div style={{display:'flex'}}>
                <label className="mr20">
                    POs
                    <input onKeyPress={handleKeyDown} value={po} onChange={ e => setPO(e.target.value)}/> 
                </label>        
                <button onClick={createPO}>Add</button>        
            </div>
        </div>
    </>
}
export default CreatePOs