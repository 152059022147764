import { useContext, useState } from 'react'
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import BackDrop from '../components/UIElements/BackDrop';
import { AppContext } from '../context/AppContext'
import LngSelector from './components/LngSelector';
import './MainLayout.scss'
import Menu from './Menu'

const MenuWrap = props => {
    return ReactDOM.createPortal(<div className='menu-overlay'><Menu /></div>,document.getElementById('menu-hook'))
}
const MenuOverlay = props => {
    const hideMenu = () =>{
        props.hideMenu()
    }
    return <>
        <BackDrop onClick={hideMenu} />
        <MenuWrap {...props} />
    </>
    
}

const MainLayout = props => {
    const { t } = useTranslation();
    const app = useContext(AppContext)

    const [showMenu,setShowMenu] = useState(false);
    
    return <>
        {app.isLoggedIn && <Menu />}

        {showMenu && <MenuOverlay hideMenu={() => setShowMenu(false)} />}

        <div className="app">
            {app.isLoggedIn &&<header>
                <div>
                    <button onClick={ () => setShowMenu( !showMenu )} className='toggleMenuButton'>...</button>
                </div>
                <div>
                    <LngSelector />
                    <small> { app?.user?.name } </small>
                    {app.isLoggedIn && <button className="link" onClick={app.logout}>{t('Log out')}</button>} 
                </div>
            </header> }
            <main>
                {props.children}
            </main>
            <footer>stage:{ process.env.REACT_APP_ENV } <b className='ml10'>nb<span style={{color:'blue'}}>_</span></b></footer>
        </div>
    </>
}
export default MainLayout