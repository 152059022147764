import moment from "moment"
import { useCallback, useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Paginator from "../../../shared/components/UIElements/Paginator"
import { AppContext } from "../../../shared/context/AppContext"
import sendRequest from "../../../shared/sendRequest"


const POsPage = () => {

    const {user} = useContext(AppContext)


    const [perPage, setPerPage] = useState(25)
    const [page, setPage] = useState(1)
    const [pagination, setPagination] = useState()
    
    const [pos, setPOs] = useState([])
    
    const getPOs = useCallback( async() => {
        const {pos,pagination} = await sendRequest('GET',`coordinator/pos?perPage=${perPage}&page=${page}`);
        setPagination(pagination)
        setPOs(pos)
    },[perPage,page]);

    useEffect( () => {
        getPOs()
    },[getPOs])

    if(!user?.isCoordinator) return <div className="m20"><h2>You are not a Coordinator</h2></div>

    return <div className="page">
        <header>
            <div  style={{flex:1}}>
                <div style={{ display:"flex", alignItems: 'center', justifyContent:'space-between'}}>
                    <h3>Purchase Orders</h3>
                    {false && <Link to="/create-po"><button className="blue">+ Create PO</button></Link>}
                </div>
                <Paginator pagination={pagination} onChangePage={setPage} onChangePerPage={setPerPage} />
                
            </div>
    
        </header>
        <main className="scrollable">

            <table className="card">
                <thead>
                    <tr>
                        <th></th>
                        <th>PO Placement</th>
                        <th>PO nbr</th>
                        <th>Early Ship Date</th>
                        <th>Late Ship Date</th>
                        <th>Country</th>
                        <th>Company</th>
                        <th>Port</th>
                        <th>Provider</th>
                        <th>Programmed</th>
                        <th>Created</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {pos.map( (po, index) => <tr key={po.id}>
                    <td className={`center ${po._programming ? 'yellow' : ''}`}>{(perPage*(page-1)) + index + 1}</td>
                    <td>{po.POPlacement?.name}</td>
                    <td>{po["po_nbr"]}</td>
                    <td>{po["early_ship_date"] && moment(po["early_ship_date"]).format('DD/MMM/Y')}</td>
                    <td>{po["late_ship_date"] && moment(po["late_ship_date"]).format('DD/MMM/Y')}</td>
                    <td>{po._country?.name}</td>
                    <td>{po._company?.name}</td>
                    <td>{po._port?.name}</td>
                    <td>{po._provider?.name}</td>
                    <td className={`center ${po._programming ? 'yellow' : ''}`}>{po._programming ? 'yes' : 'no'}</td>
                    <td>{po.createdAt && moment(po.createdAt).format('DD/MMM/Y')}</td>
                    <td><Link to={`/po/${po.id}`}><button>edit</button></Link></td>
                </tr>)}
                </tbody>
            </table>
        </main>
    </div>
}

export default POsPage