
import { useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import sendRequest from "../../shared/sendRequest";

const CreateCountryPage = () => {
    const navigate = useNavigate();

    const [country, setCountry] = useState({
        name:'',
        inspectable:true,
        notes:''
    })

    const createCountry = async e =>{
        e.preventDefault()
        await sendRequest('POST','countries',country)
        navigate('/countries')
    }

    return <div className="page">
        <header>
            <h3>Create Country</h3>
            <Link to="/countries"><button className="link">{'<'} back</button></Link>
        </header>
        <main className="scrollable">
            <form onSubmit={createCountry} className="card">
                <label>
                    Name
                    <input placeholder="Name" value={country.name} onChange={ e => setCountry( state => ({...state, name:e.target.value}))} />
                </label>
    
                <label>
                    Inspectable
                    <input type="checkbox" checked={country.inspectable} onChange={ e => setCountry( state => ({...state, inspectable:!country.inspectable}))} />
                </label>

                <label>
                    Notes
                    <textarea placeholder="Notes" value={country.notes} onChange={ e => setCountry( state => ({...state, notes:e.target.value}))} />
                </label>
                
                <button type="submit">Create Country</button>
            </form>


        </main>
    </div>
}

export default CreateCountryPage;