import { useCallback, useEffect, useRef, useState } from "react";
import sendRequest from "../../../shared/sendRequest";


const UploadReport = ({inspection,onComplete}) => {

    const filePickerRef = useRef();
    const [file, setFile] = useState();
    const [isValid, setIsValid] = useState(false);

    const pickedHandler = event => {
        let pickedFile;
        let fileIsValid = isValid;
        if(event.target.files && event.target.files.length === 1){
            pickedFile = event.target.files[0];
            setFile(pickedFile);
            fileIsValid = true;
        }else{
            fileIsValid = false;
        }
        setIsValid(fileIsValid);
    };
    const pickFileHandler = () => {
        filePickerRef.current.click();
    };
    const clearFileInput = () => {
        filePickerRef.current.value = null;
    };
    const getPresignedURL = useCallback(async () => {
        if(!file) return false;
        const payload = {
            name: file.name,
            type: file.type
        }
        try {
            const response = await sendRequest('POST','translator/inspections/'+inspection+'/presignedurl',payload)
            return {url:response.url}
        }catch(er){ console.log(er)} 

    },[inspection,file])

    const storeInS3 = useCallback( async (url) => {
        if(!file) return false;
        const myHeaders = new Headers({ 'Content-Type': file.type });
        const fetchResponse = await fetch(url, {
            method: 'PUT',
            headers: myHeaders,
            body: file
        });
        if (fetchResponse.ok){
            return true;
        }
        return false;
    },[file])

    
    const uploadFile = useCallback( async() => {

        const presignedURL = await getPresignedURL()
        if(!presignedURL){
            console.log('error al obtener la URL')
            clearFileInput()
            return false
        }
        const uploaded = await storeInS3(presignedURL.url)
        if(uploaded){
            console.log('uploaded')
            onComplete()
        }
        clearFileInput()
    },[getPresignedURL,storeInS3,onComplete])


    useEffect( () => {
        if(file){
            uploadFile()
        }
    },[file,uploadFile]) 

    return <div>
        <input
            ref={filePickerRef}
            style={{display:'none'}} 
            type="file"
            accept=".zip"
            onChange={pickedHandler}
            />
        <button type="button" onClick={pickFileHandler}>Upload Report (zip)</button>
    </div>
}
export default UploadReport