
import { useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import CoordinatorSelector from "../../shared/components/CoordinatorSelector";
import CountrySelector from "../../shared/components/CountrySelector";
import sendRequest from "../../shared/sendRequest";

const CreatePOPage = () => {

    const navigate = useNavigate();

    const [po, setPO] = useState({
        country:'',
        coordinator:''
    })

    const createPO = async e =>{
        e.preventDefault()
        await sendRequest('POST','pos',po)
        navigate('/pos')
    }


    return <div className="page">
        <header>
            <h3>Edit Purchase Order</h3>
            <Link to="/pos"><button className="link">{'<'} back</button></Link>
        </header>
        <main className="scrollable">
            <form onSubmit={createPO} className="card">
  
                <label>
                    Country
                    <CountrySelector inspectables value={po.country} onChange={ country => setPO( state => ({...state,country}) )} />
                </label>
                <label>
                    Coordinator
                    <CoordinatorSelector coordinatorId={po.coordinator} onChange={ coordinator => setPO( state => ({...state,coordinator}) )} />
                </label>
                
                
                <button type="submit">Create PO</button>
            </form>

        </main>
    </div>
}

export default CreatePOPage;