import { useEffect, useState } from "react"
import sendRequest from "../../shared/sendRequest";


const KindSelector = ({value, onChange}) =>{
    const[kinds, setKinds] = useState([])

    const getKinds = async () => {
        try{
            const response = await sendRequest('GET','inspectors/kinds');
            setKinds(response.kinds)
        }catch(er){ console.log(er) }

    }
    useEffect( () => {
        getKinds()
    },[])
    return <select value={value} onChange={ e => onChange( e.target.value)}>
        <option value="">Choose a Kind</option>
        {kinds?.map( kind => <option key={kind.id} value={kind.id}>{kind.name}</option>)}
    </select>
}

export default KindSelector