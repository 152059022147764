import { useState, useEffect } from "react";
import Modal from "../../../shared/components/UIElements/Modal";

const SetContainersCount = ({count, onCountSet, className}) => {

    const [showModal,setShowModal ] = useState(false);
    const [noContainers,setNoContainers ] = useState(count || '');

    const CountSetHandler = e => {
        e.preventDefault()
        onCountSet(noContainers)
        setShowModal(false)
    }
    useEffect( () => {
        setNoContainers(count)
    },[count]) 
    return <div className={` ${className}`}>
        <div>
            <button onClick={() => setShowModal(true)}>Set Containers Count</button>
        </div>
        {showModal && <Modal onSubmit={CountSetHandler} hideModal={() => setShowModal(false)}>
            <input type="number" value={noContainers} onChange={ e => setNoContainers(e.target.value)} />
            <button type="submit">Set</button>
        </Modal>}
    </div>
}

export default SetContainersCount