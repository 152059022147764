import ReactDOM from 'react-dom';
import BackDrop from './BackDrop';
import './Modal.scss';
import { AiOutlineCloseCircle } from "react-icons/ai";
const ModalOverlay = props => {
    const content = (
        <div className={`modal ${props.className}`} style={props.style}>
            <div className='close-btn' onClick={props.hideModal}><AiOutlineCloseCircle /></div>
            {props.header && <header className="modal-header"><h1>{props.header} im a modal</h1></header>}
            <form className="modal-container" onSubmit={props.onSubmit ? props.onSubmit : e => e.preventDefault }>
                <div>{props.children}</div>
            </form>
        </div>
    );

    return ReactDOM.createPortal(content,document.getElementById('modal-hook'))
}
const Modal = props => {
    const hideModal = () =>{
        props.hideModal()
    }
    return <>
    <BackDrop onClick={hideModal} />
    <ModalOverlay {...props} />
    </>
}

export default Modal