import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import sendRequest from "../../shared/sendRequest";

const CreateInspectorManegerPage = () => {

    const navigate = useNavigate();

    const [manager, setManager] = useState({
        user:'',
        level:"Inspector",
        description:''
    })

    const levels = ["Administrator", "Inspector"];
    const [users, setUsers] = useState([])
    
    const getUsers = async() => {
        const response = await sendRequest('GET','users');
        setUsers(response.users)
    }

    useEffect( () => {
        getUsers()
    },[])

    const createManager = async e => {
        e.preventDefault()
        await sendRequest('POST','inspectors/managers',manager);
        navigate('/inspector-managers')
    }
    return <div className="page">
        <header>
            <h3>Create Inspector Manger</h3>
            <Link to="/inspector-managers"><button className="link">{'<'} back</button></Link>
        </header>
        <main>
            <form onSubmit={createManager}>
                <select value={manager.level} onChange={ e => setManager( state => ({...state, level:e.target.value}))}>
                    { levels?.map( level => <option value={level} key={level}>{level}</option> )}
                </select>

                <select value={manager.user} onChange={ e => setManager( state => ({...state, user:e.target.value}))}>
                    <option value="" >Choose an user</option>
                    { users?.map( user => <option value={user.id} key={user.id}>{user.name}</option> )}
                </select>

                <label>
                    <textarea value={manager.description} onChange={ e => setManager( state => ({...state, description:e.target.value}))} />
                </label>

                <button type="submit">Create Manager</button>
            </form>
        </main>
    </div>
}

export default CreateInspectorManegerPage