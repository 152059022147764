import { useState } from "react";
import Modal from "../../../shared/components/UIElements/Modal";
import sendRequest from "../../../shared/sendRequest";

const CreateContainer = ({onCreation, className, inspectionId}) => {
    const [showModal,setShowModal ] = useState(false);
    const [container,setContainer ] = useState({
        container_nbr:'',
        seal_nbr:'',
        kind:'',
        status:''
    });

    const ContainerCreationHandler = async e => {
        e.preventDefault()
        await sendRequest('POST',`translator/inspections/${inspectionId}/container`,container)
        onCreation()
        setShowModal(false)
    }

    return <div className={` ${className}`}>
        <div>
            <button className="blue" onClick={() => setShowModal(true)}>+ add Container</button>
        </div>
        {showModal && <Modal onSubmit={ContainerCreationHandler} hideModal={() => setShowModal(false)}>
            <h4 className="mt0">Create Container</h4>
            <label>
                Container number
                <input placeholder="container number" value={container.container_nbr} onChange={ e => setContainer({...container, container_nbr:e.target.value})} />
            </label>
            <label>
                Seal number
                <input placeholder="seal number" value={container.seal_nbr} onChange={ e => setContainer({...container, seal_nbr:e.target.value})} />
            </label>
            <label>
                Container kind
                <select placeholder="container number" value={container.kind} onChange={ e => setContainer({...container, kind:e.target.value})} >
                    <option value="">Choose kind</option>
                    <option value="20'ST">20'ST</option>
                    <option value="40'ST">40'ST</option>
                    <option value="40'HC">40'HC</option>
                    <option value="45'HC">45'HC</option>
                </select>
            </label>
            <label>
                Container status
                <select placeholder="container number" value={container.status} onChange={ e => setContainer({...container, status:e.target.value})} >
                <option value="">Choose status</option>
                <option value="good">Good</option>
                <option value="damaged">Damaged</option>
                <option value="seriousDamaged">Serious Damaged</option>
                </select>
            </label>
            <button type="submit">Create</button>
        </Modal>}
    </div>
}

export default CreateContainer