import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

i18n
    .use(Backend)
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        defaultLocale: 'en',
        fallbackLng: 'en',
        //resources,
        //lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option
        ns: ['translation'],
        defaultNS: 'translation',
        backend: {
            loadPath: process.env.REACT_APP_LOCALES_API + 'locales/{{lng}}/{{ns}}.json'
        },

        interpolation: {
        escapeValue: false // react already safes from xss
        }
  });

  export default i18n;