import { useCallback, useState } from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import './App.scss';


import LoginPage from './auth/pages/LoginPage';

import Customer from './customers/pages/Customer';
import CustomersPage from './customers/pages/Customers';
import CreateCustomer from './customers/pages/CreateCustomer';

import Currencies from './currencies/pages/Currencies';
import CustomBrokers from './custom-brokers/pages/CustomBrokers';
import CreateCustomBrokers from './custom-brokers/pages/CreateCustomBroker';
import CustomBroker from './custom-brokers/pages/CustomBroker';

import Home from './home/pages/Home';
import CreateInspection from './inspections/pages/CreateInspection';
import Inspection from './inspections/pages/Inspection';

//import DailyReports from './daily-reports/pages/DailyReports';

import DailyArrangements from './daily-arrangements/pages/DailyArrangements';
import DailyArrangement from './daily-arrangements/pages/DailyArrangement';
import DailyArrangementsConsolidated from './daily-arrangements/pages/DailyArrangementsConsolidated';

import CountriesPage from './countries/pages/CountriesPage';
import CountryPage from './countries/pages/CountryPage';
import CreateCountryPage from './countries/pages/CreateCountryPage';

import Inspections from './inspections/pages/Inspections';
import MainLayout from './shared/layouts/MainLayout';

import { AppContext, setToken, unsetToken, setUser, unsetUser } from './shared/context/AppContext';
import RegisterPage from './auth/pages/RegisterPage';


import LocationPage from './locations/pages/LocationPage';
import LocationsPage from './locations/pages/LocationsPage';
import CreateLocationPage from './locations/pages/CreateLocationPage';


import CreateUser from './users/pages/CreateUser';
import UsersPage from './users/pages/UsersPage';
import UserPage from './users/pages/UserPage';

import ProviderPage from './providers/pages/ProviderPage';
import CreateProviderPage from './providers/pages/CreateProviderPage';
import ProvidersPage from './providers/pages/ProvidersPage';

import PortPage from './ports/pages/PortPage';
import CreatePortPage from './ports/pages/CreatePortPage';
import PortsPage from './ports/pages/PortsPage';

import POPage from './pos/pages/POPage';
import CreatePOPage from './pos/pages/CreatePOPage';
import POsPage from './pos/pages/POsPage';

import TranslatorProgrammings from './_translators/programmings/pages/Programmings';
import TranslatorInspection from './_translators/inspections/pages/Inspection';
import TranslatorInspections from './_translators/inspections/pages/Inspections';

import CoordinatorPOsPage from './_coordinators/pos/pages/POsPage';
import CoordinatorProgrammingsPage from './_coordinators/programmings/pages/ProgrammingsPage';
import CoordinatorCreateProgramming from './_coordinators/programmings/pages/CreateProgramming';
import CoordinatorCreateCFSProgramming from './_coordinators/programmings/pages/CreateCFSProgramming';
import CoordinatorProgramming from './_coordinators/programmings/pages/ProgrammingPage';
import CoordinatorDailyProgrammings from './_coordinators/daily-programmings/pages/DailyProgrammings';
import CoordinatorDailyProgramming from './_coordinators/daily-programmings/pages/DailyProgramming';


import ModelPage from './models/pages/ModelPage';
import CreateModelPage from './models/pages/CreateModelPage';
import ModelsPage from './models/pages/ModelsPage';

import CompaniesPage from './companies/pages/CompaniesPage';

import CoordinatorPage from './coordinators/pages/CoordinatorPage';
import CoordinatorsPage from './coordinators/pages/CoordinatorsPage';
import CreateCoordinatorPage from './coordinators/pages/CreateCoordinatorPage';

import InspectorsPage from './inspectors/pages/InspectorsPage';
import InspectorPage from './inspectors/pages/InspectorPage';
import CreateInspectorPage from './inspectors/pages/CreateInspectorPage';
import InspectorManagersPage from './inspectors/pages/InspectorManagersPage';
import CreateInspectorManegerPage from './inspectors/pages/CreateInspectorManegerPage';

import POPRoutes from './po-placements/POPRoutes';
import DailyReportsRoutes from './daily-reports/DailyReportsRoutes';
import WarehouseRoutes from './warehouses/WarehouseRoutes';
import BOInspectionRoutes from './_back-office/inspections/BOInspectionRoutes';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState((localStorage.getItem("token")));
  const [user, setUserState] = useState(localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")));
  
  const login = useCallback( (data) => {
    setIsLoggedIn(true);
    setUser(data.user);
    setUserState(data.user)
    setToken(data.token);
  },[])
  const logout = useCallback( () => {
    setIsLoggedIn(false);
    unsetUser();
    setUserState(null)
    unsetToken();
  },[])

  const routes = isLoggedIn 
  ? <Routes>

      <Route path="coordinator/pos" element={<CoordinatorPOsPage />}/>
      <Route path="coordinator/programmings" element={<CoordinatorProgrammingsPage />}/>
      <Route path="coordinator/programming/:id" element={<CoordinatorProgramming />}/>
      <Route path="coordinator/create-programming" element={<CoordinatorCreateProgramming />}/>
      <Route path="coordinator/create-cfs-programming" element={<CoordinatorCreateCFSProgramming />}/>
      <Route path="coordinator/daily-programmings" element={<CoordinatorDailyProgrammings />}/>
      <Route path="coordinator/daily-programming/:id" element={<CoordinatorDailyProgramming />}/>

      <Route path="translator/programmings" element={<TranslatorProgrammings />}/>
      <Route path="translator/inspections" element={<TranslatorInspections />}/>
      <Route path="translator/inspection/:id" element={<TranslatorInspection />}/>


      <Route path="/" element={<Home />}/>

      {/*<Route path="/daily-reports" element={<DailyReports />}/>*/}

      <Route path="/daily-arrangements" element={<DailyArrangements />}/>
      <Route path="/daily-arrangement/:id" element={<DailyArrangement />}/>
      <Route path="/daily-arrangements-consolidated" element={<DailyArrangementsConsolidated />}/>

      <Route path="/inspections" element={<Inspections />}/>
      <Route path="/inspection/:reference" element={<Inspection />}/>
      <Route path="create-inspection" element={<CreateInspection />}/>

      <Route path="customers" element={<CustomersPage />}/>
      <Route path="customer/:id" element={<Customer />}/>
      <Route path="create-customer" element={<CreateCustomer />}/>

      <Route path="currencies" element={<Currencies />}/>
      <Route path="custom-brokers" element={<CustomBrokers />}/>
      <Route path="create-custom-broker" element={<CreateCustomBrokers />}/>
      <Route path="custom-broker/:id" element={<CustomBroker />}/>

      <Route path="countries" element={<CountriesPage />}/>
      <Route path="country/:id" element={<CountryPage />}/>
      <Route path="create-country" element={<CreateCountryPage />}/>

      <Route path="locations" element={<LocationsPage />}/>
      <Route path="location/:id" element={<LocationPage />}/>
      <Route path="create-location" element={<CreateLocationPage />}/>


      <Route path="users" element={<UsersPage />}/>
      <Route path="create-user" element={<CreateUser />}/>
      <Route path="user/:id" element={<UserPage />}/>

      <Route path="provider/:id" element={<ProviderPage />}/>
      <Route path="create-provider" element={<CreateProviderPage />}/>
      <Route path="providers" element={<ProvidersPage />}/>

      <Route path="port/:id" element={<PortPage />}/>
      <Route path="create-port" element={<CreatePortPage />}/>
      <Route path="ports" element={<PortsPage />}/>
      
      <Route path="po/:id" element={<POPage />}/>
      <Route path="create-po" element={<CreatePOPage />}/>
      <Route path="pos" element={<POsPage />}/>
      
      <Route path="model/:id" element={<ModelPage />}/>
      <Route path="create-model" element={<CreateModelPage />}/>
      <Route path="models" element={<ModelsPage />}/>

      <Route path="companies" element={<CompaniesPage />}/>

      <Route path="coordinators" element={<CoordinatorsPage />}/>
      <Route path="coordinator/:id" element={<CoordinatorPage />}/>
      <Route path="create-coordinator" element={<CreateCoordinatorPage />}/>

      <Route path="inspectors" element={<InspectorsPage />}/>
      <Route path="create-inspector" element={<CreateInspectorPage />}/>
      <Route path="inspector/:id" element={<InspectorPage />}/>
      <Route path="inspector-managers" element={<InspectorManagersPage />}/>
      <Route path="create-inspector-manager" element={<CreateInspectorManegerPage />}/>

      <Route path="/register" element={<RegisterPage/>} />  
      <Route path="/login" element={<Navigate replace to="/"/>} />

  </Routes>
  : <Routes>
      <Route path="/login" element={<LoginPage />} />  
      <Route path="*" element={<Navigate replace to="/login"/>} />
  </Routes>
  
  return <AppContext.Provider value={{ isLoggedIn, login, logout, user }}>
    <Router>
      <MainLayout>
        { isLoggedIn && <POPRoutes />}
        { isLoggedIn && <DailyReportsRoutes />}
        { isLoggedIn && <WarehouseRoutes />}
        { isLoggedIn && <BOInspectionRoutes />}
        {routes}   
      </MainLayout>
    </Router>
  </AppContext.Provider>
}

export default App;
