import moment from "moment"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import sendRequest from "../../shared/sendRequest"

const Wharehouses = () => {
    const [warehouses, setWarehouses] = useState([])
    
    const getWarehouses = async() => {
        const {warehouses} = await sendRequest('GET','warehouses');
        setWarehouses(warehouses)
    }

    useEffect( () => {
        getWarehouses()
    },[])
    return <div className="page">
        <header>
            <h3>Warehouses</h3>
           <Link to="/create-warehouse"><button className="blue">+ Create Warehouse</button></Link>
        </header>
        <main className="scrollable">
            

            <table className="card">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Country</th>
                        <th>Address</th>
                        <th>Note</th>
                        <th>Created</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {warehouses.map( wh => <tr key={wh.id}>
                    <td>{wh.name}</td>
                    <td>{wh.country?.name}</td>
                    <td>{wh.address}</td>
                    <td>{wh.note}</td>
                    <td>{wh.createdAt && moment(wh.createdAt).format('DD/MMM/Y')}</td>
                    <td><Link to={`/warehouse/${wh.id}`}><button>edit</button></Link></td>
                </tr>)}
                </tbody>
            </table>
        </main>
    </div>
}

export default Wharehouses