import moment from "moment"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import sendRequest from "../../shared/sendRequest"

const UsersPage = () => {
    const [users, setUsers] = useState([])
    
    const getUsers = async() => {
        const {users} = await sendRequest('GET','users');
        setUsers(users)
    }

    useEffect( () => {
        getUsers()
    },[])
    return <div className="page">
        <header>
            <h3>Users</h3>
            <Link to="/create-user"><button className="blue">+ Create User</button></Link>
        </header>
        <main className="scrollable">
            

            <table className="card">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Country</th>
                        <th>City</th>
                        <th>is Dev</th>
                        <th>is Admin</th>
                        <th>is Coordinator</th>
                        <th>is Translator</th>
                        <th>is Back Office</th>
                        <th>is Analyst</th>
                        <th>Created</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {users.map( user => <tr key={user.id}>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.country?.name}</td>
                    <td>{user.city}</td>
                    <td>{user.isDev ? 'yes' : 'no'}</td>
                    <td>{user.isAdmin ? 'yes' : 'no'}</td>
                    <td>{user.isCoordinator ? 'yes' : 'no'}</td>
                    <td>{user.isTranslator ? 'yes' : 'no'}</td>
                    <td>{user.isBackOffice ? 'yes' : 'no'}</td>
                    <td>{user.isAnalyst ? 'yes' : 'no'}</td>
                    <td>{user.createdAt && moment(user.createdAt).format('DD/MMM/Y')}</td>
                    <td><Link to={`/user/${user.id}`}><button>edit</button></Link></td>
                </tr>)}
                </tbody>
            </table>
        </main>
    </div>
}

export default UsersPage