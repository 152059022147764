import { useState, useEffect } from "react";
import Modal from "../../shared/components/UIElements/Modal";
import sendRequest from "../../shared/sendRequest";

const ChargeCount = ({id, value, fcl, fclI, onChange, className}) => {

    const [showModal,setShowModal ] = useState(false);
    const [count,setCount ] = useState(value || '');

    const CountSetHandler = async e => {
        e.preventDefault()
        await sendRequest('PATCH',`daily-arrangements/${id}/charge-count`,{count})
        onChange()
        setShowModal(false)
    }
    useEffect( () => {
        setCount(value)
    },[value]) 
    return <>
        FCL: 
        {fclI ? 
        (fclI !== fcl) ? <b>{fcl} <small>in Programming</small>, {fclI} <small>in Inspection</small></b> : <b> {fclI}</b> 
        : <b>{fcl}  <small>(in Programming)</small></b>}
        <br/>
        Charge Count: <b>{value }</b>
        <div style={{float:'right'}}><button onClick={() => setShowModal(true)}>Set Charge Count</button></div>


        {showModal && <Modal onSubmit={CountSetHandler} hideModal={() => setShowModal(false)}>
            <input type="number" placeholder='0' value={count} onChange={ e => setCount(e.target.value) } />
            <button type="submit">Set</button>
        </Modal>}
    </>
}

export default ChargeCount