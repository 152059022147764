import { Route, Routes } from "react-router-dom"
import CreateWarehouse from "./pages/CreateWarehouse"
import Warehouse from "./pages/Warehouse"
import Warehouses from "./pages/Warehouses"


const WarehouseRoutes = () => {
    return <Routes>
        <Route path="/warehouses" element={<Warehouses />} />
        <Route path="/warehouse/:id" element={<Warehouse />} />
        <Route path="/create-warehouse" element={<CreateWarehouse />} />
    </Routes>
}
export default WarehouseRoutes