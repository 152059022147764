
import { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Link, useParams } from "react-router-dom";
import sendRequest from "../../shared/sendRequest";

const Customer = () => {
    const navigate = useNavigate();
    const {id} = useParams()

    const [customer, setCustomer] = useState({
        alias: "",
        name:'',
        key:'',
        email:'',
        phone:'',
        address:'',
        notes:''
    })

    const updateCustomer = async e =>{
        e.preventDefault()
        await sendRequest('PUT','customers/' + id ,customer)
        navigate('/customers')
    }

    const getCustomer = useCallback(async() => {
        if(!id) return false;
        const {customer} = await sendRequest('GET','customers/' + id);
        setCustomer(customer)
    },[id])

    useEffect( () => {
        getCustomer()
    },[getCustomer])

    return <div className="page">
        <header>
            <h3>Customer</h3>
            <Link to="/customers"><button className="link">{'<'} back</button></Link>
        </header>
        <main className="scrollable">
            <form onSubmit={updateCustomer} className="card">
                <label>
                    Alias
                    <input placeholder="Alias" value={customer.alias} onChange={ e => setCustomer( state => ({...state, alias:e.target.value}))} />
                </label>
                <label>
                    Name
                    <input placeholder="Name" value={customer?.name} onChange={ e => setCustomer( state => ({...state, name:e.target.value}))} />
                </label>
                <label>
                    Key
                    <input placeholder="Key" value={customer?.key} onChange={ e => setCustomer( state => ({...state, key:e.target.value}))} />
                </label>
                <label>
                    Email
                    <input type="email" placeholder="Email" value={customer?.email} onChange={ e => setCustomer( state => ({...state, email:e.target.value}))} />
                </label>
    
    
                <label>
                    Phone
                    <input placeholder="Phone" value={customer?.phone} onChange={ e => setCustomer( state => ({...state, phone:e.target.value}))} />
                </label>

                <label>
                    Address
                    <textarea placeholder="Address" value={customer?.address} onChange={ e => setCustomer( state => ({...state, address:e.target.value}))} />
                </label>
                <label>
                    Notes
                    <textarea placeholder="Notes" value={customer?.notes} onChange={ e => setCustomer( state => ({...state, notes:e.target.value}))} />
                </label>
                
                
                <button type="submit">Update Customer</button>
            </form>


        </main>
    </div>
}

export default Customer;