import { useState } from "react";
import sendRequest from "../sendRequest";

//let xhr = new XMLHttpRequest();

const DownloadButton = ({url,filename, label}) => {
    const [title, setTitle] = useState(label || 'Download')

    const download = async () => {
        setTitle('Downloading...')
        try {
            const response = await sendRequest('GET', url)
            setTitle('Done')
            const buff = Buffer(response.buffer,'base64');
            var blob = new Blob([buff], {'type': response.type});
            var downloadURL = URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = downloadURL;
            a.download = response.name;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a)
        }catch(er){ console.log(er)} 
        setTitle(label || 'Download')
        /*xhr.open('GET',url,true)
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Content-Type', 'application/json;charset=utf-8')
        if(localStorage.token) {
            xhr.setRequestHeader("Authorization", 'Bearer ' + localStorage.token )
        }
        xhr.onload = e => {
            setTitle('Done')
            let res = e.target;
            if(res.status === 200){
                //var blob = new Blob([res.response],res.response.type)
                //var downloadURL = URL.createObjectURL(blob);
                var downloadURL = URL.createObjectURL(res.response);
                var a = document.createElement("a");
                a.href = downloadURL;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

            }else{
                console.log(e.target.status,' :(');
            }
            setTitle('Download')
        }
        xhr.send();*/
        
    }
    return <button onClick={download}>{title}</button>
}
export default DownloadButton;