import { useEffect, useState } from "react";
import Modal from "../../shared/components/UIElements/Modal";
import moment from "moment"

const MultiDate = ({date, dates, onChange}) => {

    const [showModal,setShowModal ] = useState(false);

    const [tmp_date, setTmpDate] = useState('')


    const removeDate = (date) => {
        const dates_arr = [...dates]
        for( let i = 0; i < dates_arr.length; i++){ 
            if ( dates_arr[i] === date) { 
                dates_arr.splice(i, 1); 
                break;
            }
        }
        onChange({date:(dates_arr[0] ?? ''),dates:dates_arr})
    }

    useEffect( () => {
        if(tmp_date && dates){
            const datesSet = new Set(dates);
            datesSet.add(tmp_date);
            const dates_arr = Array.from(datesSet).sort()
            onChange({date:(dates_arr[0] ?? ''),dates:dates_arr})
            setTmpDate('')
        }
    },[tmp_date,dates,onChange])

    return <div>
        <div>
            <button onClick={() => setShowModal(true)}>{ date ? moment(date).format("DD/MMM/Y") : "<Ingresar fecha de carga>"} </button>
            {dates && dates.length > 1 && dates.map( (dt,i) => !!i && <span key={dt} style={{fontSize:"8pt" ,padding:'2px 6px', margin: "2px", background:"#ccc", borderRadius:"4px"}}>{moment(dt).format("DD/MMM/Y")}</span>)}
        </div>
        
        {showModal && <Modal onSubmit={ e => e.preventDefault()} hideModal={() => setShowModal(false)}>
        <input type="date" value={tmp_date} onChange={ e => setTmpDate(e.target.value)} />
        <ul>
            {dates && dates.map( date => <li key={date} >{moment(date).format("DD/MMM/Y")} <button onClick={ () => removeDate(date)}>remove</button></li>)}
        </ul>
        </Modal>}
    </div>
}

export default MultiDate;