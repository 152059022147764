import { useTranslation } from "react-i18next";


const Home = () => {
    const { t } = useTranslation();
    const fullReload = () => {
        window.location.reload();
        //window.location.href=window.location.href
    }
    return <div className="page">
        <header>
            <h3>{t('Home')}</h3>
        </header>
        <main className="m20">Nothing here yet...
        <button onClick={fullReload}>{t('Refresh Page')}</button>
        </main>
    </div>
}

export default Home;