import { useState, useEffect } from "react"
import CustomBrokerSelector from "../../../shared/components/CustomBrokerSelector"
import { Button } from "../../../shared/components/UIElements/Dumbs"
import sendRequest from "../../../shared/sendRequest"

const POCustomBroker = ({pos, containers, onAssign}) => {
    const [selected,setSelected] = useState([])
    const [customBrokers, setCustomBrokers] = useState([])
    const [customBroker, setCustomBroker] = useState('')
    const select = (id) => {
        if(selected.find( s => s === id)){
            setSelected(state => [...state.filter( s => s !== id)])
        }else{
            setSelected(state => [...state, id])
        }
    }
    const assignCustomBroker = async () => {
        
        if(customBroker){
            let sAnalyst = customBrokers.find( a => a.id === customBroker)
            if(!window.confirm(selected.length + ' PO will be assigned to ' + sAnalyst?.name)) return false
        }else{
            if(!window.confirm(selected.length + ' PO will be unassigned')) return false
        }
       
        await sendRequest('PUT',`back-office/inspections/assign-custom-broker`,{customBroker,selected});
        setCustomBroker('')
        setSelected([])
        onAssign()
    }

    const getBrokers =  async () => {
        try{
            const {customBrokers} = await sendRequest('GET',`custom-brokers?perPage=999999`);
            setCustomBrokers(customBrokers)
        }catch(er){ console.log(er) }
    }
    useEffect( () => {
        getBrokers()
    },[])
    return <>
        {!!selected.length && <div>
            {selected.length} selected
            <CustomBrokerSelector value={customBroker} onChange={setCustomBroker} />
            {/* <UserSelector kind="analysts" list={analysts} value={analyst} onChange={setAnalyst} /> */}
            <Button  onClick={assignCustomBroker}>Assign</Button>
        </div>}
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th>Container</th>
                    <th>PO</th>
                    <th>Country</th>
                    <th>Port</th>
                    <th>Custom Broker</th>
                </tr>
            </thead>
            <tbody>

                {containers?.map( c => {
                    return c.pos?.map(po => <tr key={c.id+'_'+po.id}>
                        <td><input checked={selected.includes(c.id+'_'+po.id)} onClick={() => select(c.id+'_'+po.id)} type="checkbox" onChange={ () => false} /></td>
                        <td>{c.container_nbr}</td>
                        <td>{po.po_nbr}</td>
                        <td>{po.origin_country}</td>
                        <td>{po.ship_port}</td>
                        <td>{(c._customBrokers?.find( cb => cb.po === po.id))?.customBroker?.name} </td>
                    </tr>)
                })}
            </tbody>
        </table>

    </>
}
export default POCustomBroker