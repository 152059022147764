import { useCallback, useEffect, useState } from "react"
import Page from "../../shared/components/UIElements/Page"
import sendRequest from "../../shared/sendRequest"
import { Button, Card } from "../../shared/components/UIElements/Dumbs"
import Modal from "../../shared/components/UIElements/Modal"
import { useParams } from "react-router-dom"

const Filters = () => {
    const {id} = useParams()

    const [filter, setFilter] = useState([])
    const [column, setColumn] = useState()
    const [columns, setColumns] = useState([])
    const [scolumns, setSColumns] = useState([])

    const getFilter = useCallback(async () => {
        try {
            const {filter} = await sendRequest('GET','po-placement-filters/' + id)
            setFilter(filter)
            setColumns(filter.columns)
        }catch(er){ console.log(er)} 
    },[id])

    const editColumn = (c) => {
        setColumn(c)
    }
    const UpdateColumnHandler = async(e) => {
        e.preventDefault()
        const {filter} = await sendRequest('PATCH','po-placement-filters/' + id,column)
        setFilter(filter)
        setColumns(filter.columns)
        setColumn(null)
    }

    useEffect( () => {
        setSColumns(columns.sort((a,b)=> (a.source > b.source ? 1 : -1)))
    },[columns])

    useEffect( () => {
        getFilter()
    },[getFilter])

    return <Page>
        <Page.Header>
            <h3>PO Placemenets - Filters</h3>
            <Button link="/po-placements/filters">{`< Back`}</Button>
        </Page.Header>
        <Page.Body className="scrollable">
            <Card>
                <div>Name: <b>{filter?.name}</b></div>
            </Card>
            <table className="card">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Source</th>
                        <th>Destiny</th>
                        <th>Use</th>
                        <th>Protected</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {scolumns?.map( c => <tr key={c.id}>
                    <td>{c.name}</td>
                    <td>{c.source}</td>
                    <td>{c.destiny}</td>
                    <td className="center">{c.use ? 'yes' : 'no'}</td>
                    <td className="center">{c.protected ? 'yes' : 'no'}</td>
                    <td><Button onClick={ () => editColumn(c) }>Edit</Button></td>
                </tr>)}
            </tbody>
            </table>
        </Page.Body>
        {column && <Modal onSubmit={UpdateColumnHandler} hideModal={() => setColumn(null)}>
            <div>Source: <b>{column.source}</b></div>
            <label>
                Name:
                <input value={column.name} onChange={ e => setColumn({...column, name: e.target.value})} />
            </label>
            <label>
                Destiny:
                <input value={column.destiny} onChange={ e => setColumn({...column, destiny: e.target.value})} />
            </label>
            <label>
                Use:
                <input type="checkbox" checked={column.use} onChange={ e => setColumn({...column, use: !column.use})} />
            </label>
            <label>
                Protected:
                <input type="checkbox" checked={column.protected} onChange={ e => setColumn({...column, protected: !column.protected})} />
            </label>
            <button type="submit">Set</button>
        </Modal>}
    </Page>
}

export default Filters