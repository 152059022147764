import moment from "moment"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Button } from "../../shared/components/UIElements/Dumbs"
import sendRequest from "../../shared/sendRequest"

const ProvidersPage = () => {
    const [providers, setProviders] = useState([])
    
    const getProviders = async() => {
        const {providers} = await sendRequest('GET','providers');
        setProviders(providers)
    }
    // const updateProviders = async() => {
    //     const {providers} = await sendRequest('GET','providers/coo-update');
    //     setProviders(providers)
    // }

    useEffect( () => {
        getProviders()
    },[])
    return <div className="page">
        <header>
            <h3>Providers</h3>
            <Button className="blue" link="/create-provider">+ Create Provider</Button>
           {/* <Button onClick={updateProviders}>Update Coo</Button> */}
        </header>
        <main className="scrollable">
            

            <table className="card">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Country</th>
                        <th>Coordinator</th>
                        <th>Assignation</th>
                        <th>Created</th>
                        <th>Note</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {providers.map( provider => <tr key={provider.id}>
                    <td>{provider.name}</td>
                    <td>{provider.country?.name}</td>
                    <td>{provider?._coordinator?.name}</td>
                    <td>{provider.assignationDate &&  moment(provider.assignationDate).format('DD/MMM/Y')}</td>
                    <td>{provider.createdAt && moment(provider.createdAt).format('DD/MMM/Y')}</td>
                    <td>{provider.note}</td>
                    <td><Link to={`/provider/${provider.id}`}><button>edit</button></Link></td>
                </tr>)}
                </tbody>
            </table>
        </main>
    </div>
}

export default ProvidersPage