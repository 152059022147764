import { useEffect, useState } from "react"

const SelectProvider = ({provider, onChange}) => {
    const providers = [
        {
            id:'P00',
            name:'CARGA CFS'
        },
        {
            id:'P01',
            name:'PROVEEDOR 01',
            coordinador: 'COO01'
        }
    ]

    const [providerT,setProviderT] = useState(provider)

    const setProvider = (clientId) => {
        if(!clientId)onChange(null)
        for( let i = 0; i < providers.length; i++){ 
            if ( providers[i].id === clientId) { 
                onChange(providers[i])
                break;
            }
        }
    }

    useEffect( () => {
        setProviderT(provider)
    },[provider])

    return <select value={providerT ? providerT.id : ''} onChange={e => setProvider(e.target.value)}>
        <option value={""}>Elige un proveedor</option>
        {providers.map( p => <option value={p.id} key={p.id}>{p.name}</option>)}
    </select>
}
export default SelectProvider