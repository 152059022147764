import moment from "moment"
import { useCallback, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Paginator from "../../../shared/components/UIElements/Paginator"
import sendRequest from "../../../shared/sendRequest"

const Inspections = () => {

    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(25)
    const [pagination, setPagination] = useState({
        page:0,
        perPage:0,
        pages:0,
        count:0
    })

    const [inspections, setInspections] = useState([])

    const getInspections = useCallback( async() => {
        const {inspections,pagination} = await sendRequest('GET',`back-office/inspections?perPage=${perPage}&page=${page}`);
        setPagination(pagination)
        setInspections(inspections)
    },[perPage,page]);

    useEffect( () => {
        getInspections()
    },[getInspections])

    return <div className="page">
        <header>
            <div  style={{flex:1}}>
                <div style={{ display:"flex", alignItems: 'center', justifyContent:'space-between'}}>
                    <h3>Inspections</h3>
                </div>
            
                <Paginator pagination={pagination} onChangePage={setPage} onChangePerPage={setPerPage} />
            </div>
    
        </header>
        <main className="scrollable">

<table className="card">
    <thead>
        <tr>
            <th></th>
            <th>Reference</th>
            <th>Loading Date</th>
            <th>Signed PO/Model/Container</th>
            <th>POs w/Agency</th>
            <th>PO nbrs</th>
            <th>Containers</th>
            <th>Country</th>
            <th>Company</th>
            <th>Port</th>
            <th>Provider</th>
            <th>Inspectors</th>
            <th>Inspection Signed</th>
            <th>Coordinator</th>
            <th>Translator</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
    {inspections.map( (ins, index) => <tr key={ins.id} >
        <td className={`center ${ins.parent ? 'yellow' : ''}`}>{(perPage*(page-1)) + index + 1}</td>
        <td>{ins.reference}</td>
        <td>{ins.loadingDate && moment(ins.loadingDate).format('DD/MMM/Y')}</td>
        <td className="center">{ins._dailyReports.filter( d => ( d.AnalysisSignedDate && d.BackOfficeSignedDate) ).length} of {ins._dailyReports.length} </td>
        <td className="center">
            {ins.containers.reduce( (prev, cur) => prev + cur._customBrokers.filter( cb => cb.customBroker ).length, 0) } of {ins.containers.reduce( (prev, cur) => prev + cur.pos.length, 0) } 
        </td>
        <td>{ins.programming?.pos?.map( po => <div key={po.id}>{po.po_nbr}</div>)}</td>
        <td>{ins.containers?.map( c => <div key={c.id}>{c.container_nbr}</div>)}</td>
        <td>{ins.programming?._country?.name}</td>
        <td>{ins.programming?._company?.name}</td>
        <td>{ins.programming?._port?.name}</td>
        <td>{ins.programming?._provider?.name}</td>
        <td>{ins.programming?.inspectors?.map( i => <div key={i.id}>{i.name}</div>)}</td>
        <td>{ins.programming?._coordinator?.name}</td>
        <td>{ins.signedDate && moment(ins.signedDate).format('DD/MMM/Y HH:mm')}</td>
        <td>{ins.translator?.name}</td>
        <td><Link to={`/back-office/inspection/${ins.id}`}><button>View Inspection</button></Link>
            </td>
    </tr>)}
    </tbody>
</table>
</main>
    </div>
}

export default Inspections