
import { useCallback, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import CoordinatorSelector from "../../shared/components/CoordinatorSelector";
import CountrySelector from "../../shared/components/CountrySelector";
import sendRequest from "../../shared/sendRequest";

const POPage = () => {
    const {id} = useParams()

    const navigate = useNavigate();

    const [po, setPO] = useState({
        _country:'',
        _coordinator:''
    })

    const updatePO = async e =>{
        e.preventDefault()
        await sendRequest('PUT','pos/' + id,po)
        navigate('/pos')
    }

    const deletePO = async() => {
        if(!window.confirm('Confirm')) return false
        await sendRequest('DELETE','pos/' + id);
        navigate('/pos')
    }

    const getPO = useCallback(async() => {
        if(!id) return false;
        const {po} = await sendRequest('GET','pos/' + id);
        setPO(po)
    },[id])

    useEffect( () => {
        getPO()
    },[getPO])

    return <div className="page">
        <header>
            <h3>Edit Purchase Order</h3>
            <Link to="/pos"><button className="link">{'<'} back</button></Link>
        </header>
        <main className="scrollable">
            <form onSubmit={updatePO} className="card">
  
                <label>
                    Country
                    <CountrySelector inspectables value={po._country} onChange={ country => setPO( state => ({...state,_country:country}) )} />
                </label>
                <label>
                    Coordinator
                    <CoordinatorSelector coordinatorId={po._coordinator} onChange={ coordinator => setPO( state => ({...state,_coordinator:coordinator}) )} />
                </label>
                
                
                <button type="submit">Update PO</button>
            </form>

            <div className="card">
                <h3>DANGER ZONE</h3>
                <button onClick={deletePO}>Remove PO</button>
            </div>


        </main>
    </div>
}

export default POPage;