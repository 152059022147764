import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import sendRequest from "../../shared/sendRequest";
import Card from "../../shared/components/UIElements/Card"
import RMBDeposit from "../components/RMBDeposit";
import ChargeCount from "../components/ChargeCount";

const DailyArrangementPage = () => {

    const {id:programmingId } = useParams()
    const [programming,setProgramming] = useState()

    const getProgramming = useCallback( async () => {
        const { programming } = await sendRequest('GET',`daily-arrangements/${programmingId}`)
        setProgramming(programming)
        //setPos(pos)
    },[programmingId])

    useEffect( () => {
        getProgramming()
    },[getProgramming])

    return <div className="page">
        <header>
            <h3>Daily Arrangement</h3>
            <Link to="/daily-arrangements-consolidated"><button className="link">{'<'} back</button></Link>
        </header>
        
        <main className="scrollable">
            <Card>
                <RMBDeposit id={programming?.id} amount={programming?.rmbDeposit} currency={programming?.rmbDepositCurrency} onChange={getProgramming} />
                <hr className="mt20"/>
                <ChargeCount id={programming?.id} value={programming?.chargeCount} fcl={programming?.noContainers} fclI={programming?.inspection?.noContainers || ''} onChange={getProgramming} />
            </Card>
            {false && <pre>{JSON.stringify(programming,null,2)}</pre>}

        </main>
    </div>
}
export default DailyArrangementPage