import { useState, useEffect } from "react";
import CustomBrokerSelector from "../../../shared/components/CustomBrokerSelector";
import Modal from "../../../shared/components/UIElements/Modal";
import sendRequest from "../../../shared/sendRequest";

const CustomBroker = ({id, value, onChange, className}) => {

    const [showModal,setShowModal ] = useState(false);
    const [customBroker,setCustomBroker ] = useState(value?.id || '');

    const CountSetHandler = async e => {
        e.preventDefault()

        await sendRequest('PATCH',`translator/inspections/${id}/custom-broker`,{customBroker})
        onChange(customBroker)
        setShowModal(false)
    }
    useEffect( () => {
        setCustomBroker(value?.id)
    },[value]) 
    return <>
        Custom Broker: 
        <div style={{float:'right'}}><button onClick={() => setShowModal(true)}>Set Custom Broker</button></div>
        <div className="ml20"><b>{value?.name || "-"}</b></div> 

        {showModal && <Modal onSubmit={CountSetHandler} hideModal={() => setShowModal(false)}>
            <CustomBrokerSelector value={customBroker} onChange={ e => setCustomBroker(e) } />
            <button type="submit">Set</button>
        </Modal>}
    </>
}

export default CustomBroker