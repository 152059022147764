import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import sendRequest from '../../shared/sendRequest'

const Inspections = () => {
    const [inspections, setInspections] = useState([]);

    const getInspections = async () => {
        try{
            const response = await sendRequest('GET','api/inspections');
            setInspections(response.inspections);
        }catch(er){
            console.log(er)
        }
    }

    useEffect( () => {
        getInspections()
    },[])


    return <div className="page">
        <header>
            <h3>Inspections</h3>
            <Link to="/create-inspection"><button>+ Create Inspection</button></Link>
        </header>
        <main className="scrollable">
            <table className="card">
                <thead>
                    <tr>
                        <th>Reference</th>
                        <th>Loading date</th>
                        <th>Report date</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {inspections?.map( i => <tr key={i.id}>
                        <td>{i.reference}</td>
                        <td>{i.loading_date && moment(i.loading_date).format('DD/MMM/Y')}</td>
                        <td>{i.report_date && moment(i.report_date).format('DD/MMM/Y')}</td>
                        <td><Link to={`/inspection/${i.reference}`}><button>Edit</button></Link></td>
                    </tr>)}

                </tbody>
            </table>
            {false && inspections.map( i => <pre key={i.id}>{JSON.stringify(i,null,2)}</pre>)}
        </main>
    </div>
}

export default Inspections;