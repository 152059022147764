import { useCallback, useEffect, useState } from "react"
import "./ImageThumb.scss"

const ImageThumbTmp = ({file}) => {
    const [image,setImage] = useState()
    
    const _getImage = useCallback(async () => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
            setImage(fileReader.result)
        }
        fileReader.readAsDataURL(file.file);
    },[file])
    useEffect( () => {
        _getImage()
    },[_getImage])
    return <div className="ImageThumb" style={image ? {
        backgroundImage : `url('${image}')`
    } : {}}>
        <div className="ImageThumb_uploader">Uploading...</div>
        <div className="ImageThumb_name">{file.name}</div>
        
    </div>
}
export default ImageThumbTmp