import { useCallback, useState, useEffect } from "react"
import Modal from "../../../shared/components/UIElements/Modal";
import sendRequest from "../../../shared/sendRequest";

const SelectInspectors = ({inspectors, onSelect, onRemove}) => {

    /*
        - Filter Inspectors by Country
        - Remove filter
    */
    const [inspectors_cat, setInspectorsCat] = useState([])

    const getInspectorsCat = useCallback(async() => {
        const response = await sendRequest('GET','inspectors');
        setInspectorsCat(response.inspectors)
    },[]);

    useEffect( () => {
        getInspectorsCat()
    },[getInspectorsCat])

    const [showModal,setShowModal ] = useState(false);

    const addInspector = inspectorId => {
        onSelect(inspectorId)
    }
    const removeInspector = inspectorId => {
        onRemove(inspectorId)
    }

    return <div>
        <button onClick={() => setShowModal(true)}>Select Inspectors</button>
        {showModal && <Modal onSubmit={ e => e.preventDefault()} hideModal={() => setShowModal(false)}>
            <div>
                Assigned Inspectors
                <ul>
                    {inspectors?.map( inspector => <li key={inspector} >{inspectors_cat.find( i => i.id === inspector)?.name}, {inspectors_cat.find( i => i.id === inspector)?.country?.name} <button onClick={ () => removeInspector(inspector)}>remove</button></li>)}
                </ul>
                <hr/>
                Available Inspectors
                <ul>
                    {inspectors_cat?.filter( i => !inspectors.includes(i.id)).map( inspector => <li key={inspector.id} ><button onClick={ () => addInspector(inspector.id)}>{inspector.name}, {inspector.country?.name}</button></li>)}
                </ul>
                
            </div>
        </Modal>}
    </div>
}

export default SelectInspectors