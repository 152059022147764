import { useEffect, useState } from "react"
import { Button, Card } from "../../../shared/components/UIElements/Dumbs"
import Modal from "../../../shared/components/UIElements/Modal"
import sendRequest from "../../../shared/sendRequest"
import ModelEditor from "./ModelEditor"

const Models = ({pos, containers, inspection, dailyReports, onComplete}) => {

    const [dpmodel, setDPModel] = useState()

    const [models, setModels] = useState([])
    const [model, setModel] = useState({
        _containerPacks:'',
        _container:'',
    })
    const [showModal, setShowModal] = useState(false)

    const openForm = (model) => {
        setModel({...model,_containerPacks:model.total_packs_available,_container:''})
        setShowModal(true)
    }

    const handleModelComplementUpdate = (complement) => {
        onComplete()
    }
    const AssignHandler = async e => {
        e.preventDefault()
        await sendRequest('POST',`translator/inspections/daily-report`,{...model,_inspection:inspection})
        setShowModal(false)
        onComplete()
    }
    const removeAssignation = async (id) => {
        if(!window.confirm("All updated data will be lost!")) return false
        await sendRequest('DELETE',`translator/inspections/daily-report/${id}`)
        setShowModal(false)
        onComplete()
    }
    useEffect( () => {
        if(pos){
            const models = []
            for(const po of pos){
                for(const model of po._models){
                    model.total_packs_available = (model.total_packs*1)
                    for(const dr of dailyReports){
                        if(dr._model.id === model._id)
                        model.total_packs_available -= dr._containerPacks
                    }
                    if(model.total_packs_available) models.push(model)
                }
            }
            setModels(models)
        }
    },[pos,dailyReports])
    return <Card className="p0 pt10">
        <h4 className="m20 mb10 mt0">PO / Models</h4>
        <div className="scrollable">
        <table>
            <thead>
                <tr>
                    <th>Provider</th>
                    <th>PO nbr</th>
                    <th>Model</th>
                    <th>UPC</th>
                    <th>Description</th>
                    <th>Total Packs</th>
                    <th>Total Packs Available</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {models.map( m => <tr key={m.id}>
                    <td>{m.supplier_name}</td>
                    <td>{m.po_nbr}</td>
                    <td>{m.vndr_stk_nbr}</td>
                    <td>{m.upc}</td>
                    <td>{m.mat_desc}</td>
                    <td>{m.total_packs}</td>
                    <td>{m.total_packs_available}</td>
                    <td><Button onClick={ () => openForm(m)}>Assign to container</Button></td>
                </tr> )}
            </tbody>
        </table>
        </div>
        {showModal && <Modal className="scrollable" onSubmit={AssignHandler} hideModal={() => setShowModal(false)}>
            <label>
                Container:
            <select value={model._container} onChange={ e => setModel({...model,_container:e.target.value})}>
                <option value="">Choose a container</option>
                {containers.map( c => <option key={c._id} value={c._id}>{c.container_nbr}</option>)}
            </select>
            </label>
            
            <label>
                Total packages available of {model.total_packs}:
                <input readOnly={true} defaultValue={model.total_packs_available} />
            </label>
            <label>
                Packages in this container:
                <input type="number" max={model.total_packs_available} value={model._containerPacks} onChange={ e => setModel({...model,_containerPacks:e.target.value})} />
            </label>

            <Button disabled={!model._container} type="submit">Assign Model</Button>
          
        </Modal>}
        <h5 className="ml20">Assigned</h5>
        <div className="scrollable">
        <table>
            <thead>
                <tr>
                    <th>Provider</th>
                    <th>PO nbr</th>
                    <th>Model</th>
                    <th>UPC</th>
                    <th>Description</th>
                    <th>Container</th>
                    <th>Packs</th>
                    <th>Pcs/Pack</th>
                    <th>Total Pcs</th>
                    <th>Material</th>
                    <th>Brand</th>
                    <th>Font size</th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {dailyReports?.map( m => <tr key={m.id}>
                    <td>{m._modelComplement?.supplier_name || m._model?.supplier_name}</td>
                    <td>{m._modelComplement?.po_nbr || m._model?.po_nbr}</td>
                    <td className="noWrap">{m._modelComplement?.vndr_stk_nbr || m._model?.vndr_stk_nbr}</td>
                    <td>{m._modelComplement?.upc || m._model?.upc}</td>
                    <td>{m._modelComplement?.mat_desc || m._model?.mat_desc}</td>
                    <td>{m._container?.container_nbr}</td>
                    <td>{m._containerPacks}</td>
                    <td>{m._modelComplement?.vndr_pack || m._model?.vndr_pack || 0}</td>
                    <td>{((m._modelComplement?.vndr_pack || m._model?.vndr_pack || 0) * m._containerPacks)}</td>
                    <td>{m._modelComplement?.material || m._model?.material}</td>
                    <td>{m._modelComplement?.brand || m._model?.brand}</td>
                    <td>{m._modelComplement?.fontSize || m._model?.fontSize}</td>
                    <td><Button onClick={ () => setDPModel(m)}>Edit</Button></td>
                    <td><Button onClick={ () => removeAssignation(m._id)}>Remove assignation</Button></td>
                </tr> )}
            </tbody>
        </table>
            <ModelEditor model={dpmodel} closeModal={ () => setDPModel(null)} onUpdate={handleModelComplementUpdate} />
        </div>
    </Card>
}
export default Models