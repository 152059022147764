import moment from "moment"
import { useCallback, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import sendRequest from "../../../shared/sendRequest"
import EditMessage from "../components/EditMessage"

const DailyProgramming = () => {

    const {id:dpId } = useParams()
    const [dp, setDP] = useState()
    const [newP, setNewP] = useState([])
    const [chP, setChP] = useState([])

    const getDP = useCallback( async () => {
        const {dailyProgramming} = await sendRequest('GET',`coordinator/daily-programmings/${dpId}`);
        setDP(dailyProgramming)
    },[dpId])

    const setMessage = async message => {
        await sendRequest('PATCH',`coordinator/daily-programmings/${dpId}/message`,{message})
        getDP()
    }
    const sendDailyProgramming = async message => {
        await sendRequest('PATCH',`coordinator/daily-programmings/${dpId}/send`)
        getDP()
    }

    useEffect( () => {
        if(!dp) return false
        const newP = []
        const chP = []
        for(let p of dp.programmings){
            p.parent ? chP.push(p) : newP.push(p)
        }
        setNewP(newP)
        setChP(chP)
    },[dp])

    useEffect( () => {
        getDP()
    },[getDP])
    return <div className="page">
        <header>
            <h3>Edit Daily Programming</h3>
            <Link to="/coordinator/daily-programmings"><button className="link">{'<'} back</button></Link>
        </header>
        <main className="scrollable">
            <div className="card">
                <div>No. Programmings:  <b>{dp?.programmings?.length}</b></div>
                <div>Daily Programming Date:  <b>{dp?.dailyProgrammingDate && moment(dp?.dailyProgrammingDate).format('DD/MMM/Y Z')}</b></div>
                <div>Date Offset:  <b>{dp?.utcOffset && `${(dp?.utcOffset / 60)}:00`}</b></div>
                <div>Sended:  <b>{dp?.sendedDate && moment(dp?.sendedDate).format('DD/MMM/Y Z')}</b></div>
                <div>Message: {!dp?.sendedDate && <EditMessage message={dp?.message} onMessageSet={setMessage} />}
                    {dp?.message && <div className="badge p10 multiLine my10">{dp.message}</div>}
                </div>
                {!dp?.sendedDate && <div className="textRight">
                    <button onClick={sendDailyProgramming} className="blue">Send Daily Programming</button>
                </div>}
            </div>
            
            <h4 className="mx20">New Programmings</h4>

            <table className="card">
                <thead>
                    <tr>
                        <th>Country</th>
                        <th>Company</th>
                        <th>Port</th>
                        <th>Provider</th>
                        <th>PO nbrs</th>
                        <th>Loading Date</th>
                        <th>Inspectors</th>
                        <th>Container Count</th>
                        <th>Locations</th>
                        <th>Observations</th>
                    </tr>
                </thead>
                <tbody>
                    {newP?.map( p => <tr key={p.id}>
                        <td>{p._country?.name}</td>
                        <td>{p._company?.name}</td>
                        <td>{p._port?.name}</td>
                        <td>{p._provider?.name}</td>
                        <td>{p.pos.map( po => <div key={po.id}>{po.po_nbr}</div>)}</td>
                        <td>{p.loadingDate && moment(p.loadingDate).format('DD/MMM/Y Z')}</td>
                        <td>{p.inspectors.map( i => <div key={i.id}>{i.name}</div>)}</td>
                        <td className="center">{p.noContainers}</td>
                        <td>{p.locations.map( l => <div key={l.id}>{l.name}</div>)}</td>
                        <td className="multiLine"><small>{p.notes}</small></td>
                    </tr>)}
                </tbody>
            </table>


            <h4 className="mx20">Changed Programmings</h4>

            <table className="card">
                <thead>
                    <tr>
                        <th>Country</th>
                        <th>Company</th>
                        <th>Port</th>
                        <th>Provider</th>
                        <th>PO nbrs</th>
                        <th>Loading Date</th>
                        <th>Inspectors</th>
                        <th>Container Count</th>
                        <th>Locations</th>
                        <th>Observations</th>
                    </tr>
                </thead>
                <tbody>
                    {chP?.map( p => <tr key={p.id}>
                        <td>{p._country?.name}</td>
                        <td>{p._company?.name}</td>
                        <td>{p._port?.name}</td>
                        <td>{p._provider?.name}</td>
                        <td>{p.pos.map( po => <div key={po.id}>{po.po_nbr}</div>)}</td>
                        <td>{p.loadingDate && moment(p.loadingDate).format('DD/MMM/Y Z')}</td>
                        <td className={`${p.changes.includes('Inspectors') ? 'yellow' : ''}`}>{p.inspectors.map( i => <div key={i.id}>{i.name}</div>)}</td>
                        <td className="center">{p.noContainers}</td>
                        <td>{p.locations.map( l => <div key={l.id}>{l.name}</div>)}</td>
                        <td className="multiLine"><small>{p.notes}</small></td>
                    </tr>)}
                </tbody>
            </table>

        </main>
    </div>
}

export default DailyProgramming