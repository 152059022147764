import { useState, useRef, useEffect, useCallback } from 'react'
import { AiOutlineDelete, AiOutlineExport, AiOutlineHolder } from 'react-icons/ai'
import { imageApi } from '../../../shared/sendRequest'
import './ReportDnD.scss'
import './ReportDnDThumb.scss'
import DragNDrop from './DragNDrop'
import ImageThumbTmp from './ImageThumbTmp'
import Modal from '../../../shared/components/UIElements/Modal'

import { useTranslation } from 'react-i18next';

let map;
const ReportDnD = ({images, inspection, className})=> {
    const dragItem = useRef()
    const dragNode = useRef()

    const [_images,setImages] = useState([])
    const [dragging,setDragging] = useState(false)

    /* SHOW IMAGE MODAL*/
    const [showBigImage,setShowBigImage ] = useState();
    const handleOpenImage = async (file) => {
        setShowBigImage(file)
    }

    const handleOnDelete = (id) => {
        setImages(state => state.filter( i => i.id !== id))
    }
    
    const handleOnUploadImage = image => {
        setImages(state => [...state,image])
    }

    const handleDragStart = (e, params) => {
        //console.log('drag starting...',params)
        dragItem.current = params
        dragNode.current = e.target
        dragNode.current.addEventListener('dragend',handleDragEnd)
        setTimeout(() => {
            setDragging(true)
        },0)
    }
    const handleDragEnter = (e, params) => {
        if(params.id !== dragItem.current.id){
            const currentItem = dragItem.current;
            //console.log('drag enter...',params)
            setImages(state => {
                let newList = JSON.parse(JSON.stringify(state));
                let dest = newList.find( i => i.id === params.id)
                let item = newList.find( i => i.id === dragItem.current.id)

                let o = 0
                if(item){
                    item.imageType = params.gi
                    item.container = params.container
                    item.model = params.model
                    item.po = params.po
                    o = item.order
                    item.order = dest? dest.order : 0
                }
                if(dest){
                    dest.order = o
                }
                newList.splice(params.ii,0, newList.splice(currentItem.ii,1)[0])
                //dragItem.current = params
                return newList
            })
        }
    }
    const handleDragEnd = () => {
        console.log('drag end...')
        setDragging(false)
        dragNode.current.removeEventListener('dragend',handleDragEnd)
        dragItem.current = null
        dragNode.current = null
        const ar = Array.from(map)
        if(ar.length){
            imageApi('PUT',`translator/inspections/image/order`,{
                files:ar
            })
        }
        
    }
    
    document.addEventListener('dragover', function(e) { e.preventDefault() })

    const getStyles = (params) => {
        const currentItem = dragItem.current;
        return (currentItem.id === params.id) ? 'dnd-current-item' : '' 
    } 
    

    const [inspImages,setInspImages] = useState([]);
    const [contImages,setContImages] = useState([]);
    const [poImages,setPOImages] = useState([]);
    const [modelImages,setModelImages] = useState([]);

    const fsort = (a,b) => {
        if (a.order > b.order) {
            return 1;
          }
          if (a.order < b.order) {
            return -1;
          }
          // a must be equal to b
          return 0;
    }

    const _updateChanges = useCallback(() => {
        //TODO: optimize order
        map = new Map()
        for(let o of images){
            map.set(o.id,{
                order:o.order,
                imageType:o.imageType,
                po:o.po,
                container:o.container,
                model:o.model
            })
        }
        for(let c of _images){
            if(map.has(c.id)){
                let m = map.get(c.id)
                if( m.order === c.order &&  
                    m.imageType === c.imageType &&
                    m.po === c.po &&
                    m.model === c.model &&
                    m.container === c.container
                ){
                    map.delete(c.id)
                }else{
                    map.set(c.id,{
                        order:c.order,
                        imageType:c.imageType,
                        po:c.po,
                        container:c.container,
                        model:c.model
                    })
                }
            }else{
                map.set(c.id,{
                    order:c.order,
                    imageType:c.imageType,
                    po:c.po,
                    container:c.container,
                    model:c.model
                })
            }
        }
       
    },[_images,images])
    useEffect( () => {
        _updateChanges()
        //ORDER IMAGES IN THEIR SECTIONS
        let inspImages = []
        let contImages = []
        let poImages = []
        let modelImages = []
        let i = 0;
        for(let image of _images){
            if(image) image.order = image.order || i;
            switch(image.imageType){
                case 'container':
                    if(image.container){
                        if(!contImages[image.container]) contImages[image.container] = []
                        contImages[image.container].push(image)
                        contImages[image.container] = contImages[image.container].sort(fsort)
                    }else{
                        contImages.push(image)
                    }
                    break;
                case 'po':
                    if(image.po){
                        if(!poImages[image.po]) poImages[image.po] = []
                        poImages[image.po].push(image)
                        poImages[image.po] = poImages[image.po].sort(fsort)
                    }else{
                        poImages.push(image)
                    }
                    break;
                case 'model':
                    modelImages.push(image)
                    break;
      
                default:
                    inspImages.push(image)
            }
            i++
        }

        setModelImages(modelImages.sort(fsort))
        setInspImages(inspImages.sort(fsort))
        setContImages(contImages.sort(fsort))
        setPOImages(poImages.sort(fsort))
    },[_images,_updateChanges])

    useEffect( () => {
        setImages(images)
    },[images])

    useEffect( () => {
        document.addEventListener('dragover', function(e) { e.preventDefault() })
        return () => {
            document.removeEventListener('dragover', function(e) { e.preventDefault() })
        }
    },[])

    const [busy,setBusy] = useState(false)
    const _getImage = useCallback(async (item) => {
        if(busy) return false;
        setBusy(true)
        const {image} = await imageApi('GET',`translator/inspections/image/thumb/${item.id}`)
        setBusy(false)
        setImages( state => {
            let newList = [...state];
            (newList.find( i => i.id === item.id)).image=image
            return newList
        })
    },[busy])

    useEffect( () => {
        for(let item of _images){
            if(!item.image) {
                return _getImage(item)
            }
        }
    },[_images,_getImage])

    return <>

        {showBigImage && <DNDImageModal file={showBigImage} onClose={ () => setShowBigImage('')} />}
        <div className={`drag-n-drop ${className}`}>
            
            {(!inspection?.signedDate || !!inspImages.length) && <div className='dnd-sub-group'>
                <DnDGroup editable={!inspection?.signedDate} dragging={dragging} getStyles={getStyles} onUpload={handleOnUploadImage}
                    handleDragEnter={handleDragEnter} handleDragStart={handleDragStart} handleOnDelete={handleOnDelete} handleOpenImage={handleOpenImage}
                    title={`INSPECTION: ${inspection?.reference}`} images={inspImages} type="inspection" inspection={inspection}
                />
            </div>}

            <div className='dnd-sub-group'>
                <DnDGroup editable={!inspection?.signedDate} dragging={dragging} getStyles={getStyles} onUpload={handleOnUploadImage}
                    handleDragEnter={handleDragEnter} handleDragStart={handleDragStart} handleOnDelete={handleOnDelete} handleOpenImage={handleOpenImage}
                    title={`CONTAINERS`} images={contImages} type="container" inspection={inspection}
                />

                {inspection?.containers.map( container => <DnDGroup editable={!inspection?.signedDate} key={container.id} dragging={dragging} getStyles={getStyles}
                    onUpload={handleOnUploadImage}
                    handleDragEnter={handleDragEnter} handleDragStart={handleDragStart} handleOnDelete={handleOnDelete} handleOpenImage={handleOpenImage}
                    title={`Container: ${container.container_nbr}`} inspection={inspection}
                    container={container.id} 
                    images={contImages?.[container.id]} type="container"
                />)}
            </div>

            <div className='dnd-sub-group'>
                <DnDGroup editable={!inspection?.signedDate} dragging={dragging} getStyles={getStyles} onUpload={handleOnUploadImage}
                    handleDragEnter={handleDragEnter} handleDragStart={handleDragStart} handleOnDelete={handleOnDelete} handleOpenImage={handleOpenImage}
                    title={`PURCHASE ORDERS`} images={poImages} type="po" inspection={inspection}
                />

                {inspection?.programming?.pos?.map( po => <div className='dnd-sub-sub-group' key={po.id}>
                    <DnDGroup editable={!inspection?.signedDate} dragging={dragging} getStyles={getStyles} onUpload={handleOnUploadImage}
                        handleDragEnter={handleDragEnter} handleDragStart={handleDragStart} handleOnDelete={handleOnDelete} handleOpenImage={handleOpenImage}
                        title={`PO: ${po.po_nbr}`} inspection={inspection}
                        po={po.id}
                        images={poImages?.[po.id]} type="po"
                    />

                    {po?._models.map( model => <DnDGroup editable={!inspection?.signedDate} key={model.id} dragging={dragging} getStyles={getStyles}
                        onUpload={handleOnUploadImage}
                        handleDragEnter={handleDragEnter} handleDragStart={handleDragStart} handleOnDelete={handleOnDelete} handleOpenImage={handleOpenImage}
                        title={`Model: ${model.upc}`} inspection={inspection}
                        model={model.id}
                        po={po.id}
                        images={modelImages.filter( m => (m.po === po.id && m.model === model.id))} type="model"
                    />)}

                </div>)}
            </div>
        </div>
    </>

}

const DnDGroup = ({title,getStyles, onUpload,
    handleDragEnter, handleDragStart, handleOnDelete, handleOpenImage,
    container, po, model, inspection, editable=true,
    dragging ,images = [], type}) => {

    /*
    DRAG N DROP UPLOAD
    */
    const [tmpfiles,setTmpFiles] = useState([]);
    const [uploading,setUploading] = useState(false);

    const handleDrop = async (files, imageType, container, po, model) => {
        let tmp = []
        for(const file of files){
            tmp.push({
                model, po, container,
                file:file,
                name:file.name,
                imageType:imageType,
                preview:null
            })
        }
        setTmpFiles(tmp)
    }

    const _getPresignedURL = useCallback(async(file) => {
        let payload = {
            container: file.container,
            po: file.po,
            model: file.model,
            name: file.name,
            type: file?.file?.type,
            size: file?.file?.size,
            imageType:file.imageType
        }
        try{
            return await imageApi('POST',`translator/inspections/${inspection.id}/image/presignedurl`,payload);
        }catch(er){ 
            console.log(er) 
        }
    },[inspection])

    const _uploadFileToS3 = useCallback(async (url,file) => {
        const myHeaders = new Headers({ 'Content-Type': file.file.type });
        const fetchResponse = await fetch(url, {
            method: 'PUT',
            headers: myHeaders,
            body: file.file
        });
        if (fetchResponse.ok){
            return true
        }
        return false
    },[])

    const _uploadFile = useCallback(async () => {
        setUploading(true)
        if(tmpfiles.length){
            const file = tmpfiles[0]
            const {url, image} = await _getPresignedURL(file)
            await _uploadFileToS3(url,file)
            onUpload(image)
            setTmpFiles(state => state.slice(1))
            setUploading(false)
        }else{
            setUploading(false)
        }
    },[_getPresignedURL,_uploadFileToS3,tmpfiles, onUpload])

    useEffect( () => {
        if(tmpfiles.length && !uploading){
            _uploadFile()
        }
    },[tmpfiles,_uploadFile,uploading])

    return <div className={`dnd-group`}
        onDragEnter={dragging && !images.length ? e=>handleDragEnter(e,{gi:type,ii:0,container, model, po}) : null}
    >
        <div className='dnd-group-title'>{title}</div>

        <div className="list">
            {images.map( (i,ii) => <DnDItem key={ii} 
                className={dragging?getStyles({id:i.id}):''}
                onDragEnter={dragging?e=>handleDragEnter(e,{gi:type,ii:i.order,id:i.id,container, model, po}):null}
                onDragStart={ e => handleDragStart(e,{gi:type,ii:i.order,id:i.id,container, model, po})}
                onDelete={handleOnDelete} openImage={handleOpenImage}
                file={i} editable={editable}
                />)}

                {tmpfiles.map( (i,ix) => <ImageThumbTmp key={ix} file={i} />)}

                {editable && <DragNDrop className="dnd-dnd" onDrop={handleDrop} 
                container={container} po={po} model={model} 
                imageType={type}>Drag photos here</DragNDrop>}
        </div>
    </div>
}





const DnDItem = ({editable,children, onDragStart, onDragEnter, className, style, file, onDelete, openImage}) => {

    const handleDeleteImage = async e => {
        preventBehavior(e)
        if(!window.confirm('Remove image?')) return false
        let id = file.id
        await imageApi('DELETE',`translator/inspections/image/${id}`)
        onDelete(id)
    }
    const handleOpenImage = async e => {
        preventBehavior(e)
        openImage(file)
    }
    const preventBehavior = e => {
        e.stopPropagation();
        e.preventDefault()
    }
    return <div draggable 
        style={style}
        className={`dnd-item ${className}`} 
        onDragStart={onDragStart}
        onDragEnter={onDragEnter}
    >
        {editable && <div className='dnd-item-handle'><AiOutlineHolder/></div>}
        {file && <div draggable onDragStart={preventBehavior} onClick={handleOpenImage}
            className={`DnDImageThumb`} style={file.image?.Content ? {...style,
            backgroundImage : `url('data:${file.image?.ContentType};base64,${file.image?.Content}')`
        } : {...style}}>
            {!file.image?.Content && <div className="DnDImageThumb_loader">Loading...</div>}
            {editable && <div className="DnDImageThumb_tools" draggable onDragStart={preventBehavior} onClick={handleDeleteImage}><AiOutlineDelete /></div>}
            <div className="DnDImageThumb_name">{file.name}</div>
        
        </div>}
        <div>{children}</div>
    </div>
}

const DNDImageModal = ({file, onClose}) => {
    const { t } = useTranslation();
    //const { t, i18n } = useTranslation();
    //i18n.changeLanguage("fr")
    

    const [image,setImage] = useState({
        Content:'',
        ContentType:''
    })

    const openImageWindow = async () => {
        const {image} = await imageApi('GET',`translator/inspections/image/original/${file.id}`)
        var img = new Image();
        img.src = `data:${image.ContentType};base64,${image.Content}`
        var w = window.open("about:blank",file.name);
        w.document.write(img.outerHTML);
    } 

    const getBigImage = async (id) => {
        const {image} = await imageApi('GET',`translator/inspections/image/big/${id}`)
        setImage(image)
    }
    useEffect( () => {
        getBigImage(file.id)
    },[file])
    return <Modal className="modalImage" hideModal={onClose}>
        <div className='DnDImageBig-btn' onClick={openImageWindow}><AiOutlineExport /></div>
        <div className="DnDImageBig" style={image.Content ? {
            backgroundImage : `url('data:${image.ContentType};base64,${image.Content}')`
        } : {}}>
            {!image.Content && <div className="DnDImageBig_loader">{t('Loading...')}</div>}
        </div>
    </Modal>
} 

export default ReportDnD