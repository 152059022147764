import { useState, useEffect } from "react";
import Modal from "../../../shared/components/UIElements/Modal";

const ManageObservations = ({observations, onObservationSet, className}) => {

    const [showModal,setShowModal ] = useState(false);
    const [observation,setObservations ] = useState(observations || '');

    const CountSetHandler = e => {
        e.preventDefault()
        onObservationSet(observation)
        setShowModal(false)
    }
    useEffect( () => {
        setObservations(observations)
    },[observations]) 
    return <div className={` ${className}`}>
        <div>
            <button onClick={() => setShowModal(true)}>Add / Edit Observations</button>
        </div>
        {showModal && <Modal onSubmit={CountSetHandler} hideModal={() => setShowModal(false)}>
            <textarea style={{width:'100%', resize:'vertical'}} rows={4} value={observation} onChange={ e => setObservations(e.target.value)} />
            <button type="submit">Set</button>
        </Modal>}
    </div>
}

export default ManageObservations