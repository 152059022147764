import moment from "moment"
import { useCallback, useEffect, useState } from "react"
import CountrySelector from "../../../shared/components/CountrySelector"
import Paginator from "../../../shared/components/UIElements/Paginator"
import sendRequest from "../../../shared/sendRequest"

const SearchPOs = ({defaultFilters, onPOAdd}) => {

    const [pos, setPOs] = useState([])

    const [providers, setProviders] = useState([])

    //filters
    const [filters,setFilters] = useState(defaultFilters || {
        country:'',
        provider:''
    })

    /*useEffect( () => {
        if(defaultFilters){
            setFilters(defaultFilters)
        }
    },[defaultFilters]) */

    useEffect( () => {
        if(filters.provider) return false
        let provs = new Map()
        for(let po of pos){
            provs.set(po._provider?.name,po._provider?.id)
        }
        setProviders(Array.from(provs))
    },[pos, filters])

    //pagination
    const [perPage, setPerPage] = useState(25)
    const [page, setPage] = useState(1)
    const [pagination, setPagination] = useState()

    
    const [countryF,setCountryF] = useState('');
    const [providerF,setProviderF] = useState('');
    useEffect( () => {
        if(filters.country){
            setCountryF('&country=' + filters.country)
        }else{
            setCountryF('')
        }
        if(filters.provider){
            setProviderF('&provider=' + filters.provider)
        }else{
            setProviderF('')
        }
    },[filters,setCountryF])

    const addPO = (po) => {
        onPOAdd(po)
    }
    const getPOs = useCallback( async() => {
        const {pos,pagination} = await sendRequest('GET',`coordinator/pos?hideProgrammed=true&perPage=${perPage}&page=${page}${countryF}${providerF}`);
        setPagination(pagination)
        setPOs(pos)
    },[perPage,page,countryF,providerF]);

    const getPOsFiltered = useCallback( async() => {
        const {pos,pagination} = await sendRequest('GET',`coordinator/pos?hideProgrammed=true&perPage=${perPage}&page=${page}${'&country=' + defaultFilters.country}${'&provider=' + defaultFilters.provider}`);
        setPagination(pagination)
        setPOs(pos)
    },[perPage,page,defaultFilters]);

    useEffect( () => {
        if(defaultFilters){
            getPOsFiltered()
        }else{
            getPOs()
        }
        
    },[getPOs,getPOsFiltered,defaultFilters])

    return <>
        <div className="card">
            {!defaultFilters && <>
            <h4>Filters</h4>
            <div style={{display:'flex'}}>
                <label className="mr20">
                    Country
                    <CountrySelector inspectables value={filters.country} onChange={ country => setFilters( state => ({...state,country}) )} />
                </label>
                <label>
                    Provider ({providers.length})
                    <select value={filters.provider} onChange={ e => setFilters( state => ({...state,'provider':e.target.value}) )}>
                        <option value="">Choose a Provider</option>
                        {providers.map( p => <option key={p[1]} value={p[1]}>{p[0]}</option> )}
                    </select>
                </label>
                
            </div>
            <hr/>
            </>}
            <Paginator pagination={pagination} onChangePage={setPage} onChangePerPage={setPerPage} />
        </div>

        <div className="scrollable">

        <table className="card" style={{marginTop: 0}}>
                <thead>
                    <tr>
                        <th></th>
                        <th>PO Placement</th>
                        <th>PO nbr</th>
                        <th>Early Ship Date</th>
                        <th>Late Ship Date</th>
                        <th>Country</th>
                        <th>Company</th>
                        <th>Port</th>
                        <th>Provider</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {pos.map( (po, index) => <tr key={po.id}>
                    <td className="center">{(perPage*(page-1)) + index + 1}</td>
                    <td>{po.POPlacement?.name}</td>
                    <td>{po["po_nbr"]}</td>
                    <td>{po["early_ship_date"] && moment(po["early_ship_date"]).format('DD/MMM/Y')}</td>
                    <td>{po["late_ship_date"] && moment(po["late_ship_date"]).format('DD/MMM/Y')}</td>
                    <td>{po._country?.name}</td>
                    <td>{po._company?.name}</td>
                    <td>{po._port?.name}</td>
                    <td>{po._provider?.name}</td>
                    <td><button onClick={e => addPO(po)}>add</button></td>
                </tr>)}
                </tbody>
            </table>
            </div>
    </>
}
export default SearchPOs