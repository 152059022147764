import { useState,useEffect } from "react";
import Modal from "../../../shared/components/UIElements/Modal";
import sendRequest from "../../../shared/sendRequest";

const CargoOrder = ({onUpdate, className, inspectionId, container}) => {
    const [showModal,setShowModal ] = useState(false);
    const [curContainer,setCurContainer ] = useState(container || {
        pos:[]
    });

    const CargoOrderHandler = async e => {
        e.preventDefault()
        //const {id, pos, ...payload} = curContainer
        const payload = ( ({id,pos}) => ({id, pos}) )(curContainer)
        await sendRequest('PUT',`translator/inspections/${inspectionId}/container/cargo-order`,payload)
        onUpdate()
        setShowModal(false)
    }

    const upOrder = (po) => {
        const pos = [...curContainer.pos] 
        const index = pos.findIndex( p => p.po_nbr===po.po_nbr)
        if(index > 0){
            const tmp = pos[index];
            pos[index] = pos[index - 1]
            pos[index -1] = tmp
        }
        setCurContainer( {...curContainer,pos:pos})
    }
    const downOrder = (po) => {
        const pos = [...curContainer.pos] 
        const index = pos.findIndex( p => p.po_nbr===po.po_nbr)
        if(index < pos.length -1){
            const tmp = pos[index];
            pos[index] = pos[index + 1]
            pos[index +1] = tmp
        }
        setCurContainer( {...curContainer,pos:pos})
    }
    useEffect( () => {
        setCurContainer(container)
    },[container])

    return <div className={` ${className}`}>
        <div>
            {curContainer?.pos?.length > 1 && <button className="" onClick={() => setShowModal(true)}>change order</button>}
        </div>
        {showModal && <Modal onSubmit={CargoOrderHandler} hideModal={() => setShowModal(false)}>
            <h4 className="mt0">Cargo Order</h4>
            <table>
                <tbody>
                    {curContainer?.pos?.map( po => <tr key={po.id}>
                        <td><button type="button" onClick={ () => upOrder(po)}>^</button> <button type="button" onClick={ () => downOrder(po)}>v</button></td>
                        <td>{po.po_nbr}</td>
                    </tr>)}
                </tbody>
            </table>
            <button type="submit">Save Order</button>
        </Modal>}
    </div>
}

export default CargoOrder