import { createContext } from "react";

export const AppContext = createContext({
    isLoggedIn: false,
    token: null,
    user: null,
    login: () => {},
    logout: () => {},
})

export const setToken = (token) => {
    localStorage.setItem('token',token)
}
export const unsetToken = () => {
    localStorage.removeItem('token')
}
export const setUser = (user) => {
    localStorage.setItem('user',JSON.stringify(user))
}
export const unsetUser = () => {
    localStorage.removeItem('user')
}
