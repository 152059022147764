import { useEffect, useState } from "react"
import sendRequest from "../../shared/sendRequest"

const SelectPort = ({value, onChange, country, onChangeKey}) => {
    const[ports, setPorts] = useState([])
    const[filteredPorts, setFilteredPorts] = useState([])
    const[filter, setFilter] = useState(true)

    const getPorts = async () => {
        try{
            const response = await sendRequest('GET','ports');
            setPorts(response.ports)
        }catch(er){ console.log(er) }
    }
    const onChangeHandler = (id) => {
        onChange(id)
        onChangeKey(ports.find( p => p.id === id).key || '')
    }
    useEffect( () => {
        if(filter && country){
            setFilteredPorts(ports.filter( p => p.country && p.country.id === country))
        }else{
            setFilteredPorts(ports)
        }
    },[ports,country,filter])
    /*useEffect( () => {
        if(country){
            setFilter(true)
        }else{
            setFilter(false)
        }
    },[country])*/
    useEffect( () => {
        getPorts()
    },[])

    const handleChange = () => {
        setFilter(!filter);
    };

    return <div>
        <select value={value} onChange={ e => onChangeHandler( e.target.value)}>
            <option value="">Choose a Port</option>
            {filteredPorts.map( port => <option key={port.id} value={port.id}>({port.key || "?"}) {port.name}, {port.country && port.country.name}</option>)}
        </select> 
        <label>
            <input type="checkbox" checked={filter} onChange={handleChange} />
            Solo en el país de origen
        </label>
    </div>
}
export default SelectPort