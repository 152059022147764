
import { useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import sendRequest from "../../shared/sendRequest";

const CreateCustomBrokerPage = () => {
    const navigate = useNavigate();

    const [customBroker, setCustomBroker] = useState({
        name:'',
        key:'',
        email:'',
        phone:'',
        address:'',
        notes:''
    })

    const createCustomBroker = async e =>{
        e.preventDefault()
        await sendRequest('POST','custom-brokers',customBroker)
        navigate('/custom-brokers')
    }

    return <div className="page">
        <header>
            <h3>Create Custom Broker</h3>
            <Link to="/custom-brokers"><button className="link">{'<'} back</button></Link>
        </header>
        <main className="scrollable">
            <form onSubmit={createCustomBroker} className="card">
                <label>
                    Name
                    <input placeholder="Name" value={customBroker.name} onChange={ e => setCustomBroker( state => ({...state, name:e.target.value}))} />
                </label>
                <label>
                    Key
                    <input placeholder="Key" value={customBroker.key} onChange={ e => setCustomBroker( state => ({...state, key:e.target.value}))} />
                </label>
                <label>
                    Email
                    <input type="email" placeholder="Email" value={customBroker.email} onChange={ e => setCustomBroker( state => ({...state, email:e.target.value}))} />
                </label>
    
    
                <label>
                    Phone
                    <input placeholder="Phone" value={customBroker.phone} onChange={ e => setCustomBroker( state => ({...state, phone:e.target.value}))} />
                </label>

                <label>
                    Address
                    <textarea placeholder="Address" value={customBroker.address} onChange={ e => setCustomBroker( state => ({...state, address:e.target.value}))} />
                </label>
                <label>
                    Notes
                    <textarea placeholder="Notes" value={customBroker.notes} onChange={ e => setCustomBroker( state => ({...state, notes:e.target.value}))} />
                </label>
                
                
                <button type="submit">Create Custom Broker</button>
            </form>


        </main>
    </div>
}

export default CreateCustomBrokerPage;