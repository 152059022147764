import { useEffect, useState } from "react"
import Page from "../../shared/components/UIElements/Page"
import sendRequest from "../../shared/sendRequest"
import { Button } from "../../shared/components/UIElements/Dumbs"
import Modal from "../../shared/components/UIElements/Modal"

const Structure = () => {

    const [showModal, setShowModal] = useState(false)
    const [column, setColumn] = useState()
    const [ncolumn, setNColumn] = useState({
        order:'',
        name:'',
        model:'',
        destiny:'',
        type:'',
        special:'',
        headerBkg:'',
        headerColor:''
    })
    const [columns, setColumns] = useState([])

    const getStructure = async () => {
        try {
            const {columns} = await sendRequest('GET','daily-reports/structure')
            setColumns(columns)
        }catch(er){ console.log(er)} 
    }

    const createCol = () => {
        setShowModal(true)
    }
    const editColumn = (c) => {
        setColumn(c)
    }
    const CreateColumnHandler = async(e) => {
        e.preventDefault()
        await sendRequest('POST','daily-reports/structure/',ncolumn)
        getStructure()
        setShowModal(false)
        setNColumn({
            order:'',
            name:'',
            model:'',
            destiny:'',
            type:'',
            special:'',
            headerBkg:'',
            headerColor:''
        })
    }
    const UpdateColumnHandler = async(e) => {
        e.preventDefault()
        await sendRequest('PATCH','daily-reports/structure/'+column.id,column)
        getStructure()
        setColumn(null)
    }


    useEffect( () => {
        getStructure()
    },[])

    return <Page>
        <Page.Header>
            <h3>Daily Report - Structure</h3>
            <Button onClick={createCol}>Create</Button>
        </Page.Header>
        <Page.Body className="scrollable">
            <table className="card">
                <thead>
                    <tr>
                        <th>Order</th>
                        <th>Name</th>
                        <th>Model</th>
                        <th>Destiny</th>
                        <th>Type</th>
                        <th>Special</th>
                        <th>Header Background</th>
                        <th>Header Color</th>
                        <th>Edit in Inspection</th>
                        <th>Show in Report</th>

                        <th>Show in Analysis</th>
                        <th>Edit in Analysis</th>

                        <th>Info</th>
                        <th>Notes</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {columns?.map( c => <tr key={c.id}>
                    <td>{c.order}</td>
                    <td>{c.name}</td>
                    <td>{c.model}</td>
                    <td>{c.destiny}</td>
                    <td>{c.type || 'string'}</td>
                    <td>{c.special}</td>
                    <td>{c.headerBkg ? '#'+c.headerBkg : ''}</td>
                    <td>{c.headerColor ? '#'+c.headerColor : ''}</td>
                    <td>{c.editInInspection ? 'yes' : 'no'}</td>
                    <td>{c.showInReport ? 'yes' : 'no'}</td>

                    <td>{c.showInAnalysis ? 'yes' : 'no'}</td>
                    <td>{c.editInAnalysis ? 'yes' : 'no'}</td>

                    <td>{c.info}</td>
                    <td>{c.notes}</td>
                    <td><Button onClick={ () => editColumn(c) }>Edit</Button></td>
                </tr>)}
            </tbody>
            </table>
        </Page.Body>


        {showModal && <Modal onSubmit={CreateColumnHandler} hideModal={() => setShowModal(false)}>

            <label>
                Order:
                <input value={ncolumn.order} type="number" onChange={ e => setNColumn({...ncolumn, order: e.target.value})} />
            </label>
            <label>
                Name:
                <input value={ncolumn.name} onChange={ e => setNColumn({...ncolumn, name: e.target.value})} />
            </label>
            <label>
                Model:
                <input value={ncolumn.model} onChange={ e => setNColumn({...ncolumn, model: e.target.value})} />
            </label>
            <label>
                Destiny:
                <input value={ncolumn.destiny} onChange={ e => setNColumn({...ncolumn, destiny: e.target.value})} />
            </label>
            <label>
                Type:
                <input value={ncolumn.type} onChange={ e => setNColumn({...ncolumn, type: e.target.value})} />
            </label>
            <label>
                Special:
                <input value={ncolumn.special} onChange={ e => setNColumn({...ncolumn, special: e.target.value})} />
            </label>
            <label>
                Header Background:
                <input value={ncolumn.headerBkg} onChange={ e => setNColumn({...ncolumn, headerBkg: e.target.value})} />
            </label>
            <label>
                Header Color:
                <input value={ncolumn.headerColor} onChange={ e => setNColumn({...ncolumn, headerColor: e.target.value})} />
            </label>
            <label>
                Edit In Inspection:
                <input type="checkbox" checked={ncolumn.editInInspection} onChange={ e => setNColumn({...ncolumn, editInInspection: !ncolumn.editInInspection})} />
            </label>
            <label>
                Show in Report:
                <input type="checkbox" checked={ncolumn.showInReport} onChange={ e => setNColumn({...ncolumn, showInReport: !ncolumn.showInReport})} />
            </label>

            <label>
                Show in Analysis:
                <input type="checkbox" checked={ncolumn.showInAnalysis} onChange={ e => setNColumn({...ncolumn, showInAnalysis: !ncolumn.showInAnalysis})} />
            </label>
            <label>
                Edit In Analysis:
                <input type="checkbox" checked={ncolumn.editInAnalysis} onChange={ e => setNColumn({...ncolumn, editInAnalysis: !ncolumn.editInAnalysis})} />
            </label>


            <label>
                Info:
                <input value={ncolumn.info} onChange={ e => setNColumn({...ncolumn, info: e.target.value})} />
            </label>
            <label>
                Notes:
                <input value={ncolumn.notes} onChange={ e => setNColumn({...ncolumn, notes: e.target.value})} />
            </label>
   
            <button type="submit">Create</button>
        </Modal>}


        {column && <Modal onSubmit={UpdateColumnHandler} hideModal={() => setColumn(null)}>

            <label>
                Order:
                <input value={column.order} type="number" onChange={ e => setColumn({...column, order: e.target.value})} />
            </label>
            <label>
                Name:
                <input value={column.name} onChange={ e => setColumn({...column, name: e.target.value})} />
            </label>
            <label>
                Model:
                <input value={column.model} onChange={ e => setColumn({...column, model: e.target.value})} />
            </label>
            <label>
                Destiny:
                <input value={column.destiny} onChange={ e => setColumn({...column, destiny: e.target.value})} />
            </label>
            <label>
                Type:
                <input value={column.type} onChange={ e => setColumn({...column, type: e.target.value})} />
            </label>
            <label>
                Special:
                <input value={column.special} onChange={ e => setColumn({...column, special: e.target.value})} />
            </label>
            <label>
                Header Background:
                <input value={column.headerBkg} onChange={ e => setColumn({...column, headerBkg: e.target.value})} />
            </label>
            <label>
                Header Color:
                <input value={column.headerColor} onChange={ e => setColumn({...column, headerColor: e.target.value})} />
            </label>
            <label>
                Edit In Inspection:
                <input type="checkbox" checked={column.editInInspection} onChange={ e => setColumn({...column, editInInspection: !column.editInInspection})} />
            </label>
            <label>
                Show in Report:
                <input type="checkbox" checked={column.showInReport} onChange={ e => setColumn({...column, showInReport: !column.showInReport})} />
            </label>

            <label>
                Show in Analysis:
                <input type="checkbox" checked={column.showInAnalysis} onChange={ e => setColumn({...column, showInAnalysis: !column.showInAnalysis})} />
            </label>
            <label>
                Edit In Analysis:
                <input type="checkbox" checked={column.editInAnalysis} onChange={ e => setColumn({...column, editInAnalysis: !column.editInAnalysis})} />
            </label>


            <label>
                Info:
                <input value={column.info} onChange={ e => setColumn({...column, info: e.target.value})} />
            </label>
            <label>
                Notes:
                <input value={column.notes} onChange={ e => setColumn({...column, notes: e.target.value})} />
            </label>
   
            <button type="submit">Set</button>
        </Modal>}
    </Page>
}

export default Structure