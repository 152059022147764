import { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import sendRequest from "../../shared/sendRequest";

const InspectorManagersPage = () => {

    const [managers, setManagers] = useState([])
    
    const getManagers = async() => {
        const response = await sendRequest('GET','inspectors/managers');
        setManagers(response.managers)
    }

    useEffect( () => {
        getManagers()
    },[])

    return <div className="page">
        <header>
            <h3>Inspector Managers</h3>
            <Link to="/create-inspector-manager"><button className="blue">+ Create Inspector Manager</button></Link>
        </header>
        <main className="scrollable">
            <table className="card">
                <thead>
                    <tr>
                        <th>Level</th>
                        <th>User</th>
                        <th>Description</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {managers?.map( manager => <tr key={manager.id}>
                        <td>{manager.level}</td>
                        <td>{manager.user?.name}</td>
                        <td>{manager.description}</td>
                        <td></td>
                    </tr>)}
                </tbody>
            </table>
        </main>
    </div>
}

export default InspectorManagersPage