import { useEffect, useState } from "react"
import Page from "../../shared/components/UIElements/Page"
import sendRequest from "../../shared/sendRequest"
import {Button} from "../../shared/components/UIElements/Dumbs"

const Filters = () => {
    const [filters, setFilters] = useState([])

    const getFilters = async () => {
        try {
            const {filters} = await sendRequest('GET','po-placement-filters')
            setFilters(filters)
        }catch(er){ console.log(er)} 
    }
    useEffect( () => {
        getFilters()
    },[])

    return <Page>
        <Page.Header>
            <h3>PO Placemenets - Filters</h3>
        </Page.Header>
        <Page.Body className="scrollable">
            <table className="card">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Columns</th>
     
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {filters?.map( filter => <tr key={filter.id}>
                    <td>{filter.name}</td>
                    <td>{filter.columns.length}</td>
                    <td><Button link={`/po-placements/filter/${filter.id}`}>Edit</Button></td>
                </tr>)}
            </tbody>
            </table>
        </Page.Body>
    </Page>
}

export default Filters