import { useEffect, useState } from "react"
import { Button } from "../../../shared/components/UIElements/Dumbs"
import Modal from "../../../shared/components/UIElements/Modal"
import sendRequest from "../../../shared/sendRequest"

const ModelEditor = ({model, closeModal,onUpdate}) => {
    const [_model,setModel] = useState({})
    const [_complement,setComplement] = useState({})
    const [columns,setColumns] = useState([])
    const getStructure = async () => {
        try {
            const {columns} = await sendRequest('GET','daily-reports/structure')
            setColumns(columns.filter( c => c.editInInspection))
        }catch(er){ console.log(er)} 
    }
    const updateComplement = async e =>{
        e.preventDefault()
        let complement
        if(_complement.id){
            complement = await sendRequest('PUT',`translator/inspections/model-complement/${_complement.id}`,_complement)
        }else{
            complement = await sendRequest('POST',`translator/inspections/model-complement/${model.id}`,_complement)
        }
        onUpdate(complement)
        closeModal()
    }

    useEffect( () => {
        setModel(model)
        setComplement(model?._modelComplement || {})//fill structure with columns
    },[model])
    useEffect( () => {
        getStructure()
    },[])
    return <>
        {_model && <Modal className="scrollable" onSubmit={updateComplement} hideModal={closeModal}>
            Model Editor 
            <hr />

            { columns?.map( c => <Input key={c.id} struc={c} 
                                    model={_model._model[c.model]} 
                                    complement={_complement?.[c.destiny] || null} 
                                    onChange={ v => setComplement({..._complement, [c.destiny]:v })}
                                />)}
            <Button type="submit">Save changes</Button>
    
        </Modal>}
    </>
    
}

const Input = ({struc,model,complement, onChange}) => {
    const [value, setValue] = useState('')
    useEffect( () => {
        setValue(complement || model)
    },[model,complement])

    return <label>
        {struc.name}
        <input type={struc.type} defaultValue={value} onChange={ e => onChange(e.target.value)} />
    </label>
}
export default ModelEditor