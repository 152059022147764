
import { useCallback, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import CountrySelector from "../../shared/components/CountrySelector";
import sendRequest from "../../shared/sendRequest";
import AddUser from "../components/AddUser";

const CoordinatorPage = () => {
    const {id} = useParams()

    const navigate = useNavigate();

    const [coordinator, setCoordinator] = useState({
        name:'',
        country:'',
        //user:'',
        //active:'',
        note:''
    })

    const updateCoordinator = async e =>{
        e.preventDefault()
        await sendRequest('PUT','coordinators/' + id,coordinator)
        navigate('/coordinators')
    }

    const deleteCoordinator = async() => {
        if(!window.confirm('Confirm')) return false
        await sendRequest('DELETE','coordinators/' + id);
        navigate('/coordinators')
    }

    const getCoordinator = useCallback(async() => {
        if(!id) return false;
        const {coordinator} = await sendRequest('GET','coordinators/' + id);
        setCoordinator(coordinator)
    },[id])

    useEffect( () => {
        getCoordinator()
    },[getCoordinator])
    
    return <div className="page">
        <header>
            <h3>Edit coordinator</h3>
            <Link to="/coordinators"><button className="link">{'<'} back</button></Link>
        </header>
        <main className="scrollable">
            <form onSubmit={updateCoordinator} className="card">
                <label>
                    Name
                    <input placeholder="Name" value={coordinator.name} onChange={ e => setCoordinator( state => ({...state, name:e.target.value}))} />
                </label>
                <label>
                    Country
                    <CountrySelector inspectables value={coordinator.country} onChange={ country => setCoordinator( state => ({...state,country}) )} />
                </label>

                <label>
                    Note
                    <textarea placeholder="Note" value={coordinator.note} onChange={ e => setCoordinator( state => ({...state, note:e.target.value}))} />
                </label>
                
                
                <button type="submit">Update Coordinator</button>
            </form>

            <div className="card">
                <h3>User</h3>
                { coordinator.user ? <div>{coordinator.user.email}</div>
                : <AddUser id={coordinator.id} onUpdate={ coordinator => setCoordinator(coordinator)} />
                }
            </div>

            

            <div className="card">
                <h3>DANGER ZONE</h3>
                <button onClick={deleteCoordinator}>Remove Coordinator</button>
            </div>


        </main>
    </div>
}

export default CoordinatorPage;