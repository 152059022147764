import { useEffect, useState } from "react"
import sendRequest from "../../shared/sendRequest";

const SelectCustomer = ({customer, onChange, onChangeKey}) => {
    const [customers,setCustomers] = useState([]);

    const onChangeHandler = (id) => {
        onChange(id)
        onChangeKey(customers.find( c => c.id === id).key || '')
    }
    
    const getCustomers = async () => {
        try{
            const response = await sendRequest('GET','api/customers');
            setCustomers(response.customers)
        }catch(er){ console.log(er) }
    }

    useEffect( () => {
        getCustomers()
    },[])

    return <select value={customer} onChange={e => onChangeHandler(e.target.value)}>
        <option value={""}>Elige un cliente</option>
        {customers?.map( c => <option value={c.id} key={c.id} >({c.key ? c.key : "?"}) {c.alias && `${c.alias}, `}{c.name}</option>)}
    </select>
}
export default SelectCustomer