import moment from "moment"
import { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import sendRequest from "../../shared/sendRequest"

const POsPage = () => {
    const [perPage, setPerPage] = useState(25)

    const [pagination, setPagination] = useState({
        page:0,
        perPage:0,
        pages:0,
        count:0
    })
    const [page, setPage] = useState(1)
    const [pos, setPOs] = useState([])
    
    const getPOs = useCallback( async() => {
        const {pos,pagination} = await sendRequest('GET',`pos?perPage=${perPage}&page=${page}`);
        setPagination(pagination)
        setPOs(pos)
    },[perPage,page]);

    useEffect( () => {
        getPOs()
    },[getPOs])
    return <div className="page">
        <header>
            <div  style={{flex:1}}>
                <div style={{ display:"flex", alignItems: 'center', justifyContent:'space-between'}}>
                    <h3>Purchase Orders</h3>
                    {true && <Link to="/create-po"><button className="blue">+ Create PO</button></Link>}
                </div>
            
                <div>
                    Page:
                    <input type="number" className="mr20" style={{width:30, textAlign:"center"}} value={page} onChange={ e => setPage( e.target.value )} />
                    Per page:
                    <input type="number" className="mr20" style={{width:30, textAlign:"center"}} value={perPage} onChange={ e => setPerPage( e.target.value )} />
                    <small className="mr20">Page {pagination.page} of {pagination.pages} </small>
                    <small className="mr20">Showing {(perPage*(page-1)) + 1} to {(perPage*(page-1)) + perPage} of {pagination.count} </small>
                </div>
            </div>
    
        </header>
        <main className="scrollable">
            

            <table className="card">
                <thead>
                    <tr>
                        <th></th>
                        <th>PO Placement</th>
                        <th>PO nbr</th>
                        <th>Early Ship Date</th>
                        <th>Late Ship Date</th>
                        <th>Country</th>
                        <th>Company</th>
                        <th>Port</th>
                        <th>Provider</th>
                        <th>Coordinator</th>
                        <th>Created</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {pos.map( (po, index) => <tr key={po.id}>
                    <td className="center">{(perPage*(page-1)) + index + 1}</td>
                    <td>{po.POPlacement?.name}</td>
                    <td>{po["po_nbr"]}</td>
                    <td>{po["early_ship_date"] && moment(po["early_ship_date"]).format('DD/MMM/Y')}</td>
                    <td>{po["late_ship_date"] && moment(po["late_ship_date"]).format('DD/MMM/Y')}</td>
                    <td>{po._country?.name}</td>
                    <td>{po._company?.name}</td>
                    <td>{po._port?.name}</td>
                    <td>{po._provider?.name}</td>
                    <td>{po._coordinator?.name}</td>
                    <td>{po.createdAt && moment(po.createdAt).format('DD/MMM/Y')}</td>
                    <td><Link to={`/po/${po.id}`}><button>edit</button></Link></td>
                </tr>)}
                </tbody>
            </table>
        </main>
    </div>
}

export default POsPage