import { useEffect, useState } from "react"
import Card from "../../../shared/components/UIElements/Card"
import { Button } from "../../../shared/components/UIElements/Dumbs"
import Modal from "../../../shared/components/UIElements/Modal"
import sendRequest from "../../../shared/sendRequest"

const defaultS = {
    allContainersHasDrySac:false,
    howManyBagsDidEachContainerHave:'',
    doesTheFactoryCooperate:false,
    theBagsWerePlacedCorrectly:false,
    observations:[]
}
const ObservationsDrySac = ({inspectionId,observations,onUpdate}) => {
    
    const [_observations,setObservations ] = useState(observations ? {...observations,howManyBagsDidEachContainerHave:observations.howManyBagsDidEachContainerHave || ''} : {...defaultS})


    const [edito,setEditO ] = useState();
    const [editoI,setEditOI ] = useState();
    const [newObservation,setNewObservation ] = useState('');
    const [showModal,setShowModal ] = useState(false);

    const editObservationC = async () => {
        setShowModal(true)
    }
    const EditObservationHandler = async e => {
        e.preventDefault()
    }

    const addObservation = async () => {
        if(newObservation){
            await _preserveChanges({..._observations,observations:[..._observations.observations,newObservation]})
            setNewObservation('')
        }
    }
    const editObservation = (i) => {
        setEditOI(i)
        setEditO(_observations.observations[i])
    }
    const deleteObservation =  async () => {
        if(!window.confirm('confirm?')) return false
        let observations = [..._observations.observations];
        observations.splice(editoI, 1);
        await _preserveChanges({..._observations,observations})
        setEditOI(null)
        setEditO(null)
    }
    const updateObservation =  async () => {
        let observations = [..._observations.observations];
        observations[editoI] = edito;
        await _preserveChanges({..._observations,observations})
        setEditOI(null)
        setEditO(null)
    }
    const updateAllContainersHasDrySac = async () => {
        await _preserveChanges({..._observations,allContainersHasDrySac:!_observations.allContainersHasDrySac})
    }
    const updateHowManyBagsDidEachContainerHave = async (no) => {
        await _preserveChanges({..._observations,howManyBagsDidEachContainerHave:no})
    }
    const updateDoesTheFactoryCooperate = async () => {
        await _preserveChanges({..._observations,doesTheFactoryCooperate:!_observations.doesTheFactoryCooperate})
    }
    const updateTheBagsWerePlacedCorrectly = async () => {
        await _preserveChanges({..._observations,theBagsWerePlacedCorrectly:!_observations.theBagsWerePlacedCorrectly})
    }
    const _preserveChanges = async (drysac) => {
        await sendRequest('PUT',`translator/inspections/${inspectionId}/observations`,{ drysac})
        onUpdate()
    }
    useEffect( () => {
        let ob = observations ? {...observations,howManyBagsDidEachContainerHave:observations.howManyBagsDidEachContainerHave || ''} : {}
       setObservations({...defaultS,...ob})    
    },[observations])
    return <>
    <Card>
            

        <h4 className=" mt0">Dry-Sac observations</h4>

        <label style={{flexDirection:'row', alignItems:'center'}}>All Containers has Dry-Sac? <b className="ml10"> {_observations.allContainersHasDrySac ? 'yes': 'no'}</b></label> 

        <label style={{flexDirection:'row', alignItems:'center'}}>How many bags did each container have? <b className="ml10"> {_observations.howManyBagsDidEachContainerHave}</b></label> 

        <label style={{flexDirection:'row', alignItems:'center'}}>The bags were placed correctly? <b className="ml10"> {_observations.theBagsWerePlacedCorrectly ? 'yes': 'no'}</b></label> 

        <label style={{flexDirection:'row', alignItems:'center'}}>Does the factory cooperate to place the drySac bags? <b className="ml10"> {_observations.doesTheFactoryCooperate ? 'yes': 'no'}</b></label> 
        {_observations && <div>
            <ol>
                {_observations.observations?.map( (o,i) => <li key={i}>{o}</li>)}
            </ol>
        </div>}
        <Button onClick={() => editObservationC()}>edit</Button>


    </Card>
        {showModal && <Modal onSubmit={EditObservationHandler} hideModal={() => setShowModal(false)}>
            <h3>Dry-Sac observations</h3>
            <label style={{flexDirection:'row', alignItems:'center'}}>
            All Containers has Dry-Sac?
               <input type="checkbox" checked={_observations.allContainersHasDrySac} onChange={updateAllContainersHasDrySac}  />
           </label>
            <label style={{flexDirection:'row', alignItems:'center'}}>
                How many bags did each container have?
               <input value={_observations.howManyBagsDidEachContainerHave} onChange={e => setObservations(state => ({...state,howManyBagsDidEachContainerHave:e.target.value}))} onBlur={e => updateHowManyBagsDidEachContainerHave(e.target.value)}  />
           </label>
            <label style={{flexDirection:'row', alignItems:'center'}}>
            The bags were placed correctly?
               <input type="checkbox" checked={_observations.theBagsWerePlacedCorrectly} onChange={updateTheBagsWerePlacedCorrectly}  />
           </label>
            <label style={{flexDirection:'row', alignItems:'center'}}>
            Does the factory cooperate to place the drySac bags?
               <input type="checkbox" checked={_observations.doesTheFactoryCooperate} onChange={updateDoesTheFactoryCooperate}  />
           </label>


           <ol>
                {_observations.observations?.map( (o,i) => <li key={i}>{o} <Button onClick={ () => editObservation(i)} className="link">edit</Button></li>)}
            </ol>

           {edito ?
           <>
           <textarea style={{width:"100%"}} rows={3} value={edito} onChange={ e => setEditO(e.target.value)} placeholder="edit observation"/>
           <Button onClick={updateObservation} type="button">Update</Button><Button onClick={deleteObservation} type="button">Delete</Button>
           </>
           :
           <>
           <textarea style={{width:"100%"}} rows={3} value={newObservation} onChange={ e => setNewObservation(e.target.value)} placeholder="add a Dry-Sac observation"/>
           <Button onClick={addObservation} type="button">Add</Button>
           </>
            }
        </Modal>}
    </>
}

export default ObservationsDrySac