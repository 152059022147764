import sendRequest from "../../../shared/sendRequest";

const OpenSignedProgramming = ({programmingId, onOpen}) => {

    const openProgramming = async () => {
        if(!window.confirm('confirm')) return false;
        const {programming} = await sendRequest('PUT',`coordinator/programmings/${programmingId}/open-signed`)
        onOpen(programming.id)
    }

    return <div className="card" style={{border: 'orange 2px solid'}}>
        <b>DANGER ZONE</b><br/>
        <button className="mt20" style={{border: 'orange 2px solid', background:'#f2ae61', color:'black'}} onClick={openProgramming}>Open Signed Programming</button>
    </div>
}

export default OpenSignedProgramming