import moment from "moment"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import sendRequest from "../../shared/sendRequest"

const CoordinatorsPage = () => {
    const [coordinators, setCoordinators] = useState([])
    
    const getCoordinators = async() => {
        const {coordinators} = await sendRequest('GET','coordinators');
        setCoordinators(coordinators)
    }
    

    useEffect( () => {
        getCoordinators()
    },[])
    return <div className="page">
        <header>
            <h3>Coordinators</h3>
            <Link to="/create-coordinator"><button className="blue">+ Create Coordinator</button></Link> 
        </header>
        <main className="scrollable">
            

            <table className="card">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Country</th>
                        <th>User</th>
                        <th>Active</th>
                        <th>Note</th>
                        <th>Created</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {coordinators.map( coordinator => <tr key={coordinator.id}>
                    <td>{coordinator.name}</td>
                    <td>{coordinator.country?.name}</td>
                    <td>{coordinator.user?.email}</td>
                    <td className="center">{coordinator.active ? 'yes' : 'no'}</td>
                    <td><small>{coordinator.note}</small></td>

                    <td>{coordinator.createdAt && moment(coordinator.createdAt).format('DD/MMM/Y')}</td>
                    <td><Link to={`/coordinator/${coordinator.id}`}><button>edit</button></Link></td>
                </tr>)}
                </tbody>
            </table>
        </main>
    </div>
}

export default CoordinatorsPage