import { useState } from "react";
import Modal from "../../shared/components/UIElements/Modal";
import sendRequest from "../../shared/sendRequest";

const AddUser = ({id, onUpdate}) => {

    const [showModal,setShowModal ] = useState(false);
    const [user,setUser] = useState({
        email:'',
        password:''
    });

    const createUser = async e => {
        e.preventDefault()
        if(user.email.trim() === '' || user.password.trim() === '') return false;
        const coordinator = await sendRequest('POST','coordinators/add-user/' + id,user)
        onUpdate(coordinator)
        setShowModal(false)
    }

    return <>
        <button onClick={() => setShowModal(true)}>Crate User</button>
        {showModal && <Modal onSubmit={createUser} hideModal={() => setShowModal(false)}>
            <label>
                Email
                <input type="email" placeholder="email" value={user.email} onChange={ e => setUser({ ...user, 'email': e.target.value})} />
            </label>
            <label>
                Password
                <input type="password" placeholder="password" value={user.password} onChange={ e => setUser({ ...user, 'password': e.target.value})} />
            </label>
            <button type="submit">Create User</button>
        </Modal>}
    </>
}

export default AddUser