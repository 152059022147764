import { useCallback, useEffect, useRef, useState } from "react"

// https://medium.com/@650egor/simple-drag-and-drop-file-upload-in-react-2cb409d88929

const DragNDrop = ({onDrop,children,imageType, className, container=undefined, po=undefined, model=undefined}) => {
    const [draggingIn, setDraggingIn] = useState(false)
    const dndEl = useRef(null);

    const handleDragIn = e => {
        e.preventDefault()
        e.stopPropagation()
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDraggingIn(true)
          }
        //console.log('dragIn')
    }
    const handleDragOut = e => {
        e.preventDefault()
        e.stopPropagation()
        setDraggingIn(false)
        //console.log('dragOut')
    }
    const handleDrag = e => {
        e.preventDefault()
        e.stopPropagation()
    }

    const dropped = useCallback((files) => {
        onDrop(files, imageType, container, po, model)
    },[onDrop, imageType, container, po, model])

    const handleDrop= useCallback( e => {
        e.preventDefault()
        e.stopPropagation()
        setDraggingIn(false)
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            dropped(e.dataTransfer.files)
            e.dataTransfer.clearData()
            //this.dragCounter = 0
        }
    },[dropped])
    

    useEffect( () => {
        let dnd = dndEl.current
        dnd.addEventListener("dragenter", handleDragIn);
        dnd.addEventListener("dragleave", handleDragOut);
        dnd.addEventListener("dragover", handleDrag);
        dnd.addEventListener("drop", handleDrop);
        return () => {
            dnd.removeEventListener("dragenter", handleDragIn);
            dnd.removeEventListener("dragleave", handleDragOut);
            dnd.removeEventListener("dragover", handleDrag);
            dnd.removeEventListener("drop", handleDrop);
        };
    },[handleDrop])
    return <div ref={dndEl} className={`${className} ${draggingIn ? 'dragging' : ''}`}>
        {children}
    </div>
}
export default DragNDrop