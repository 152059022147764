import { useState } from "react";
import { Card } from "../../../shared/components/UIElements/Dumbs";
import { imageApi, pdfApi } from "../../../shared/sendRequest";
import ReportDnD from "./ReportDnD";

import { useTranslation } from 'react-i18next';

const Report = ({inspection}) => {

    const { t } = useTranslation();

    const [showImages,setShowImages] = useState(false);

    const [images,setImages] = useState([]);

    const generatePDF = async() => {
        await pdfApi('GET',`translator/inspections/${inspection.id}/pdf`)
        
    }
    const getImages = async() => {
        const {images} = await imageApi('GET',`translator/inspections/${inspection.id}/images`)
        setImages(images)
    }

    const showImagesHandler = () => {
        if(!images.length) getImages()
        setShowImages(!showImages)
    }

    return <Card>
        {false &&  <button onClick={generatePDF}>PDF</button>}
        Inspection Report <button onClick={showImagesHandler}>{t('Show photos')}</button>
        
        {showImages && <ReportDnD className="mt20" images={images} inspection={inspection} />}
    </Card>
}
export default Report;