import moment from "moment"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import sendRequest from "../../shared/sendRequest"

const LocationsPage = () => {
    const [locations, setLocations] = useState([])
    
    const getLocations = async() => {
        const {locations} = await sendRequest('GET','locations');
        setLocations(locations)
    }

    useEffect( () => {
        getLocations()
    },[])
    return <div className="page">
        <header>
            <h3>Locations</h3>
            <Link to="/create-location"><button className="blue">+ Create Location</button></Link>
        </header>
        <main className="scrollable">
            

            <table className="card">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Country</th>
                        <th>City</th>
                        <th>Address</th>
                        <th>Notes</th>
                        <th>Created</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {locations.map( location => <tr key={location.id}>
                    <td>{location.name}</td>
                    <td>{location.country?.name}</td>
                    <td>{location.city}</td>
                    <td><small>{location.address}</small></td>
                    <td><small>{location.notes}</small></td>
                    <td>{location.createdAt && moment(location.createdAt).format('DD/MMM/Y')}</td>
                    <td><Link to={`/location/${location.id}`}><button>edit</button></Link></td>
                </tr>)}
                </tbody>
            </table>
        </main>
    </div>
}

export default LocationsPage