import { Route, Routes } from "react-router-dom"
import Inspection from "./pages/Inspection"
import Inspections from "./pages/Inspections"

const BOInspectionRoutes = () => {
    return <Routes>
        <Route path="/back-office/inspections" element={<Inspections />}/>
        <Route path="/back-office/inspection/:id" element={<Inspection />}/>


    </Routes>
}
export default BOInspectionRoutes