import { useEffect, useState } from "react"
import sendRequest from "../sendRequest";



const CoordinatorSelector = ( {coordinatorId, onChange }) => {

    const [coordinators, setCoordinators] = useState([])

    const getCoordinators = async () => {
        try{
            const {coordinators} = await sendRequest('GET',`coordinators`);
            setCoordinators(coordinators)
        }catch(er){ console.log(er) }

    }
    useEffect( () => {
        getCoordinators()
    },[])

    return <select value={coordinatorId} onChange={ e => onChange( e.target.value)}>
            <option value="">Choose a Coordinator</option>
            {coordinators.map( coordinator => <option key={coordinator.id} value={coordinator.id}>{coordinator.name} ({coordinator?.country?.name})</option>)}
        </select>
}

export default CoordinatorSelector;