
const Paginator = ({pagination, onChangePage, onChangePerPage}) => {

    if(!pagination)  return <small>Loading...</small>
    return <div>
        Page:
        <input type="number" className="mr20" style={{width:30, textAlign:"center"}} value={pagination?.page} onChange={ e => onChangePage( e.target.value )} />
        Per page:
        <input type="number" className="mr20" style={{width:30, textAlign:"center"}} value={pagination?.perPage} onChange={ e => onChangePerPage( e.target.value )} />
        <small className="mr20">Page {pagination?.page} of {pagination?.pages} </small>
        <small className="mr20">Showing {(pagination?.perPage*(pagination?.page-1)) + 1} to {(pagination?.perPage*(pagination?.page-1)) + pagination?.perPage} of {pagination?.count} </small>
    </div>
}
export default Paginator