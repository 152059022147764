const _request = async (method, path, body, headers,endpoint) => {
    let defaultHeaders = {
        'Content-Type': 'application/json;charset=utf-8',
    }
    if(localStorage.token) {
        defaultHeaders = { ...defaultHeaders, "Authorization" :'Bearer ' + localStorage.token };
    }
    if(['GET'].includes(method)){
        body = undefined
    }
    try{
        const fetchResponse = await fetch(endpoint + path,{
            method,
            headers:{...defaultHeaders,...headers},
            body:JSON.stringify(body),
        })
        if(fetchResponse.ok){
        return await fetchResponse.json()
        }
    }catch(er){
        throw new Error(er)
    }
}

export const pdfApi = async (method, path, body = {}, headers = {}) => {
    return await _request(method, path, body, headers, process.env.REACT_APP_PDF_API)
}
export const imageApi = async (method, path, body = {}, headers = {}) => {
    return await _request(method, path, body, headers, process.env.REACT_APP_IMAGE_API)
}
export const authApi = async (method, path, body = {}, headers = {}) => {
    return await _request(method, path, body, headers, process.env.REACT_APP_AUTH_API)
}
export const sendRequest = async (method, path, body = {}, headers = {}) => {
    return await _request(method, path, body, headers, process.env.REACT_APP_API_ENDPOINT)
}

export default sendRequest