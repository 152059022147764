import { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Paginator from "../../shared/components/UIElements/Paginator"
import sendRequest from "../../shared/sendRequest"

const CustomersPage = () => {

    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(25)
    const [pagination, setPagination] = useState({
        page:0,
        perPage:0,
        pages:0,
        count:0
    })

    const [customers, setCustomers] = useState([])

    const getCustomers = useCallback( async () => {
        try{
            const {customers,pagination} = await sendRequest('GET',`customers?perPage=${perPage}&page=${page}`);
            setPagination(pagination)
            setCustomers(customers)
        }catch(er){
            console.log(er)
        }
    },[perPage,page])

    useEffect( () => {
        getCustomers()
    },[getCustomers])

    return <div className="page">
        <header>
        <div  style={{flex:1}}>
                <div style={{ display:"flex", alignItems: 'center', justifyContent:'space-between'}}>
                    <h3>Customers</h3>
                    <Link to="/create-customer"><button className="blue">+ Create Customer</button></Link>
                </div>
            
                <Paginator pagination={pagination} onChangePage={setPage} onChangePerPage={setPerPage} />
            </div>
        </header>
        <main className="scrollable">

            <table className="card">
                <thead>
                    <tr>
                        <th></th>
                        <th>Key</th>
                        <th>Alias</th>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Active</th>
                        <th>TradeLens</th>
                        <th>Primary</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {customers.map( (customer,index) => <tr key={customer.id}>
                        <td className={`center `}>{(perPage*(page-1)) + index + 1}</td>
                        <td>{customer.key}</td>
                        <td>{customer.alias}</td>
                        <td>{customer.name}</td>
                        <td className="noWrap">{customer.phone}</td>
                        <td>{customer.email}</td>
                        <td className="center">{customer.active ? 'yes' : 'no' }</td>
                        <td className="center">{customer.tradeLens ? 'yes' : 'no' }</td>
                        <td className="center">{customer.primary ? 'yes' : 'no' }</td>
                        <td><Link to={`/customer/${customer.id}`}><button>edit</button></Link></td>
                    </tr>)}
                </tbody>
            </table>
            {false && <pre>{JSON.stringify(customers, null, 2)}</pre>}
        </main>
    </div>
}
export default CustomersPage