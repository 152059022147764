
import { useCallback, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import CountrySelector from "../../shared/components/CountrySelector";
import sendRequest from "../../shared/sendRequest";

const PortPage = () => {
    const {id} = useParams()

    const navigate = useNavigate();

    const [port, setPort] = useState({
        key:'',
        name:'',
        country:''
    })

    const updatePort = async e =>{
        e.preventDefault()
        await sendRequest('PUT','ports/' + id,port)
        navigate('/ports')
    }

    const deletePort = async() => {
        if(!window.confirm('Confirm')) return false
        await sendRequest('DELETE','ports/' + id);
        navigate('/ports')
    }

    const getPort = useCallback(async() => {
        if(!id) return false;
        const {port} = await sendRequest('GET','ports/' + id);
        port.key = port.key ?? ''
        setPort(port)
    },[id])

    useEffect( () => {
        getPort()
    },[getPort])

    return <div className="page">
        <header>
            <h3>Edit Port</h3>
            <Link to="/ports"><button className="link">{'<'} back</button></Link>
        </header>
        <main className="scrollable">
            <form onSubmit={updatePort} className="card">
                <label>
                    Key
                    <input placeholder="Key" value={port.key} onChange={ e => setPort( state => ({...state, key:e.target.value}))} />
                </label>
                <label>
                    Name
                    <input placeholder="Name" value={port.name} onChange={ e => setPort( state => ({...state, name:e.target.value}))} />
                </label>
                <label>
                    Country
                    <CountrySelector inspectables value={port.country} onChange={ country => setPort( state => ({...state,country}) )} />
                </label>
                
                
                <button type="submit">Update Port</button>
            </form>

            <div className="card">
                <h3>DANGER ZONE</h3>
                <button onClick={deletePort}>Remove Port</button>
            </div>


        </main>
    </div>
}

export default PortPage;