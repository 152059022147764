import moment from "moment"
import { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import sendRequest from "../../shared/sendRequest"

const ModelsPage = () => {
    const [perPage, setPerPage] = useState(25)

    const [pagination, setPagination] = useState({
        page:0,
        perPage:0,
        pages:0,
        count:0
    })
    const [page, setPage] = useState(1)
    const [models, setModels] = useState([])
    
    const getModels = useCallback( async() => {
        const {models,pagination} = await sendRequest('GET',`models?perPage=${perPage}&page=${page}`);
        setPagination(pagination)
        setModels(models)
    },[perPage,page]);

    useEffect( () => {
        getModels()
    },[getModels])
    return <div className="page">
        <header>
            <div  style={{flex:1}}>
                <div style={{ display:"flex", alignItems: 'center', justifyContent:'space-between'}}>
                    <h3>Models</h3>
                    {false && <Link to="/create-model"><button className="blue">+ Create Model</button></Link>}
                </div>
            
                <div>
                    Page:
                    <input type="number" className="mr20" style={{width:30, textAlign:"center"}} value={page} onChange={ e => setPage( e.target.value )} />
                    Per page:
                    <input type="number" className="mr20" style={{width:30, textAlign:"center"}} value={perPage} onChange={ e => setPerPage( e.target.value )} />
                    <small className="mr20">Page {pagination.page} of {pagination.pages} </small>
                    <small className="mr20">Showing {(perPage*(page-1)) + 1} to {(perPage*(page-1)) + perPage} of {pagination.count} </small>
                </div>
            </div>
    
        </header>
        <main className="scrollable">
            

            <table className="card">
                <thead>
                    <tr>
                        <th></th>
                        <th>PO Placement</th>
                        <th>PO nbr</th>
                        <th>pack desc</th>
                        <th>irf desc</th>
                        <th>mat desc</th>
                        <th>upc</th>
                        
                        <th>Created</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {models.map( (po, index) => <tr key={po.id}>
                    <td className="center">{(perPage*(page-1)) + index + 1}</td>
                    <td>{po.POPlacement?.name}</td>
                    <td>{po["po_nbr"]}</td>
                    <td>{po["pack_desc"]}</td>
                    <td>{po["irf_desc"]}</td>
                    <td>{po["mat_desc"]}</td>
                    <td>{po["upc"]}</td>
                    <td>{po.createdAt && moment(po.createdAt).format('DD/MMM/Y')}</td>
                    <td>{false && <Link to={`/po/${po.id}`}><button>edit</button></Link>}</td>
                </tr>)}
                </tbody>
            </table>
        </main>
    </div>
}

export default ModelsPage