import moment from "moment-timezone"
import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import DownloadButton from "../../../shared/components/DownloadButton";
import Card from "../../../shared/components/UIElements/Card";
import sendRequest from "../../../shared/sendRequest";
import CargoOrder from "../components/CargoOrder";
import ContainersCount from "../components/ContainersCount";
import CreateContainer from "../components/CreateContainer";
import CustomBroker from "../components/CustomBroker";
import EditContainer from "../components/EditContainer";
//import EditModel from "../components/EditModel";
//import ModelContainer from "../components/ModelContainer";
import Models from "../components/Models";
import Observations from "../components/Observations";
import ObservationsContainers from "../components/ObservationsContainers";
import ObservationsDrySac from "../components/ObservationsDrySac";
import ObservationsWeather from "../components/ObservationsWeather";
import Report from "../components/Report";
import UploadReport from "../components/UploadReport";

const Inspection = () => {

    const {id} = useParams()
    const [inspection,setInspection] = useState();

    const getInspection = useCallback( async() => {
        const {inspection} = await sendRequest('GET','translator/inspections/' + id)
        setInspection(inspection)
    },[id]);
    useEffect( () => {
        getInspection()
    },[getInspection]) 

    const signInspection = async () => {
        if(!window.confirm('confirm')) return false;
        //TODO: ADD VAlidations (loadingdate, inpectors, locations, containers_count, pos)
        await sendRequest('PATCH',`translator/inspections/${id}/sign`,{now:moment()})
        getInspection()
    } 

    const setContainersCount = async count => {
        await sendRequest('PATCH',`translator/inspections/${id}/containers-count`,{count})
        getInspection()
    }

    const removeZip = async () => {
        if(!window.confirm('confirm?')) return false
        await sendRequest('DELETE',`translator/inspections/${id}/delete-zip`)
        getInspection()
    }

    const [dt,setDt] = useState('');
    useEffect( () => {
        /*  
        let mom = moment().tz('Asia/Shanghai')
        {moment.tz.guess()} <br/>
        {mom.tz()} <br/>
        */
        if(!dt) return false
        console.log(moment(dt).format())
        console.log(moment.utc(dt).format())
        console.log(moment.tz(dt,'America/Mexico_City').format())
        console.log(moment.tz(dt,'Asia/Shanghai').format())
    },[dt])
    

    return <div className="page">
        <header>
            <h3>Inspection {inspection?.reference}</h3>
            {false && <input type="date" value={dt} onChange={ e => setDt(e.target.value)} />}
       

        </header>
        <main className="scrollable">
            <Card>
                <div className="mb5">Country: <b>{inspection?.programming?._country?.name}</b></div>
                <div className="mb5">Customer: <b>{inspection?.programming?._company?.customer?.name}</b></div>
                <div className="mb5">Company: <b>{inspection?.programming?._company?.name}</b></div>
                <div className="mb5">Provider: <b>{inspection?.programming?._provider?.name}</b></div>
                <div className="mb5">Reference: <b>{inspection?.reference}</b></div>
                <div className="mb5">Loading Date(s): {inspection?.dates?.map( d => <span className={`badge ${d === inspection?.loadingDate ? 'primary' : ''}`} key={d} >{moment(d).format('DD/MMM/YY')}</span>)}</div>
                <div className="mb5">Port: <b>{inspection?.port?.name}</b></div>
                <div className="mb5">Location(s): {inspection?.programming?.locations?.map( l => <div className="ml20" key={l.id} ><b>{l.name}</b></div>)}</div>
                <div className="mb5">Inspector(s): {inspection?.programming?.inspectors?.map( i => <div className="ml20" key={i.id} ><b>{i.name}</b></div>)}</div>
                <hr/>
                <div className="mb5">Coordinator: <b>{inspection?.coordinator?.name}</b></div>
                <div className="mb5">Translator: <b>{inspection?.translator?.name}</b></div>
                <hr/>
                {inspection?.signedDate &&  <div>Signed Date: <b>{moment(inspection?.signedDate).format('DD/MMM/Y Z')}</b></div>}
                {!inspection?.signedDate && <div className="textRight"><button onClick={signInspection}>Sign Inspection</button></div>}
            </Card>
            <Card>
                <div className="pb10">
                    Containers Count: <span className={`badge primary ${inspection?.noContainers !== inspection?.programming?.noContainers ? 'yellow' : ''}`}>{inspection?.noContainers || "-"}</span> 
                    <div style={{float:'right'}}>
                        {!inspection?.signedDate && <ContainersCount count={inspection?.noContainers} onCountSet={ setContainersCount } />}
                    </div>
                </div>
                <div className="pb10">
                    <CustomBroker id={inspection?.id} value={inspection?.customBroker} onChange={getInspection} />
                </div>
                {inspection?.zipName ?
                <div>
                    <DownloadButton label={`Download Report (${inspection?.zipName})`} url={`translator/inspections/${inspection?.id}/download-zip`} filename={inspection?.zipName} />
                    <button onClick={removeZip}>Remove</button>
                </div>
                :
                <UploadReport inspection={inspection?.id} onComplete={getInspection} />
                }
                
            </Card>
                
            <Report inspection={inspection} />

            {/*}
            <Card className="p0 pt10">
                <h4 className="m20 mb10 mt0">PO / Models</h4>
                <div className="scrollable">
                <table>
                    <thead>
                        <tr>
                            <th>Provider</th>
                            <th>PO nbr</th>
                            <th>Model</th>
                            <th>Container</th>
                            <th>Description</th>
                            <th>Ctns.</th>
                            <th>Pcs/Ctn.</th>
                            <th>Total Pcs.</th>
                            <th>UPC</th>
                            <th>Material</th>
                            <th>Brand</th>
                            <th>Letter</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {inspection?.programming?.pos?.map( po  => {
                            return po._models.map( m => <tr key={m.id}>
                                <td>{po._provider?.name}</td>
                                <td>{po.po_nbr}</td>
                                <td>
                                    {(m.model && m.model !== m.vndr_stk_nbr) ? 
                                        <div>
                                            <div style={{textDecoration: "line-through"}}>{m.vndr_stk_nbr}</div>
                                            {m.model}
                                        </div> 
                                    : m.vndr_stk_nbr} 
                                </td>
                                <td><ModelContainer model={m} containers={inspection?.containers || []} id={inspection.id} onChange={getInspection} /></td>
                                <td>{m.mat_desc}</td>
                                <td className={`center ${!m.ctns && 'yellow'} ${(m.ctns && m.ctns !== m.total_packs) && 'red'} ${(m.ctns && m.ctns === m.total_packs) && 'green'}`}>{(m.ctns && m.ctns !== m.total_packs) ? <div><div style={{textDecoration: "line-through"}}>{m.total_packs}</div>{m.ctns}</div> : m.total_packs}</td>
                                <td className={`center 
                                            ${(m.pcs_ctn && m.pcs_ctn !== m.vndr_pack) && 'red'} 
                                            ${(m.pcs_ctn && m.pcs_ctn === m.vndr_pack) && 'green'}`}>
                                    {(m.pcs_ctn && m.pcs_ctn !== m.vndr_pack) ? 
                                        <div>
                                            <div style={{textDecoration: "line-through"}}>{m.vndr_pack}</div>
                                            {m.pcs_ctn}
                                        </div> 
                                    : m.vndr_pack} 
                                </td>
                                <td className={`center ${(m.ctns && m.ctns*(m.pcs_ctn || m.vndr_pack) !== m.total_units) && 'red'} ${(m.ctns && m.ctns*(m.pcs_ctn || m.vndr_pack) === m.total_units) && 'green'}`}>
                                    {(m.ctns && m.ctns*(m.pcs_ctn || m.vndr_pack) !== m.total_units) ? 
                                        <div>
                                            <div style={{textDecoration: "line-through"}}>{m.total_units}</div>
                                            {m.ctns*(m.pcs_ctn || m.vndr_pack)}
                                        </div> 
                                    : m.total_units}
                                </td>
                                <td>{(m.customUPC && m.customUPC !== m.upc) ? 
                                        <div>
                                            <div style={{textDecoration: "line-through"}}>{m.upc}</div>
                                            {m.customUPC}
                                        </div> 
                                    : m.upc} </td>
                                <td>{m.material}</td>
                                <td>{m.brand}</td>
                                <td>{m.letter}</td>
                                <td><EditModel model={m} id={inspection.id} onChange={getInspection} /> </td>
                            </tr> )
                        })}
                    </tbody>
                </table>
                </div>
                    </Card> */}
            <Card className="p0 pt10">
                <div className="floatRight mr20"><CreateContainer inspectionId={inspection?.id} onCreation={getInspection} /></div>
                
                <h4 className="m20 mt0">Cargo Info</h4>
                <div className="scrollable">
                <table style={{minWidth:'100%'}}>
                    <thead>
                        <tr>
                            <th rowSpan={2}>Container #</th>
                            <th rowSpan={2}>Seal #</th>
                            <th colSpan={4}>Container Kind</th>
                            <th rowSpan={2}>Total Cartons</th>
                            <th rowSpan={2}>Cargo Order</th>
                            <th colSpan={3}>Contrainer Status</th>
                            <th rowSpan={2}></th>
                        </tr>
                        <tr>
                            <th>20'ST</th>
                            <th>40'ST</th>
                            <th>40'HC</th>
                            <th>45'HC</th>

                            <th>Good</th>
                            <th>Damaged</th>
                            <th>Serious Damaged</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {inspection?.containers?.map( c  => <tr key={c.id}>
                            <td>{c.container_nbr}</td>
                            <td>{c.seal_nbr}</td>
                            <td style={{backgroundColor: c.kind === "20'ST" ? 'blue': 'transparent'}}></td>
                            <td style={{backgroundColor: c.kind === "40'ST" ? 'blue': 'transparent'}}></td>
                            <td style={{backgroundColor: c.kind === "40'HC" ? 'blue': 'transparent'}}></td>
                            <td style={{backgroundColor: c.kind === "45'HC" ? 'blue': 'transparent'}}></td>
                            <td className="center">{c.total_ctns}</td>
                            <td>
                                {c.pos.map( po => <div key={po.id}>{po.po_nbr}</div>)}
                                <CargoOrder onUpdate={getInspection} inspectionId={inspection?.id} container={c} />
                            </td>
                            <td style={{backgroundColor: c.status === "good" ? 'blue': 'transparent'}}></td>
                            <td style={{backgroundColor: c.status === "damaged" ? 'blue': 'transparent'}}></td>
                            <td style={{backgroundColor: c.status === "seriousDamaged" ? 'blue': 'transparent'}}></td>
                            <td><EditContainer onUpdate={getInspection} inspectionId={inspection?.id} container={c} /></td>
                        </tr>)}
                    </tbody>
                </table>
                </div>
            </Card>
            
            <Models inspection={inspection?._id} 
                pos={inspection?.programming?.pos} 
                containers={inspection?.containers} 
                dailyReports={inspection?._dailyReports} 
                onComplete={ e => getInspection()}
            />

            <ObservationsContainers inspectionId={id} observations={inspection?._observations?.containers} onUpdate={ e => getInspection() } />
            <ObservationsWeather inspectionId={id} observations={inspection?._observations?.weather} onUpdate={ e => getInspection() } />
            <ObservationsDrySac inspectionId={id} observations={inspection?._observations?.drysac} onUpdate={ e => getInspection() } />
            <Observations inspectionId={id} observations={inspection?._observations?.other} onUpdate={ e => getInspection() } />

            {false && <Card><pre>{JSON.stringify(inspection,null,2)}</pre></Card>}
        </main>

    </div>
}
export default Inspection