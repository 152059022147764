
import { useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import CountrySelector from "../../shared/components/CountrySelector";
import sendRequest from "../../shared/sendRequest";

const CreateWarehouse = () => {

    const navigate = useNavigate();

    const [warehouse, setWarehouse] = useState({
        name:'',
        country:'',
        address:'',
        note:''
    })

    const createWarehouse = async e =>{
        e.preventDefault()
        await sendRequest('POST','warehouses',warehouse)
        navigate('/warehouses')
    }

    return <div className="page">
        <header>
            <h3>Create Warehouse</h3>
            <Link to="/warehouses"><button className="link">{'<'} back</button></Link>
        </header>
        <main className="scrollable">
            <form onSubmit={createWarehouse} className="card">
                <label>
                    Name
                    <input placeholder="Name" value={warehouse.name} onChange={ e => setWarehouse( state => ({...state, name:e.target.value}))} />
                </label>
                <label>
                    Country
                    <CountrySelector inspectables value={warehouse.country} onChange={ country => setWarehouse( state => ({...state,country}) )} />
                </label>

                <label>
                    Address
                    <textarea placeholder="Address" value={warehouse.address} onChange={ e => setWarehouse( state => ({...state, address:e.target.value}))} />
                </label>
                <label>
                    Note
                    <textarea placeholder="Note" value={warehouse.note} onChange={ e => setWarehouse( state => ({...state, note:e.target.value}))} />
                </label>
                
                <button type="submit">Create Warehouse</button>
            </form>

        </main>
    </div>
}

export default CreateWarehouse;