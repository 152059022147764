import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
const LngSelector = () => {
    const { i18n } = useTranslation();
    const [lng,setLng] = useState(i18n.resolvedLanguage);

    useEffect( () => {
        i18n.changeLanguage(lng);
    },[lng,i18n])

    return <select className='lng-selector' value={lng} onChange={ e => setLng(e.target.value)}>
        <option>en</option>
        <option>es</option>
        <option>cn</option>
        <option>vn</option>
    </select>
}
export default LngSelector