
import { useCallback, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import CountrySelector from "../../shared/components/CountrySelector";
import sendRequest from "../../shared/sendRequest";
import CoordinatorSelector from "../../shared/components/CoordinatorSelector";

const ProviderPage = () => {
    const {id} = useParams()

    const navigate = useNavigate();

    const [provider, setProvider] = useState({
        name:'',
        country:'',
        //user:'',
        //active:'',
        note:''
    })

    const updateProvider = async e =>{
        e.preventDefault()
        await sendRequest('PUT','providers/' + id,provider)
        navigate('/providers')
    }

    const deleteProvider = async() => {
        if(!window.confirm('Confirm')) return false
        await sendRequest('DELETE','providers/' + id);
        navigate('/providers')
    }

    const getProvider = useCallback(async() => {
        if(!id) return false;
        const {provider} = await sendRequest('GET','providers/' + id);
        setProvider(provider)
    },[id])

    useEffect( () => {
        getProvider()
    },[getProvider])

    if(!provider.id) return <div className="page"> Loading...</div>

    return <div className="page">
        <header>
            <h3>Edit provider</h3>
            <Link to="/providers"><button className="link">{'<'} back</button></Link>
        </header>
        <main className="scrollable">
            <form onSubmit={updateProvider} className="card">
                <label>
                    Name
                    <input placeholder="Name" value={provider.name} onChange={ e => setProvider( state => ({...state, name:e.target.value}))} />
                </label>
                <label>
                    Country
                    <CountrySelector inspectables value={provider.country} onChange={ country => setProvider( state => ({...state,country}) )} />
                </label>

                <label>
                    Coordinator
                    <CoordinatorSelector coordinatorId={provider._coordinator} onChange={ _coordinator => setProvider( state => ({...state,_coordinator}) ) } />
                </label>
                
                <label>
                    Note
                    <textarea placeholder="Note" value={provider.note} onChange={ e => setProvider( state => ({...state, note:e.target.value}))} />
                </label>
                
                
                <button type="submit">Update Provider</button>
            </form>

            <div className="card">
                <h3>DANGER ZONE</h3>
                <button onClick={deleteProvider}>Remove Provider</button>
            </div>


        </main>
    </div>
}

export default ProviderPage;