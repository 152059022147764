import moment from "moment"
import { useCallback, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Button } from "../../../shared/components/UIElements/Dumbs"
import Paginator from "../../../shared/components/UIElements/Paginator"
import sendRequest from "../../../shared/sendRequest"

const ProgrammingsPage = () => {

    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(25)
    const [pagination, setPagination] = useState({
        page:0,
        perPage:0,
        pages:0,
        count:0
    })

    const [programmings, setProgrammings] = useState([])

    const getProgrammings = useCallback( async() => {
        const {programmings,pagination} = await sendRequest('GET',`coordinator/programmings?perPage=${perPage}&page=${page}`);
        setPagination(pagination)
        setProgrammings(programmings)
    },[perPage,page]);

    useEffect( () => {
        getProgrammings()
    },[getProgrammings])

    return <div className="page">
        <header>
            <div  style={{flex:1}}>
                <div style={{ display:"flex", alignItems: 'center', justifyContent:'space-between'}}>
                    <h3>Programmings</h3>
                    <div>
                    <Button link="/coordinator/create-cfs-programming" className="blue mr10">+ Create CFS Programming</Button>
                    <Button link="/coordinator/create-programming" className="blue">+ Create CY Programming</Button>

                    </div>
                </div>
            
                <Paginator pagination={pagination} onChangePage={setPage} onChangePerPage={setPerPage} />
            </div>
    
        </header>
        <main className="scrollable">

<table className="card">
    <thead>
        <tr>
            <th></th>
            <th>Kind</th>
            <th>PO nbrs</th>
            <th>Country</th>
            <th>Company</th>
            <th>Port</th>
            <th>Provider</th>
            <th>Loading Date</th>
            <th>Inspectors</th>
            <th>Programming Created</th>
            <th>Booking form sended</th>
            <th>Booking form received</th>
            <th>Signed</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
    {programmings.map( (po, index) => <tr key={po.id} >
        <td className={`center ${po.parent ? 'yellow' : ''}`}>{(perPage*(page-1)) + index + 1}</td>
        <td>{po.kind}</td>
        <td>{po.pos?.map( po => <div key={po.id}>{po.po_nbr}</div>)}</td>
        <td>{po._country?.name}</td>
        <td>{po._company?.name}</td>
        <td>{po._port?.name}</td>
        <td>{po._provider?.name}</td>
        <td>{po.loadingDate && moment(po.loadingDate).format('DD/MMM/Y')}</td>
        <td className={`${po.changes.includes('Inspectors') ? 'yellow' : ''}`}>{po.inspectors?.map( i => <div key={i.id}>{i.name}</div>)}</td>
        <td>{po.createdAt && moment(po.createdAt).format('DD/MMM/Y Z')}</td>
        <td>{po.bookingSendedDate && moment(po.bookingSendedDate).format('DD/MMM/Y Z')}</td>
        <td>{po.bookingReceivedDate && moment(po.bookingReceivedDate).format('DD/MMM/Y Z')}</td>
        <td>{po.signedDate && moment(po.signedDate).format('DD/MMM/Y HH:mm')}</td>
        <td><Link to={`/coordinator/programming/${po.id}`}><button>edit</button></Link></td>
    </tr>)}
    </tbody>
</table>
</main>
    </div>
}

export default ProgrammingsPage