import { useState } from "react"
import { useNavigate } from "react-router"
import { authApi } from "../../shared/sendRequest";

const RegisterPage = () => {

    const navigate = useNavigate();

    const [user,setUser] = useState({
        name:'',
        password:'',
        email:''
    })
    const registerUser = async e => {
        e.preventDefault()
        try {
            await authApi('POST','auth/register',user)
            navigate('/users')
        }catch(er){ console.log(er)} 
    }

    return <div>
        <form onSubmit={registerUser} className="card">
            <label>
                Name
                <input placeholder="name" value={user.name} onChange={ e => setUser( state => ({...state, name: e.target.value}) )} />
            </label>

            <label>
                Email
                <input placeholder="email" type="email" value={user.email} onChange={ e => setUser( state => ({...state, email: e.target.value}) )} />
            </label>

            <label>
                Password
                <input placeholder="password" type="password" value={user.password} onChange={ e => setUser( state => ({...state, password: e.target.value}) )} />
            </label>

           <button type="submit">Register</button>
        </form>
    </div>
}

export default RegisterPage;