import moment from "moment"
import { useCallback, useEffect, useState } from "react"
import { Button } from "../../shared/components/UIElements/Dumbs"
import UserSelector from "../../shared/components/UserSelector"
import sendRequest from "../../shared/sendRequest"

const AnalystAssignment = () => {
    const [drs, setDrs] = useState([])
    const [repStructure, setRepStructure] = useState([])

    const [filters, setFilters] = useState({
        translator:'',
        endDate:'',
        date: moment().valueOf()
    })
    const [perPage, setPerPage] = useState(25)

    const [pagination, setPagination] = useState({
        page:0,
        perPage:0,
        pages:0,
        count:0
    })
    const [page, setPage] = useState(1)

    const getStructure = async() => {
        const {columns} = await sendRequest('GET','daily-reports/report-structure')
        setRepStructure(columns)
    }

    const getReports = useCallback( async() => {
        let translatorFilter = filters.translator ? `&translator=${filters.translator}` : ''
        let dateF = filters.date ? `&date=${filters.date}` : ''
        let endDateF = filters.endDate ? `&enddate=${filters.endDate}` : ''
        
        const {pagination,drs} = await sendRequest('GET',`daily-reports?perPage=${perPage}&page=${page}${translatorFilter}${dateF}${endDateF}`);
        setPagination(pagination)
        setDrs(drs)
    },[perPage,page, filters]);


    useEffect( () => {
        getReports()
    },[getReports])

    useEffect( () => {
        getStructure()
    },[])

    const [analysts, setAnalysts] = useState([])
    const [analyst, setAnalyst] = useState('')
    const [selected,setSelected] = useState([])
    const select = (id) => {
        if(selected.find( s => s === id)){
            setSelected(state => [...state.filter( s => s !== id)])
        }else{
            setSelected(state => [...state, id])
        }
    }
    const assignModels = async () => {
        let sAnalyst = analysts.find( a => a.id === analyst)
        if(!window.confirm(selected.length + ' models will be assigned to ' + sAnalyst?.name)) return false
        await sendRequest('PUT',`daily-reports/assign-analyst`,{analyst,selected});
        setAnalyst('')
        setSelected([])
        getReports()
    }
    const getAnalysts =  async () => {
        try{
            const {users} = await sendRequest('GET',`users/analysts`);
            setAnalysts(users)
        }catch(er){ console.log(er) }
    }
    useEffect( () => {
        getAnalysts()
    },[])



    const generateDailyArrangement = () => {
        _downloadExcel( 'daily-reports/generate-excel/' + filters.date )
    }
    const _downloadExcel = async url => {
        try {
            const response = await sendRequest('GET',url)
            const buff = Buffer(response.buffer,'base64');
            var blob = new Blob([buff], {'type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            var downloadURL = URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = downloadURL;
            a.download = response.name;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }catch(er){ console.log(er)} 
    }
    
    return <div className="page">
        <header>
            <div  style={{flex:1}}>
                <div style={{ display:"flex", alignItems: 'center', justifyContent:'space-between'}}>
                    <h3>Daily Reports - Analyst Assignment</h3>
                    <button className="blue" onClick={() => generateDailyArrangement()}>Daily Report (Excel)</button>
                </div>
            
                <div>
                    Page:
                    <input type="number" className="mr20" style={{width:30, textAlign:"center"}} value={page} onChange={ e => setPage( e.target.value )} />
                    Per page:
                    <input type="number" className="mr20" style={{width:30, textAlign:"center"}} value={perPage} onChange={ e => setPerPage( e.target.value )} />
                    <small className="mr20">Page {pagination.page} of {pagination.pages} </small>
                    <small className="mr20">Showing {(perPage*(page-1)) + 1} to {(perPage*(page-1)) + perPage} of {pagination.count} </small>

                    <div className="floatRight">
                        <input className="mr20" type="date" value={moment(filters.date,'x').format('Y-MM-DD')} onChange={ e => setFilters({...filters,date:moment(e.target.value).valueOf()})}></input>
                        <input disabled={!filters.date} className="mr20" type="date" value={moment(filters.endDate,'x').format('Y-MM-DD')} onChange={ e => setFilters({...filters,endDate:moment(e.target.value).valueOf()})}></input>
                        <UserSelector kind="translators" value={filters.translator} onChange={ translator => setFilters(state => ({...state, translator}))} />
                    </div>
                </div>
                <div className="mt20">
                    {!!selected.length && <div>
                        {selected.length} selected
                        <UserSelector kind="analysts" list={analysts} value={analyst} onChange={setAnalyst} />
                        <Button disabled={!analyst} onClick={assignModels}>Assign</Button>
                    </div>}
                </div>
            </div>
    
        </header>
        <main className="scrollable">
  
            <table className="card">
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>Analyst</th>
                        <th>Translator</th>
                        {repStructure.map( (r,i) => <th style={{background : r.headerBkg ? '#'+r.headerBkg : '', color : r.headerColor ? '#'+r.headerColor : '' }} key={i}>{r.name}</th>)}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {drs.map( (p, index) => <tr key={p.id}>
                    <td className={`center ${p.parent ? 'yellow' : ''} ${p.hasChanged ? 'red' : ''}`}>{(perPage*(page-1)) + index + 1}</td>
                    
                    <td><input checked={selected.includes(p.id)} onClick={() => select(p.id)} type="checkbox" onChange={ () => false} /></td>
                    <td>{p._analyst?.name}</td>
                   
                    <td>{p._inspection?.translator?.name}</td>
                    {repStructure.map( (r,i) => {
                            let value = p._modelComplement?.[r.destiny] || p._model[r.model]
                            if(r.special) value = p._modelComplement?.[r.destiny] || p['_'+r.special.split('.')[0]][r.special.split('.')[1]]
                            switch(r.type){
                                case 'date':
                                    return <td key={i}>{value && moment(value).format('DD/MMM/Y Z')}</td>
                                default:
                                    return <td key={i}>{value}</td>
                            }
                        }
                    )}
                    <td></td>
                </tr>)}
                </tbody>
            </table>

        </main>
    </div>
}

export default AnalystAssignment