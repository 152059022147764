import { useCallback, useEffect, useRef, useState } from 'react';
import sendRequest from '../sendRequest';
import './ImageUpload.scss'

const ImageUploadS3 = ({prefix, id, images, type, inspection, container, po }) => {

    const [imagesArr, setImagesArr] = useState(images ?? []);
    const [file, setFile] = useState();
    const [previewUrl, setPreviewUrl] = useState();
    const [isValid, setIsValid] = useState(false);

    const filePickerRef = useRef();

    const pickedHandler = event => {
        let pickedFile;
        let fileIsValid = isValid;
        if(event.target.files && event.target.files.length === 1){
            pickedFile = event.target.files[0];
            setFile(pickedFile);
            fileIsValid = true;
        }else{
            fileIsValid = false;
        }
        setIsValid(fileIsValid);
    };
    const pickImageHandler = () => {
        filePickerRef.current.click();
    };

    const uploadToS3 = useCallback( async (presignedURL, imageId) => {
        const myHeaders = new Headers({ 'Content-Type': 'image/*' });
        const fetchResponse = await fetch(presignedURL, {
            method: 'PUT',
            headers: myHeaders,
            body: file
        });
        if (fetchResponse.ok){
            setImagesArr(state => [...state, imageId]);
            /*const imagesArrCopy = [...imagesArr]
            imagesArrCopy.push(imageId)
            setImagesArr(imagesArrCopy)*/
            setPreviewUrl(null)
        }
    },[file]);

    const getPresignedURL = useCallback( async () => {
        let payload = {
            name: file.name,
            type: file.type,
            imageType: type,
            inspection,
            container,
            po,
            prefix: prefix ?? ''
        }
        try{
            const response = await sendRequest('POST','api/inspections/presignedurl',payload);
            uploadToS3(response.url,response.imageId)
        }catch(er){ console.log(er) }
    },[file,prefix,type,inspection,container,po,uploadToS3]);
    
    
    const deleteImage = async (id) => {
        try{
            const response = await sendRequest('DELETE',`api/inspections/delete-image/${id}`);
            if (response){
                const imagesArrCopy = [...imagesArr];
                console.log(imagesArrCopy)
                const index = imagesArrCopy.indexOf(id);
                if (index > -1) {
                    imagesArrCopy.splice(index, 1)
                    setImagesArr(imagesArrCopy)
                }
                //TODO: remove the id from the array
            }
        }catch(er){ console.log(er) }

    }
    const getList = async (prefix) => {
        try{
            await sendRequest('POST','api/inspections/s3list',{prefix: prefix ?? ''});
        }catch(er){ console.log(er) }
    };

    useEffect( () => {
        if(file){
            getPresignedURL()
            const fileReader = new FileReader();
            fileReader.onload = () => {
                setPreviewUrl(fileReader.result)
            }
            fileReader.readAsDataURL(file);
        }
    },[file,getPresignedURL]) 

    useEffect( ( ) => {
        if(typeof prefix !== 'undefined' && prefix !== 'undefined') getList(prefix)
    },[prefix])

    useEffect( () => {
        if(images && images.length){
            setImagesArr(images)
        }
    },[images])
    return (
        <div>
            <small>{prefix}</small>
            <div className="image-upload__canvas">
                {imagesArr && imagesArr.map( image => <div key={image} className="image-upload__image">
                    <img  alt={prefix} src={`${window.api_url}api/inspections/serve-image/${image}`} />
                    <button className="image-upload__del-btn" onClick={ () => deleteImage(image)}>delete</button>
                </div>)}
            </div>
           
             <input id={id} 
                ref={filePickerRef}
                style={{display:'none'}} 
                type="file"
                accept=".jpg,.png,.jpeg"
                onChange={pickedHandler}
             />
             <div className="image-upload">
                 {previewUrl && <div className="image-upload__preview">
                     <img src={previewUrl} alt="Preview" />
                     <span className="image-upload__loader">Uploading...</span>
                 </div>}
                 <button type="button" onClick={pickImageHandler}>Pick Image</button>
             </div>      
        </div>
    )
}
export default ImageUploadS3;