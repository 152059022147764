import { useEffect } from 'react';
import ImageUploadS3 from '../../shared/components/ImageUploadS3';
import './PhotoCanvas.scss'

const PhotoCanvas = ({structure, reference, images}) => {

    useEffect( ( ) => {
        if(images && images.length){
            console.log(images.length)
        }
    },[images])

    return <div className="photo-canvas__container">
        <span className="photo-canvas__title">Inspection: {reference}</span>
        <div style={{textAlign:'right'}}>
            <ImageUploadS3 id={reference} prefix={`${reference}`} type="inspection" images={images} inspection={reference} />
        </div>
        { structure && structure.map( container => <div className="photo-canvas__container" key={container.container_nbr}>
            <div style={{textAlign:'right'}}>
                <ImageUploadS3 id={container.container_nbr} prefix={`${reference}/${container.container_nbr}`} images={container.images} inspection={reference} container={container.container_nbr} type="container" />
            </div>
            <span className="photo-canvas__title">Container: {container.container_nbr}</span>

            { container && container.pos && container.pos.map( (po) => <div className="photo-canvas__container" key={po.po_nbr}>
                <div style={{textAlign:'right'}}>
                    <ImageUploadS3 id={po.po_nbr} prefix={`${reference}/${container.container_nbr}/${po.po_nbr}`} images={po.images} inspection={reference} container={container.container_nbr} po={po.po_nbr} type="po" />
                </div>
                <span className="photo-canvas__title">PO: {po.po_nbr}</span>
        
            </div>)}
        </div>)}
    </div>
}

export default PhotoCanvas;