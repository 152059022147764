
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import CountrySelector from "../../shared/components/CountrySelector";
import { AppContext } from "../../shared/context/AppContext";
import sendRequest from "../../shared/sendRequest";

const UserPage = () => {

    const {user : currentUser} = useContext(AppContext)

    const {id} = useParams()

    const navigate = useNavigate();

    const [user, setUser] = useState({
        name:'',
        email:'',
        country:'',
        city:''
    })

    const updateUser = async e =>{
        e.preventDefault()
        await sendRequest('PUT',`users/${user.id}`,user)
        navigate('/users')
    }



    const deleteUser = useCallback(async() => {
        if(!id) return false;
        if(!window.confirm('Confirm')) return false
        await sendRequest('DELETE','users/' + id);
        navigate('/users')
    },[id,navigate])

    const getUser = useCallback(async() => {
        if(!id) return false;
        const {user} = await sendRequest('GET','users/' + id);
        setUser(user)
    },[id])

    useEffect( () => {
        getUser()
    },[getUser])

    return <div className="page">
        <header>
            <h3>User</h3>
            <Link to="/users"><button className="link">{'<'} back</button></Link>
        </header>
        <main className="scrollable">
            {user?.id &&  <form onSubmit={updateUser} className="card">
                <label>
                    Name
                    <input placeholder="Name" value={user.name} onChange={ e => setUser( state => ({...state, name:e.target.value}))} />
                </label>
                <label>
                    Email
                    <input placeholder="Email" type="email" value={user.email} onChange={ e => setUser( state => ({...state, email:e.target.value}))} />
                </label>
                <label>
                    Country
                    <CountrySelector value={user.country} onChange={ country => setUser( state => ({...state,country}) )} />
                </label>
                <label>
                    City (tmp)
                    <input placeholder="City" value={user.city} onChange={ e => setUser( state => ({...state, city:e.target.value}))} />
                </label>
                <label style={{flexDirection:'row', alignItems: 'center'}}>
                        Developer
                        <input type="checkbox" checked={user.isDev} onChange={ e => setUser( state => ({...state, isDev:!user.isDev}))} />
                    </label>
                    <label style={{flexDirection:'row', alignItems: 'center'}}>
                        Administrator
                        <input type="checkbox" checked={user.isAdmin} onChange={ e => setUser( state => ({...state, isAdmin:!user.isAdmin}))} />
                    </label>
                    <label style={{flexDirection:'row', alignItems: 'center'}}>
                        Coordinator
                        <input type="checkbox" checked={user.isCoordinator} onChange={ e => setUser( state => ({...state, isCoordinator:!user.isCoordinator}))} />
                    </label>
                    <label style={{flexDirection:'row', alignItems: 'center'}}>
                        Translator
                        <input type="checkbox" checked={user.isTranslator} onChange={ e => setUser( state => ({...state, isTranslator:!user.isTranslator}))} />
                    </label>
                    <label style={{flexDirection:'row', alignItems: 'center'}}>
                        Back Office
                        <input type="checkbox" checked={user.isBackOffice} onChange={ e => setUser( state => ({...state, isBackOffice:!user.isBackOffice}))} />
                    </label>
                    <label style={{flexDirection:'row', alignItems: 'center'}}>
                        Analyst
                        <input type="checkbox" checked={user.isAnalyst} onChange={ e => setUser( state => ({...state, isAnalyst:!user.isAnalyst}))} />
                    </label>
                
                
                <button type="submit">Update User</button>
            </form>}
            {currentUser.isDev && <button onClick={deleteUser}>Delete User</button>}
        </main>
    </div>
}

export default UserPage;