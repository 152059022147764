
import { useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import CountrySelector from "../../shared/components/CountrySelector";
import sendRequest from "../../shared/sendRequest";

const CreateCoordinatorPage = () => {
    const navigate = useNavigate();

    const [coordinator, setCoordinator] = useState({
        name:'',
        country:'',
        //user:'',
        //active:'',
        note:''
    })

    const createCoordinator = async e =>{
        e.preventDefault()
        await sendRequest('POST','coordinators',coordinator)
        navigate('/coordinators')
    }

    return <div className="page">
        <header>
            <h3>Create Coordinator</h3>
            <Link to="/coordinators"><button className="link">{'<'} back</button></Link>
        </header>
        <main className="scrollable">
            <form onSubmit={createCoordinator} className="card">
                <label>
                    Name
                    <input placeholder="Name" value={coordinator.name} onChange={ e => setCoordinator( state => ({...state, name:e.target.value}))} />
                </label>
                <label>
                    Country
                    <CountrySelector inspectables value={coordinator.country} onChange={ country => setCoordinator( state => ({...state,country}) )} />
                </label>

                <label>
                    Note
                    <textarea placeholder="Note" value={coordinator.note} onChange={ e => setCoordinator( state => ({...state, note:e.target.value}))} />
                </label>
                
                
                <button type="submit">Create Coordinator</button>
            </form>


        </main>
    </div>
}

export default CreateCoordinatorPage;