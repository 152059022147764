import { useEffect, useState } from "react"
import sendRequest from "../sendRequest";
// <WarehouseSelector value="" onChange={ wh => console.log(wh)} />

const WarehouseSelector = ({ value, onChange, disabled}) =>{
    
    const [wharehouses, setWarehouses] = useState([])

    const getWarehouses = async () => {
        try{
            const {warehouses} = await sendRequest('GET',`warehouses`);
            setWarehouses(warehouses)
        }catch(er){ console.log(er) }

    }
    useEffect( () => {
        getWarehouses()
    },[])
    
    return <select disabled={disabled} value={value} onChange={ e => onChange( e.target.value)}>
        <option value="">Choose a Warehouse</option>
        {wharehouses.map( wh => <option key={wh.id} value={wh.id}>{wh.name}</option>)}
    </select>
}

export default WarehouseSelector