import { useState, useEffect } from "react";
import Modal from "../../shared/components/UIElements/Modal";
import sendRequest from "../../shared/sendRequest";
import CurrencySelector from "../../shared/components/CurrencySelector";

const RMBDeposit = ({id, amount, currency, onChange, className}) => {

    const [showModal,setShowModal ] = useState(false);
    const [curr,setCurrency ] = useState(currency?.id || '');
    const [amo,setAmount ] = useState(amount || '');

    const CountSetHandler = async e => {
        e.preventDefault()

        await sendRequest('PATCH',`daily-arrangements/${id}/rmb-deposit`,{
            currency:curr,
            amount:amo
        })
        onChange()
        setShowModal(false)
    }
    useEffect( () => {
        setCurrency(currency?.id)
        setAmount(amount)
    },[currency,amount]) 
    return <>
        RMB Deposit: <b>{amount && amount.toFixed(2)}</b> <b>{currency ? ` (${currency.code})` : ""}</b>
        <div style={{float:'right'}}><button onClick={() => setShowModal(true)}>Set RMB Deposit</button></div>


        {showModal && <Modal onSubmit={CountSetHandler} hideModal={() => setShowModal(false)}>
            <input type="number" placeholder='0.00' step='0.01' value={amo} onChange={ e => setAmount(e.target.value) } />
            <CurrencySelector value={curr} onChange={ e => setCurrency(e) } />
            <button type="submit">Set</button>
        </Modal>}
    </>
}

export default RMBDeposit