import moment from "moment"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import sendRequest from "../../shared/sendRequest"

const PortsPage = () => {
    const [ports, setPorts] = useState([])
    
    const getPorts = async() => {
        const {ports} = await sendRequest('GET','ports');
        setPorts(ports)
    }

    useEffect( () => {
        getPorts()
    },[])
    return <div className="page">
        <header>
            <h3>Ports</h3>
           <Link to="/create-port"><button className="blue">+ Create Port</button></Link>
        </header>
        <main className="scrollable">
            

            <table className="card">
                <thead>
                    <tr>
                        <th>Key</th>
                        <th>Name</th>
                        <th>Country</th>
                        <th>Created</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {ports.map( port => <tr key={port.id}>
                    <td>{port.key}</td>
                    <td>{port.name}</td>
                    <td>{port.country?.name}</td>
                    <td>{port.createdAt && moment(port.createdAt).format('DD/MMM/Y')}</td>
                    <td><Link to={`/port/${port.id}`}><button>edit</button></Link></td>
                </tr>)}
                </tbody>
            </table>
        </main>
    </div>
}

export default PortsPage