import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { authApi } from "../../shared/sendRequest";

const CreateUser = () => {
    const navigate = useNavigate()
    const [user, setUser] = useState({
        name:'',
        email:'',
        password:''
    })

    const createUser = async e => {
        e.preventDefault()
        try {
            await authApi('POST','auth/register',user)
            navigate('/users')
        }catch(er){ console.log(er)} 
    }
    return <div className="page">
        <header>
            <h4>Create User</h4>
            <Link to="/users">{`< back`}</Link>
        </header>
        <main className="scrollable">
            <div className="card">
                <form onSubmit={createUser}>
                    <label>
                        Name:
                        <input value={user.name} onChange={ e => setUser({...user, name: e.target.value})} />
                    </label>
                    <label>
                        Email:
                        <input type="email" value={user.email} onChange={ e => setUser({...user, email: e.target.value})} />
                    </label>
                    <label>
                        Password:
                        <input type="password" value={user.password} onChange={ e => setUser({...user, password: e.target.value})} />
                    </label>
                    <label style={{flexDirection:'row', alignItems: 'center'}}>
                        Developer
                        <input type="checkbox" checked={user.isDev} onChange={ e => setUser( state => ({...state, isDev:!user.isDev}))} />
                    </label>
                    <label style={{flexDirection:'row', alignItems: 'center'}}>
                        Administrator
                        <input type="checkbox" checked={user.isAdmin} onChange={ e => setUser( state => ({...state, isAdmin:!user.isAdmin}))} />
                    </label>
                    <label style={{flexDirection:'row', alignItems: 'center'}}>
                        Coordinator
                        <input type="checkbox" checked={user.isCoordinator} onChange={ e => setUser( state => ({...state, isCoordinator:!user.isCoordinator}))} />
                    </label>
                    <label style={{flexDirection:'row', alignItems: 'center'}}>
                        Translator
                        <input type="checkbox" checked={user.isTranslator} onChange={ e => setUser( state => ({...state, isTranslator:!user.isTranslator}))} />
                    </label>
                    <label style={{flexDirection:'row', alignItems: 'center'}}>
                        Back Office
                        <input type="checkbox" checked={user.isBackOffice} onChange={ e => setUser( state => ({...state, isBackOffice:!user.isBackOffice}))} />
                    </label>
                    <label style={{flexDirection:'row', alignItems: 'center'}}>
                        Analyst
                        <input type="checkbox" checked={user.isAnalyst} onChange={ e => setUser( state => ({...state, isAnalyst:!user.isAnalyst}))} />
                    </label>

                    <button type="submit">Create User</button>
                </form>
            </div>
        </main>
    </div>
}
export default CreateUser;