import { useEffect, useState } from "react"
import Card from "../../../shared/components/UIElements/Card"
import { Button } from "../../../shared/components/UIElements/Dumbs"
import Modal from "../../../shared/components/UIElements/Modal"
import sendRequest from "../../../shared/sendRequest"

const defaultS = {
    didItRainOrSnow:false,
    wetDay:false,
    wetCartons:false,
    sunnyDay:false,
    observations:[]
}
const ObservationsWeather = ({inspectionId,observations,onUpdate}) => {
    
    const [_observations,setObservations ] = useState(observations || {...defaultS})


    const [edito,setEditO ] = useState();
    const [editoI,setEditOI ] = useState();
    const [newObservation,setNewObservation ] = useState('');
    const [showModal,setShowModal ] = useState(false);

    const editObservationC = async () => {
        setShowModal(true)
    }
    const EditObservationHandler = async e => {
        e.preventDefault()
    }

    const addObservation = async () => {
        if(newObservation){
            await _preserveChanges({..._observations,observations:[..._observations.observations,newObservation]})
            setNewObservation('')
        }
    }
    const editObservation = (i) => {
        setEditOI(i)
        setEditO(_observations.observations[i])
    }
    const deleteObservation =  async () => {
        if(!window.confirm('confirm?')) return false
        let observations = [..._observations.observations];
        observations.splice(editoI, 1);
        await _preserveChanges({..._observations,observations})
        setEditOI(null)
        setEditO(null)
    }
    const updateObservation =  async () => {
        let observations = [..._observations.observations];
        observations[editoI] = edito;
        await _preserveChanges({..._observations,observations})
        setEditOI(null)
        setEditO(null)
    }
    const updateDidItRain = async () => {
        await _preserveChanges({..._observations,didItRainOrSnow:!_observations.didItRainOrSnow})
    }
    const updateWetCartons = async () => {
        await _preserveChanges({..._observations,wetCartons:!_observations.wetCartons})
    }
    const updateWetDay = async () => {
        await _preserveChanges({..._observations,wetDay:!_observations.wetDay})
    }
    const updateSunnyDay = async () => {
        await _preserveChanges({..._observations,sunnyDay:!_observations.sunnyDay})
    }
    const _preserveChanges = async (weather) => {
        await sendRequest('PUT',`translator/inspections/${inspectionId}/observations`,{ weather})
        onUpdate()
    }
    useEffect( () => {
       setObservations({...defaultS,...observations})    
    },[observations])
    return <>
    <Card>
            

        <h4 className=" mt0">Weather observations</h4>

        <label style={{flexDirection:'row', alignItems:'center'}}>Did it Rain or Snow? <b className="ml10"> {_observations.didItRainOrSnow ? 'yes': 'no'}</b></label> 
        <label style={{flexDirection:'row', alignItems:'center'}}>Where there Wet Cartons? <b className="ml10"> {_observations.wetCartons ? 'yes': 'no'}</b></label> 
        <label style={{flexDirection:'row', alignItems:'center'}}>It was a Sunny Day? <b className="ml10"> {_observations.sunnyDay ? 'yes': 'no'}</b></label> 
        <label style={{flexDirection:'row', alignItems:'center'}}>Was it a Wet Day? <b className="ml10"> {_observations.wetDay ? 'yes': 'no'}</b></label> 
        {_observations && <div>
            <ol>
                {_observations.observations?.map( (o,i) => <li key={i}>{o}</li>)}
            </ol>
        </div>}
        <Button onClick={() => editObservationC()}>edit</Button>


    </Card>
        {showModal && <Modal onSubmit={EditObservationHandler} hideModal={() => setShowModal(false)}>
            <h3>Weather observations</h3>
            <label style={{flexDirection:'row', alignItems:'center'}}>
               Did it Rain or Snow?
               <input type="checkbox" checked={_observations.didItRainOrSnow} onChange={updateDidItRain}  />
           </label>
            <label style={{flexDirection:'row', alignItems:'center'}}>
                Where there Wet Cartons?
               <input type="checkbox" checked={_observations.wetCartons} onChange={updateWetCartons}  />
           </label>
            <label style={{flexDirection:'row', alignItems:'center'}}>
            It was a Sunny Day?
               <input type="checkbox" checked={_observations.sunnyDay} onChange={updateSunnyDay}  />
           </label>
            <label style={{flexDirection:'row', alignItems:'center'}}>
            Was it a Wet Day?
               <input type="checkbox" checked={_observations.wetDay} onChange={updateWetDay}  />
           </label>


           <ol>
                {_observations.observations?.map( (o,i) => <li key={i}>{o} <Button onClick={ () => editObservation(i)} className="link">edit</Button></li>)}
            </ol>

           {edito ?
           <>
           <textarea style={{width:"100%"}} rows={3} value={edito} onChange={ e => setEditO(e.target.value)} placeholder="edit observation"/>
           <Button onClick={updateObservation} type="button">Update</Button><Button onClick={deleteObservation} type="button">Delete</Button>
           </>
           :
           <>
           <textarea style={{width:"100%"}} rows={3} value={newObservation} onChange={ e => setNewObservation(e.target.value)} placeholder="add a weather observation"/>
           <Button onClick={addObservation} type="button">Add</Button>
           </>
            }
        </Modal>}
    </>
}

export default ObservationsWeather