import moment from "moment"
import { useCallback, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Paginator from "../../../shared/components/UIElements/Paginator"
import sendRequest from "../../../shared/sendRequest"

const DailyProgrammings = () => {

    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(25)
    const [pagination, setPagination] = useState({
        page:0,
        perPage:0,
        pages:0,
        count:0
    })

    const [programmings, setProgrammings] = useState([])

    const getProgrammings = useCallback( async() => {
        const {programmings,pagination} = await sendRequest('GET',`coordinator/daily-programmings?perPage=${perPage}&page=${page}`);
        setPagination(pagination)
        setProgrammings(programmings)
    },[perPage,page]);

    useEffect( () => {
        getProgrammings()
    },[getProgrammings])

    return <div className="page">
        <header>
            <div  style={{flex:1}}>
                <div style={{ display:"flex", alignItems: 'center', justifyContent:'space-between'}}>
                    <h3>Daily Programmings</h3>
                </div>
            
                <Paginator pagination={pagination} onChangePage={setPage} onChangePerPage={setPerPage} />
            </div>
    
        </header>
        <main className="scrollable">

<table className="card">
    <thead>
        <tr>
            <th></th>
            <th>No. Programmings</th>
            <th>Daily Programming Date</th>
            <th>Date Offset</th>
            <th>Sended</th>

            <th></th>
        </tr>
    </thead>
    <tbody>
    {programmings.map( (po, index) => <tr key={po.id}>
        <td className={`center ${po.sendedDate ? 'green' : ''}`}>{(perPage*(page-1)) + index + 1}</td>
        <td className="center">{po.programmings?.length}</td>
        <td>{po.dailyProgrammingDate && moment(po.dailyProgrammingDate).format('DD/MMM/Y Z')}</td>
        <td className="center">{po.utcOffset && `${(po.utcOffset / 60)}:00`}</td>
        <td className={`center ${po.sendedDate ? 'green' : ''}`}>{po.sendedDate && moment(po.sendedDate).format('DD/MMM/Y Z')}</td>
 
        <td><Link to={`/coordinator/daily-programming/${po.id}`}><button>edit</button></Link></td>
    </tr>)}
    </tbody>
</table>
</main>
    </div>
}

export default DailyProgrammings