import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import sendRequest from "../../shared/sendRequest";
import InspectionForm from "../components/InspectionForm";
import LoadingInfo from "../components/LoadingInfo";
import { InspectionContextProvider } from "../context/InspectionContext";

const Inspection = () => {
    const {reference} = useParams()
    const [inspection, setInspection] = useState({});

    const getInspection = useCallback( async () => {
        try{
            const response = await sendRequest('GET','api/inspections/' + reference);
            setInspection(response);
        }catch(er){
            console.log(er)
        }
    },[reference])

    useEffect( () => {
        getInspection()
    },[getInspection])

    return <InspectionContextProvider value={
        {
            inspection, setInspection
        }
        
    }>
        <div className="page">
            <header>
                <h3>Inspection:  {reference}</h3>
                <Link to="/inspections"><button className="link">back</button></Link>
            </header>
            <main className="scrollable p20">
                <InspectionForm insp={inspection} />
                <LoadingInfo />
                <pre>{JSON.stringify(inspection,null,2)}</pre>
            </main>
        </div>
    </InspectionContextProvider>
}

export default Inspection;

<div className="page">
    
  </div>