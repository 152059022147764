import { useCallback, useState, useEffect } from "react"
import Modal from "../../../shared/components/UIElements/Modal";
import sendRequest from "../../../shared/sendRequest";

const SelectLocations = ({locations, onSelect, onRemove}) => {

    /*
        - Filter Locations by Country
        - Remove filter
    */
    const [locations_cat, setLocationsCat] = useState([])

    const getLocationsCat = useCallback(async() => {
        const response = await sendRequest('GET','locations');
        setLocationsCat(response.locations)
    },[]);

    useEffect( () => {
        getLocationsCat()
    },[getLocationsCat])

    const [showModal,setShowModal ] = useState(false);

    const addLocation = locationId => {
        onSelect(locationId)
    }
    const removeLocation = locationId => {
        onRemove(locationId)
    }

    return <div>
        <button onClick={() => setShowModal(true)}>Select Locations</button>
        {showModal && <Modal onSubmit={ e => e.preventDefault()} hideModal={() => setShowModal(false)}>
            <div>
                Assigned Locations
                <ul>
                    {locations?.map( location => <li key={location} >{locations_cat.find( i => i.id === location)?.name}, {locations_cat.find( i => i.id === location)?.country?.name} <button onClick={ () => removeLocation(location)}>remove</button></li>)}
                </ul>
                <hr/>
                Available Locations
                <ul>
                    {locations_cat?.filter( i => !locations.includes(i.id)).map( location => <li key={location.id} ><button onClick={ () => addLocation(location.id)}>{location.name}, {location.country?.name}</button></li>)}
                </ul>
                
            </div>
        </Modal>}
    </div>
}

export default SelectLocations