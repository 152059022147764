import { useState, useEffect } from "react";
import Modal from "../../../shared/components/UIElements/Modal";

const EditMessage = ({message, onMessageSet, className}) => {

    const [showModal,setShowModal ] = useState(false);
    const [new_message,setMessage ] = useState(message || '');

    const CountSetHandler = e => {
        e.preventDefault()
        onMessageSet(new_message)
        setShowModal(false)
    }
    useEffect( () => {
        setMessage(message)
    },[message]) 
    return <div className={` ${className}`}>
        <div>
            <button onClick={() => setShowModal(true)}>Add / Edit Message</button>
        </div>
        {showModal && <Modal onSubmit={CountSetHandler} hideModal={() => setShowModal(false)}>
            <textarea style={{width:'100%', resize:'vertical'}} rows={4} value={new_message} onChange={ e => setMessage(e.target.value)} />
            <button type="submit">Set</button>
        </Modal>}
    </div>
}

export default EditMessage