
import { useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import CountrySelector from "../../shared/components/CountrySelector";
import sendRequest from "../../shared/sendRequest";

const CreateLocationPage = () => {
    const navigate = useNavigate();

    const [location, setLocation] = useState({
        name:'',
        country:'',
        city:'',
        address:'',
        notes:''
    })

    const createLocation = async e =>{
        e.preventDefault()
        await sendRequest('POST','locations',location)
        navigate('/locations')
    }

    return <div className="page">
        <header>
            <h3>Create Location</h3>
            <Link to="/locations"><button className="link">{'<'} back</button></Link>
        </header>
        <main className="scrollable">
            <form onSubmit={createLocation} className="card">
                <label>
                    Name
                    <input placeholder="Name" value={location.name} onChange={ e => setLocation( state => ({...state, name:e.target.value}))} />
                </label>
    
                <label>
                    Country
                    <CountrySelector inspectables value={location.country} onChange={ country => setLocation( state => ({...state,country}) )} />
                </label>
                <label>
                    City (tmp)
                    <input placeholder="City" value={location.city} onChange={ e => setLocation( state => ({...state, city:e.target.value}))} />
                </label>

                <label>
                    Address
                    <textarea placeholder="Address" value={location.address} onChange={ e => setLocation( state => ({...state, address:e.target.value}))} />
                </label>
                <label>
                    Notes
                    <textarea placeholder="Notes" value={location.notes} onChange={ e => setLocation( state => ({...state, notes:e.target.value}))} />
                </label>
                
                
                <button type="submit">Create Location</button>
            </form>


        </main>
    </div>
}

export default CreateLocationPage;