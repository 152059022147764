import { useContext, useEffect, useState } from "react";
import sendRequest from "../../shared/sendRequest";
import InspectionContext from "../context/InspectionContext";
import './LoadingInfo.scss'
import LoadingOrder from "./LoadingOrder";
import PhotoCanvas from "./PhotoCanvas";

const Cel = ({value='',onChange}) => <td>
    <input value={value} onChange={ e => onChange(e.target.value)} />
</td> 

const Model = ({model, onChange}) => {
    const change = (key,value) => onChange({...model,[key]:value})
    
    return <tr key={model.id}>
        <td style={{minWidth:"200px"}}><textarea style={{resize: "vertical"}} rows="1" value={model.proveedor} onChange={ e => onChange({...model, proveedor:e.target.value})} /></td> 
        <Cel value={model.po} _key="po" onChange={ value => change('po',value)} />
        <Cel value={model.modelo} _key="modelo" onChange={ value => change('modelo',value)} />
        <Cel value={model.contenedor} _key="contenedor" onChange={ value => change('contenedor',value)} />
        <td style={{minWidth:"200px"}}><textarea rows="1" value={model.descripcion} onChange={ e => onChange({...model, descripcion:e.target.value})} /></td> 
        <Cel value={model.ctns} _key="ctns" onChange={ value => change('ctns',value)} />
        <td><input value={model.pcs_ctn} onChange={ e => onChange({...model, pcs_ctn:e.target.value})} /></td> 
        <td><input value={model.total_pcs} onChange={ e => onChange({...model, total_pcs:e.target.value})} /></td> 
        <td><input value={model.upc} onChange={ e => onChange({...model, upc:e.target.value})} /></td> 
        <td><input value={model.material} onChange={ e => onChange({...model, material:e.target.value})} /></td> 
        <td><input value={model.marca} onChange={ e => onChange({...model, marca:e.target.value})} /></td> 
        <td><input value={model.letra} onChange={ e => onChange({...model, letra:e.target.value})} /></td> 
    </tr>
}
const LoadingInfo = () => {

    const {inspection, setInspection} = useContext(InspectionContext)

    const [models, setModels] = useState(inspection.models);
    const [containers, setContainers] = useState([]);

    const saveModels = async () => {
        try {
            const response = await sendRequest('PUT','api/models/many',models)
            setInspection(response);
        }catch(er){ console.log(er)} 
        
    }
    const addModel = async () => {
        const payload = {
            inspection: inspection.id
        }
        try {
            const {model} = await sendRequest('POST','api/models',payload)
            //setModels(state => [...state,response.model])
            setModels(models.concat(model))
        }catch(er){ console.log(er)} 
    }

    const updateModel = (model) => {
        let copy = [...models];
        for(let i=0; i < copy.length; i++){
            if(copy[i].id === model.id){
                //console.log(copy[i]);
                copy[i] = model;
                break;
            }
        }
        setModels(copy)
    }

    useEffect( () => {
        if(inspection && inspection.models){
            console.log(inspection.models)
            setModels(inspection.models)
            setContainers(inspection.containers)
        }
    },[inspection])
    return <>
        <h3>Información de Carga</h3>
        <div className="table-responsive">
        <table className="table condensed">
            <thead>
                <tr>
                    <th> Proveedor</th>
                    <th> PO </th>
                    <th> Modelo </th>
                    <th> Contenedor </th>
                    <th> Descripción</th>
                    <th> Ctns.</th>
                    <th> Pcs/Ctn. </th>
                    <th> Total Pcs. </th>
                    <th> UPC </th>
                    <th> Material  </th>
                    <th> Marca </th>
                    <th> Letra </th>
                </tr>
            </thead>
            <tbody>
                { models && models.map( m => <Model key={m.id} model={m} onChange={updateModel} />)}
            </tbody>
        </table>
        </div>

            <button onClick={addModel}>Agregar modelo</button>
            <button onClick={saveModels}>Guardar</button>

            <LoadingOrder containers={containers} />
     
            {false && <pre>{JSON.stringify(models,null,2)}</pre>}
            {true && <PhotoCanvas structure={containers} reference={inspection.reference} images={inspection.images} />}

    </>
}

export default LoadingInfo;