import { useCallback, useEffect, useState } from "react"
import sendRequest from "../sendRequest";

const CustomBrokerSelector = ({ value, onChange}) =>{
    
    const [customBrokers, setCustomBroker] = useState([])

    const getCustomBroker = useCallback( async () => {
        try{
            const {customBrokers} = await sendRequest('GET',`custom-brokers?perPage=999999`);
            setCustomBroker(customBrokers)
        }catch(er){ console.log(er) }

    },[])
    useEffect( () => {
        getCustomBroker()
    },[getCustomBroker])
    
    return <select value={value} onChange={ e => onChange( e.target.value)}>
        <option value="">Choose a Custom Broker</option>
        {customBrokers.map( cb => <option key={cb.id} value={cb.id}>{cb.name}</option>)}
    </select>
}

export default CustomBrokerSelector