import moment from "moment"
import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import sendRequest from "../../../shared/sendRequest"
import SearchPOs from "../components/SearchPOs"

const CreateProgramming = () => {
    const navigate = useNavigate();
    
    const emptyProg = {
        _country:'',
        _company:'',
        _port:'',
        _provider:'',
        pos:[]
    }
    let emptyDisplay = {
        _country:'',
        _company:'',
        _port:'',
        _provider:'',
        max_early_ship_date:'',
        min_late_ship_date:'',
        pos:[],
    }
    const [programming, setProgramming] = useState({...emptyProg})
    const [display, setDisplay] = useState({...emptyDisplay})

    const createProgramming = async () => {
        const response = await sendRequest('POST','coordinator/programmings',programming)
        navigate('/coordinator/programming/'+response.programming.id)
    }
    const removePo = async (po) => {
        if(programming.pos.length === 1){
            setProgramming(emptyProg)
            setDisplay(emptyDisplay)
            return false;
        }

        let newPOS = [];
        let newPOSIds = [];
        var maxEarly = null;
        var minLate = null;
        for await (let dpo of display.pos){
            if(po.id !== dpo.id){
                newPOS.push(dpo)
                newPOSIds.push(dpo.id)
                maxEarly = maxEarly ? (maxEarly > dpo.early_ship_date ? maxEarly : dpo.early_ship_date) : dpo.early_ship_date
                minLate = minLate ? (minLate < dpo.late_ship_date ? minLate : dpo.late_ship_date) : dpo.late_ship_date
            }
        }
        
        setProgramming(state => ({...state,
            pos:newPOSIds
        }))
        setDisplay(state => ({...state,
            pos:newPOS,
            max_early_ship_date:maxEarly,
            min_late_ship_date:minLate
        }))

    }
    const addPO = po => {
        if(!programming.pos.length){
            setProgramming(state => ({...state,
                _country:po._country?.id,
                _company:po._company?.id,
                _port:po._port?.id,
                _provider:po._provider?.id,
                pos:[...state.pos,po.id],
            }) )
            setDisplay(state => ({...state,
                _country:po._country?.name,
                _company:po._company?.name,
                _port:po._port?.name,
                _provider:po._provider?.name,
                max_early_ship_date:po.early_ship_date,
                min_late_ship_date:po.late_ship_date,
                pos:[...state.pos,po]
            }) )
            return false
        }
        if(programming.pos.includes(po.id)) return false;
        setProgramming(state => ({...state,
            pos:[...state.pos,po.id],
        }) )
        setDisplay(state => ({...state,
            max_early_ship_date: state.max_early_ship_date > po.early_ship_date ? state.max_early_ship_date : po.early_ship_date,
            min_late_ship_date: state.min_late_ship_date < po.late_ship_date ? state.min_late_ship_date : po.late_ship_date,
            pos:[...state.pos,po]
        }) )
    }
    return <div className="page">
        <header>
            <h3>Create CY Programming</h3>
            <Link to={"/coordinator/programmings"}>{'<'} back</Link>
        </header>
        <main className="scrollable">
            <div className="card">
                
                {display._country && <div>Country: {display._country}</div>}
                {display._company && <div>Company: {display._company}</div>}
                {display._port && <div>Port: {display._port}</div>}
                {display._provider && <div>Provider: {display._provider}</div>}
                {!!display.pos.length && <div className="mb20 mt10">POS: {display.pos.map( po => <li key={po.id}>{po.po_nbr} <button onClick={ e => removePo(po)}>remove</button></li>)}</div>}

                {(display["max_early_ship_date"] && moment(display["min_late_ship_date"]) ) && <div>
                    Shipping window: {moment(display["max_early_ship_date"]).format('DD/MMM/Y')} to {' '}
                    {moment(display["min_late_ship_date"]).format('DD/MMM/Y')}
                    <small>({moment(display["min_late_ship_date"]).diff(moment(display["max_early_ship_date"]),'days')} days)</small>
                </div>}

                {!!display.pos.length && <button className="mt20" onClick={createProgramming}>Create Programming</button>}
            </div>
            
            <SearchPOs onPOAdd={addPO} />
            
        </main>
    </div>
}

export default CreateProgramming