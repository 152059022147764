import { useState } from "react";
import Modal from "../../shared/components/UIElements/Modal";

const MultiLocations = ({location, locations, onChange}) => {
    const [locations_cat] = useState([
        {
            id:'LOC01',
            short_name:'Ningbo, Zhejiang',
        },
        {
            id:'LOC02',
            short_name:'Ningbo, Twoo',
        },
    ])

    const [showModal,setShowModal ] = useState(false);

    const addLocation = (location) => {
        const locations_array = [...locations];
        for( let i = 0; i < locations_array.length; i++){ 
            if ( locations_array[i] === location) { 
                return;
            }
        }
        locations_array.push(location)
        onChange({locations:locations_array, location:constructName(locations_array)})
    }

    const constructName = (arr) => {
        let name = ""
        for( let i = 0; i < arr.length; i++){ 
            name += arr[i].short_name;
            if(i < arr.length -1 ) name += '; '
        }
        return name;
    }

    const removelocation = (location) => {
        const locations_array = [...locations]
        for( let i = 0; i < locations_array.length; i++){ 
            if ( locations_array[i] === location) { 
                locations_array.splice(i, 1); 
                break;
            }
        }
        onChange({locations:locations_array, location:constructName(locations_array)})
    }
    
    return <div>
        <button onClick={() => setShowModal(true)}>{location || "<Ingresar lugar de inspección>"} </button>
        {showModal && <Modal onSubmit={ e => e.preventDefault()} hideModal={() => setShowModal(false)}>
            <div>
                <ul>
                    {locations_cat && locations_cat.map( location => <li key={location.id} ><button onClick={ () => addLocation(location)}>{location.short_name}</button></li>)}
                </ul>
                <ul>
                    {locations && locations.map( location => <li key={location.id} >{location.short_name} <button onClick={ () => removelocation(location)}>remove</button></li>)}
                </ul>
            </div>
        </Modal>}
        
     

    </div>
}

export default MultiLocations;