import sendRequest from "../../../shared/sendRequest";

const DeleteProgramming = ({programmingId, onDelete}) => {

    const deleteProgramming = async () => {
        if(!window.confirm('confirm')) return false;
        await sendRequest('DELETE',`coordinator/programmings/${programmingId}`)
        onDelete()
    }

    return <div className="card" style={{border: 'red 2px solid'}}>
        <b>DANGER ZONE</b><br/>
        If you delete the programming, it cannot be restored and all resources (pos) will be liberated.
        <button className="mt20" style={{border: 'red 2px solid', background:'#e83858', color:'white'}} onClick={deleteProgramming}>Delete Programming</button>
    </div>
}

export default DeleteProgramming