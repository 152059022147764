import moment from "moment"
import { useCallback, useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import Paginator from "../../../shared/components/UIElements/Paginator"
import sendRequest from "../../../shared/sendRequest"

const Programmings = () => {
    const navigate = useNavigate()
    //const [filters, setFilters] = useState({
    const [filters] = useState({
        signed: true
    })
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(25)
    const [pagination, setPagination] = useState({
        page:0,
        perPage:0,
        pages:0,
        count:0
    })

    const [programmings, setProgrammings] = useState([])

    const getProgrammings = useCallback( async() => {
        const {programmings,pagination} = await sendRequest('GET',`translator/programmings?perPage=${perPage}&page=${page}&signed=${filters.signed}`);
        setPagination(pagination)
        setProgrammings(programmings)
    },[perPage,page,filters]);

    useEffect( () => {
        getProgrammings()
    },[getProgrammings])

    const createInspection = async (programmingId) => {
        const {inspection} = await sendRequest('GET',`translator/programmings/${programmingId}/create-inspection`);
        navigate('/translator/inspection/'+inspection.id)
    }

    return <div className="page">
        <header>
            <div  style={{flex:1}}>
                <div style={{ display:"flex", alignItems: 'center', justifyContent:'space-between'}}>
                    <h3>Programmings</h3>
                </div>
            
                <Paginator pagination={pagination} onChangePage={setPage} onChangePerPage={setPerPage} />
            </div>
    
        </header>
        <main className="scrollable">

<table className="card">
    <thead>
        <tr>
            <th></th>
            <th>PO nbrs</th>
            <th>Country</th>
            <th>Company</th>
            <th>Port</th>
            <th>Provider</th>
            <th>Loading Date</th>
            <th>Inspectors</th>
            <th>Programming Signed</th>
            <th>Coordinator</th>
            <th>Reference</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
    {programmings.map( (po, index) => <tr key={po.id} >
        <td className={`center ${po.parent ? 'yellow' : ''}`}>{(perPage*(page-1)) + index + 1}</td>
        <td>{po.pos?.map( po => <div key={po.id}>{po.po_nbr}</div>)}</td>
        <td>{po._country?.name}</td>
        <td>{po._company?.name}</td>
        <td>{po._port?.name}</td>
        <td>{po._provider?.name}</td>
        <td>{po.loadingDate && moment(po.loadingDate).format('DD/MMM/Y')}</td>
        <td className={`${po.changes.includes('Inspectors') ? 'yellow' : ''}`}>{po.inspectors?.map( i => <div key={i.id}>{i.name}</div>)}</td>
        <td>{po.signedDate && moment(po.signedDate).format('DD/MMM/Y HH:mm')}</td>
        <td>{po._coordinator?.name}</td>
        <td>{po.reference}</td>
        <td>{po.inspection ?
            <Link to={`/translator/inspection/${po.inspection}`}><button>Vew Inspection</button></Link>
            :
            <button onClick={ () => createInspection(po.id)}>Create Inspection</button>
        }</td>
    </tr>)}
    </tbody>
</table>
</main>
    </div>
}

export default Programmings