
import { useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import CountrySelector from "../../shared/components/CountrySelector";
import sendRequest from "../../shared/sendRequest";
import CoordinatorSelector from "../../shared/components/CoordinatorSelector";

const CreateProviderPage = () => {

    const navigate = useNavigate();

    const [provider, setProvider] = useState({
        name:'',
        country:'',
        //user:'',
        //active:'',
        note:''
    })

    const createProvider = async e =>{
        e.preventDefault()
        await sendRequest('POST','providers',provider)
        navigate('/providers')
    }

    return <div className="page">
        <header>
            <h3>Create provider</h3>
            <Link to="/providers"><button className="link">{'<'} back</button></Link>
        </header>
        <main className="scrollable">
            <form onSubmit={createProvider} className="card">
                <label>
                    Name
                    <input placeholder="Name" value={provider.name} onChange={ e => setProvider( state => ({...state, name:e.target.value}))} />
                </label>
                <label>
                    Country
                    <CountrySelector inspectables value={provider.country} onChange={ country => setProvider( state => ({...state,country}) )} />
                </label>
                <label>
                    Coordinator
                    <CoordinatorSelector coordinatorId={provider.coordinator} onChange={ coordinator => setProvider( state => ({...state,coordinator}) ) } />
                </label>


                <label>
                    Note
                    <textarea placeholder="Note" value={provider.note} onChange={ e => setProvider( state => ({...state, note:e.target.value}))} />
                </label>
                
                
                <button type="submit">Create Provider</button>
            </form>




        </main>
    </div>
}

export default CreateProviderPage;