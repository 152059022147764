import { useCallback, useState, useEffect } from "react";
import Modal from "../../shared/components/UIElements/Modal";
import sendRequest from "../sendRequest";

const InspectorsSelector = ({inspector, inspectors, onChange}) => {
    const [inspectors_cat, setInspectorsCat] = useState([])
    
    const getInspectorsCat = useCallback(async() => {
        const response = await sendRequest('GET','inspectors');
        setInspectorsCat(response.inspectors)
    },[]);

    useEffect( () => {
        getInspectorsCat()
    },[getInspectorsCat])

    const [showModal,setShowModal ] = useState(false);

    const addInspector = (inspector) => {
        const inspectors_array = [...inspectors];
        for( let i = 0; i < inspectors_array.length; i++){ 
            if ( inspectors_array[i] === inspector) { 
                return;
            }
        }
        inspectors_array.push(inspector)
        onChange({inspectors:inspectors_array, inspector:inspectors_array[0]})
    }

    const removeinspector = (inspector) => {
        const inspectors_array = [...inspectors]
        for( let i = 0; i < inspectors_array.length; i++){ 
            if ( inspectors_array[i] === inspector) { 
                inspectors_array.splice(i, 1); 
                break;
            }
        }
        onChange({inspectors:inspectors_array, inspector:inspectors_array[0]})
    }
    
    return <div>
        <button onClick={() => setShowModal(true)}>{inspectors?.length 
            ? inspectors_cat.filter( i => inspectors.includes(i.id)).map( i => i.name).join(', ')
            :  "<Ingresar inspector>"} {inspectors?.length > 1 && `(${inspectors.length})`}</button>
        {showModal && <Modal onSubmit={ e => e.preventDefault()} hideModal={() => setShowModal(false)}>
            <div>
                Assigned Inspectors
                <ul>
                    {inspectors?.map( inspector => <li key={inspector} >{inspectors_cat.find( i => i.id === inspector)?.name}, {inspectors_cat.find( i => i.id === inspector)?.country?.name} <button onClick={ () => removeinspector(inspector)}>remove</button></li>)}
                </ul>
                <hr/>
                Available Inspectors
                <ul>
                    {inspectors_cat?.filter( i => !inspectors.includes(i.id)).map( inspector => <li key={inspector.id} ><button onClick={ () => addInspector(inspector.id)}>{inspector.name}, {inspector.country?.name}</button></li>)}
                </ul>
                
            </div>
        </Modal>}
        
    </div>
}

export default InspectorsSelector;