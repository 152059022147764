import { Link, useNavigate, useParams } from "react-router-dom"
import { useCallback, useEffect, useState } from "react"
import sendRequest from "../../../shared/sendRequest"
import SearchPOs from "../components/SearchPOs"
import moment from "moment"
import SetBookingFormSubmitionDt from "../components/SetBookingFormSubmitionDt"
import DeleteProgramming from "../components/DeleteProgramming"
import SetBookingFormReceptionDt from "../components/SetBookingFormReceptionDt"
import SelectInspectors from "../components/SelectInspectors"
import SelectLocations from "../components/SelectLocations"
import ManageLoadingDates from "../components/ManageLoadingDates"
import SetContainersCount from "../components/SetContainersCount"
import ManageObservations from "../components/ManageObservations"
import OpenSignedProgramming from "../components/OpenSignedProgramming"
import UploadBooking from "../components/UploadBooking"
import DownloadButton from "../../../shared/components/DownloadButton"
import CreatePOs from "../components/CreatePOs"

const ProgrammingPage = () => {
    const navigate = useNavigate();

    const {id:programmingId } = useParams()
    const [programming,setProgramming] = useState()
    const [pos,setPos] = useState([])
    const [showSearchPOs,setShowSearchPOs] = useState(false)
    const [showCreatePOs,setShowCreatePOs] = useState(false)

    const showSearchPOsHandler = async () => {
        setShowSearchPOs(!showSearchPOs)
        setShowCreatePOs(false)
    }
    const showCreatePOsHandler = async () => {
        setShowSearchPOs(false)
        setShowCreatePOs(!showCreatePOs)
    }

    const getProgramming = useCallback( async () => {
        const { programming, pos } = await sendRequest('GET',`coordinator/programmings/${programmingId}`)
        setProgramming(programming)
        setPos(pos)
    },[programmingId])

    useEffect( () => {
        getProgramming()
    },[getProgramming])


    const removePO = async id => {
        if(!window.confirm('confirm')) return false;
        await sendRequest('DELETE',`coordinator/programmings/${programmingId}/remove-po/${id}`)
        getProgramming()
    }

    const addPO = async po => {
        await sendRequest('PATCH',`coordinator/programmings/${programmingId}/add-po/${po.id}`)
        getProgramming()
    }

    const addInspector = async inspectorId => {
        await sendRequest('PATCH',`coordinator/programmings/${programmingId}/add-inspector/${inspectorId}`)
        getProgramming()
    }
    const removeInspector = async inspectorId => {
        await sendRequest('DELETE',`coordinator/programmings/${programmingId}/remove-inspector/${inspectorId}`)
        getProgramming()
    }

    const addLocation = async locationId => {
        await sendRequest('PATCH',`coordinator/programmings/${programmingId}/add-location/${locationId}`)
        getProgramming()
    }
    const removeLocation = async locationId => {
        await sendRequest('DELETE',`coordinator/programmings/${programmingId}/remove-location/${locationId}`)
        getProgramming()
    }

    const updateLoadingDates = async dates => {
        dates.date = dates.date ? moment(dates.date) : undefined
        dates.dates = dates.dates.map( dt => moment(dt))
        await sendRequest('PATCH',`coordinator/programmings/${programmingId}/loading-dates`,dates)
        getProgramming()
    }
    const setContainersCount = async count => {
        await sendRequest('PATCH',`coordinator/programmings/${programmingId}/containers-count`,{count})
        getProgramming()
    }
    const setObservations = async observation => {
        await sendRequest('PATCH',`coordinator/programmings/${programmingId}/observations`,{observation})
        getProgramming()
    }

    const signProgramming = async () => {
        if(!window.confirm('Proceed with signature')) return false;
        console.log(pos)
        if(pos.find( po => !po._models.length)){
            if(!window.confirm('There are PO(s) without any model!\nDo you want to proceed?')) return false;
        }
        
        //TODO: ADD VAlidations (loadingdate, inpectors, locations, containers_count, pos)
        await sendRequest('PATCH',`coordinator/programmings/${programmingId}/sign`,{now:moment()})
        getProgramming()
    }

    const removeBookingFile = async () => {
        if(!window.confirm('confirm?')) return false
        await sendRequest('DELETE',`coordinator/programmings/${programmingId}/booking/delete`)
        getProgramming()
    }
    return <div className="page">
        <header>
            <h3>Edit Programming</h3>
            <Link to="/coordinator/programmings"><button className="link">{'<'} back</button></Link>
        </header>
        <main className="scrollable">
            <div className="card" style={{display:'flex'}}>
                <div className="mr20" style={{flex:1}}>
                    {programming?.parent && <div><small>Parent:</small> <small>{programming?.parent}</small></div>}
                    <div>Coordinator: <b>{programming?._coordinator?.name}</b></div>
                    <div>Company: <b>{programming?._company?.name}</b></div>
                    <div>Country: <b>{programming?._country?.name}</b></div>
                    <div>Provider: <b>{programming?._provider?.name}</b></div>
                    <div>Port: <b>{programming?._port?.name}</b></div>
                </div>
                <div>
                    { programming?.bookingSendedDate ?
                        <div>Booking form submission date: <b>{moment(programming.bookingSendedDate).format('DD/MMM/Y')}</b></div>
                        :
                        <SetBookingFormSubmitionDt programmingId={programming?.id} onSet={getProgramming} />
                    }

                    {programming?.bookingName ?
                    <div>
                        <DownloadButton label={`Download Report (${programming?.bookingName})`} url={`coordinator/programmings/${programming?.id}/booking/download`} filename={programming?.bookingName} />
                        <button onClick={removeBookingFile}>Remove</button>
                    </div>
                    :
                    <UploadBooking programming={programming?.id} onComplete={getProgramming} />
                    }
                    

                    { programming?.bookingSendedDate && (
                            programming?.bookingReceivedDate ?
                            <div>Booking form reception date: <b>{moment(programming.bookingReceivedDate).format('DD/MMM/Y')}</b></div>
                            :
                            <SetBookingFormReceptionDt programmingId={programming?.id} onSet={getProgramming} />
                        )
                    }
                    { programming?.signedDate ?
                        <div>Programming Signed at: <b>{moment(programming?.signedDate).format('DD/MMM/Y HH:mm')}</b></div>
                        :
                        <button onClick={signProgramming}>Sign Programming</button>
                    }
                </div>
                
            </div>
            <div className="card">
                <div className="mb20">Loading Dates: 
                    {!programming?.signedDate && <div style={{float:'right'}}><ManageLoadingDates date={programming?.loadingDate} dates={programming?.dates || []} onChange={updateLoadingDates} /></div>}
                    {programming?.dates?.map( dt => <span className={`badge ${dt === programming.loadingDate ? 'primary' : ''}`} key={dt}>{moment(dt).format("DD/MMM/Y")}</span>)}
                </div>
                <hr/>
                <div className="mb20" >{programming?.changes.includes('Inspectors') && <div className="yellow dot"></div>} Inspectors: 
                    {!programming?.signedDate && <div style={{float:'right'}}><SelectInspectors inspectors={programming?.inspectors.map( i => i.id)} onSelect={ addInspector } onRemove={ removeInspector } /></div>}
                    {programming?.inspectors?.map( i => <span className="badge" key={i.id}>{i.name}</span>)} 
                </div>
                <hr/>
                <div className="mb20">{programming?.changes.includes('Locations') && <div className="yellow dot"></div>} Locations: 
                    {!programming?.signedDate && <div style={{float:'right'}}><SelectLocations locations={programming?.locations.map( l => l.id)} onSelect={ addLocation } onRemove={ removeLocation } /></div>}
                    {programming?.locations?.map( i => <span className="badge" key={i.id}>{i.name}</span>)} 
                </div>
                <hr/>
                <div className="pb10">
                    Containers Count: <span className="badge primary">{programming?.noContainers || "-"}</span> <div style={{float:'right'}}>
                    {!programming?.signedDate && <SetContainersCount  count={programming?.noContainers} onCountSet={ setContainersCount } />}
                </div>
                </div>
                <hr/>
                <div>
                    Observations:  <div style={{float:'right'}}>{!programming?.signedDate && <ManageObservations observations={programming?.notes} onObservationSet={ setObservations } />}</div>
                    {programming?.notes && <div className="badge mt20 mr0 multiLine">{programming?.notes}</div>}
                </div>
            </div>

            <table className="card">
                <thead>
                    <tr>
                        <th>PO nbr</th>
                        <th>Early Ship Date</th>
                        <th>Late Ship Date</th>
                        <th>Country</th>
                        <th>Company</th>
                        <th>Port</th>
                        <th>Provider</th>
                        <th>No. Models</th>
                        {(pos.length > 1 && !programming?.signedDate) && <th></th> }
                    </tr>
                </thead>
                <tbody>
                    {pos.map( po => <tr key={po.id}>
                        <td>{po.po_nbr}</td>
                        <td>{po["early_ship_date"] && moment(po["early_ship_date"]).format('DD/MMM/Y')}</td>
                        <td>{po["late_ship_date"] && moment(po["late_ship_date"]).format('DD/MMM/Y')}</td>
                        <td>{po._country?.name}</td>
                        <td>{po._company?.name}</td>
                        <td>{po._port?.name}</td>
                        <td>{po._provider?.name}</td>
                        <td className="center">{po._models.length}</td>
                        {(pos.length > 1 && !programming?.signedDate) && <td><button onClick={() => removePO(po.id)}>Remove PO</button></td>}
                    </tr>)}
                </tbody>
     
            </table>
            {!programming?.signedDate && <div className="m20">
                <button onClick={showSearchPOsHandler}>Search POs</button> <button onClick={showCreatePOsHandler}>Create POs</button>
            </div>}
            {showSearchPOs && <SearchPOs defaultFilters={{
                provider:programming?._provider?.id || undefined,
                country:programming?._country?.id || undefined
            }} onPOAdd={addPO} />}
            {showCreatePOs && <CreatePOs port={programming?._port} company={programming?._company} provider={programming?._provider} country={programming?._country} onPOAdd={addPO} />}

            {(!programming?.signedDate && !programming?.parent) && <DeleteProgramming programmingId={programming?.id} onDelete={ () => navigate('/coordinator/programmings')} />}

            {programming?.signedDate && <OpenSignedProgramming programmingId={programming?.id} onOpen={ pId => navigate('/coordinator/programming/'+ pId)} />}
        </main>
    </div>
}

export default ProgrammingPage