
import { useCallback, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import CountrySelector from "../../shared/components/CountrySelector";
import sendRequest from "../../shared/sendRequest";

const Warehouse = () => {
    const {id} = useParams()

    const navigate = useNavigate();

    const [warehouse, setWarehouse] = useState({
        name:'',
        country:'',
        address:'',
        note:''
    })

    const updateWarehouse = async e =>{
        e.preventDefault()
        await sendRequest('PUT','warehouses/' + id,warehouse)
        navigate('/warehouses')
    }

    const deleteWharehouse = async() => {
        if(!window.confirm('Confirm')) return false
        await sendRequest('DELETE','warehouses/' + id);
        navigate('/warehouses')
    }

    const getWarehouse = useCallback(async() => {
        if(!id) return false;
        const {warehouse} = await sendRequest('GET','warehouses/' + id);
        setWarehouse(warehouse)
    },[id])

    useEffect( () => {
        getWarehouse()
    },[getWarehouse])

    if(!warehouse.id) return <div className="page"> Loading...</div>

    return <div className="page">
        <header>
            <h3>Edit Warehouse</h3>
            <Link to="/warehouses"><button className="link">{'<'} back</button></Link>
        </header>
        <main className="scrollable">
            <form onSubmit={updateWarehouse} className="card">
                <label>
                    Name
                    <input placeholder="Name" value={warehouse.name} onChange={ e => setWarehouse( state => ({...state, name:e.target.value}))} />
                </label>
                <label>
                    Country
                    <CountrySelector inspectables value={warehouse.country} onChange={ country => setWarehouse( state => ({...state,country}) )} />
                </label>

                <label>
                    Address
                    <textarea placeholder="Address" value={warehouse.address} onChange={ e => setWarehouse( state => ({...state, address:e.target.value}))} />
                </label>
                
                <label>
                    Note
                    <textarea placeholder="Note" value={warehouse.note} onChange={ e => setWarehouse( state => ({...state, note:e.target.value}))} />
                </label>
                
                
                <button type="submit">Update Warehouse</button>
            </form>

            <div className="card">
                <h3>DANGER ZONE</h3>
                <button onClick={deleteWharehouse}>Remove Warehouse</button>
            </div>


        </main>
    </div>
}

export default Warehouse;