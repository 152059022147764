import { useCallback, useEffect, useState } from "react"
import sendRequest from "../sendRequest";

const CountrySelector = ({inspectables, value, onChange, disabled}) =>{
    
    const [countries, setCountries] = useState([])

    const getCountries = useCallback( async () => {
        try{
            const response = await sendRequest('GET',`countries${inspectables ? '/inspectables' : ''}`);
            setCountries(response.countries)
        }catch(er){ console.log(er) }

    },[inspectables])
    useEffect( () => {
        getCountries()
    },[getCountries])
    
    return <select disabled={disabled} value={value} onChange={ e => onChange( e.target.value)}>
        <option value="">Choose a Country</option>
        {countries.map( country => <option key={country.id} value={country.id}>{country.name}</option>)}
    </select>
}

export default CountrySelector