import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"
import { AppContext } from "../context/AppContext";

const Menu = () => {
    const { t } = useTranslation()
    const {user} = useContext(AppContext)

    return <aside className="menu">
        <Link to="/"><button className="w100 mb10">{t('Home')}</button></Link>
        {user?.isAdmin && <>
            <h5 className="mb10">{t('Administrator')}</h5>
            <Link to="/po-placements"><button className="w100 mb10">{t('PO Placements')}</button></Link>
            <Link to="/po-placements/filters"><button className="w100 mb10">{t('PO Placements - Filters')}</button></Link>
            <Link to="/daily-arrangements"><button className="w100 mb10">Daily Arrangements</button></Link>
            <Link to="/daily-arrangements-consolidated"><button className="w100 mb10">Daily Arrangements Consolidated</button></Link>
            <Link to="/daily-reports"><button className="w100 mb10">Daily Reports</button></Link>
            <Link to="/daily-reports/structure"><button className="w100 mb10">Daily Reports - Structure</button></Link>
            {false && <Link to="/inspections"><button className="w100 mb10">Inspections</button></Link>}
        </>}
        {user?.isBackOffice && <>
            <h5 className="mb10">Back Office</h5>
            <Link to="/daily-reports"><button className="w100 mb10">Daily Reports</button></Link>
            <Link to="/daily-reports/analyst-assignment"><button className="w100 mb10">Daily Reports - Analyst Assignment</button></Link>
            <Link to="/daily-reports/back-office-analysis"><button className="w100 mb10">Daily Reports - Back Office Analysis</button></Link>
            {false && <Link to="/daily-reports/final-report"><button className="w100 mb10">Daily Reports - Final Report</button></Link>}
            <Link to="/back-office/inspections"><button className="w100 mb10">Inspections</button></Link>
        </>}
        {user?.isCoordinator && <>
            <h5 className="mb10">Coordinator</h5>
            <Link to="/coordinator/pos"><button className="w100 mb10">Purchase Orders</button></Link>
            <Link to="/coordinator/programmings"><button className="w100 mb10">Programmings</button></Link>
            <Link to="/coordinator/daily-programmings"><button className="w100 mb10">Daily Programmings</button></Link>
        </>}
        {user?.isTranslator && <>
            <h5 className="mb10">Translator</h5>
            <Link to="/translator/programmings"><button className="w100 mb10">Programmings</button></Link>
            <Link to="/translator/inspections"><button className="w100 mb10">Inspections</button></Link>
        </>}
        {user?.isAnalyst && <>
            <h5 className="mb10">Analyst</h5>
            <Link to="/daily-reports/analysis"><button className="w100 mb10">Daily Reports - Analysis</button></Link>
        </>}
        <hr />
        <Link to="/pos"><button className="w100 mb10">Purchase Orders</button></Link>
        <Link to="/models"><button className="w100 mb10">Models</button></Link>
        <hr />
        <Link to="/customers"><button className="w100 mb10">Customers</button></Link>
        <Link to="/inspectors"><button className="w100 mb10">Inspectors</button></Link>
        <Link to="/locations"><button className="w100 mb10">Locations</button></Link>
        <Link to="/countries"><button className="w100 mb10">Countries</button></Link>
        <Link to="/companies"><button className="w100 mb10">Companies</button></Link>
        <Link to="/providers"><button className="w100 mb10">Providers</button></Link>
        <Link to="/warehouses"><button className="w100 mb10">Warehouses</button></Link>
        <Link to="/custom-brokers"><button className="w100 mb10">Custom Brokers</button></Link>
        <Link to="/ports"><button className="w100 mb10">Ports</button></Link>
        <Link to="/currencies"><button className="w100 mb10">Currencies</button></Link>
        <hr />
        {/* <Link to="/coordinators"><button className="w100 mb10">Coordinators</button></Link> */}
        <Link to="/users"><button className="w100 mb10">Users</button></Link>
    </aside>
}

export default Menu;