import moment from "moment"
import { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import CoordinatorSelector from "../../shared/components/CoordinatorSelector"
import sendRequest from "../../shared/sendRequest"

const DailyArrangementsConsolidated = () => {
    const [filters, setFilters] = useState({
        coordinator:'',
        date: moment().valueOf()
    })
    const [perPage, setPerPage] = useState(25)

    const [pagination, setPagination] = useState({
        page:0,
        perPage:0,
        pages:0,
        count:0
    })
    const [page, setPage] = useState(1)
    const [programmings, setProgrammings] = useState([])
    
    const getProgrammings = useCallback( async() => {
        let coordinatorF = filters.coordinator ? `&coordinator=${filters.coordinator}` : ''
        let dateF = filters.date ? `&date=${filters.date}` : ''
        
        const {programmings,pagination} = await sendRequest('GET',`daily-arrangements/consolidated?perPage=${perPage}&page=${page}${coordinatorF}${dateF}`);
        setPagination(pagination)
        setProgrammings(programmings)
    },[perPage,page, filters]);

    useEffect( () => {
        getProgrammings()
    },[getProgrammings])

    const generateDailyArrangement = () => {
        _downloadExcel( 'daily-arrangements/generate-monthly-excel/' + filters.date )
    }
    const _downloadExcel = async url => {
        try {
            const response = await sendRequest('GET',url)
            const buff = Buffer(response.buffer,'base64');
            var blob = new Blob([buff], {'type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            var downloadURL = URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = downloadURL;
            a.download = response.name;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }catch(er){ console.log(er)} 
    }
    
    return <div className="page">
        <header>
            <div  style={{flex:1}}>
                <div style={{ display:"flex", alignItems: 'center', justifyContent:'space-between'}}>
                    <h3>Daily Arrangements Consolidated</h3>
                    <button className="blue" onClick={() => generateDailyArrangement()}>Daily Arrangement (Excel)</button>
                </div>
            
                <div>
                    Page:
                    <input type="number" className="mr20" style={{width:30, textAlign:"center"}} value={page} onChange={ e => setPage( e.target.value )} />
                    Per page:
                    <input type="number" className="mr20" style={{width:30, textAlign:"center"}} value={perPage} onChange={ e => setPerPage( e.target.value )} />
                    <small className="mr20">Page {pagination.page} of {pagination.pages} </small>
                    <small className="mr20">Showing {(perPage*(page-1)) + 1} to {(perPage*(page-1)) + perPage} of {pagination.count} </small>

                    <div className="floatRight">
                        <input className="mr20" type="date" value={moment(filters.date,'x').format('Y-MM-DD')} onChange={ e => setFilters({...filters,date:moment(e.target.value).valueOf()})}></input>
                        <CoordinatorSelector coordinatorId={filters.coordinator} onChange={ coordinator => setFilters({...filters,coordinator})} />
                    </div>
                </div>
            </div>
    
        </header>
        <main className="scrollable">
            

            <table className="card">
                <thead>
                    <tr>
                        <th></th>
                        <th>Country</th>
                        <th>Company</th>
                        <th>PO nbrs</th>
                        <th>Provider</th>
                        <th>Reference</th>
                        <th>Loading Date</th>
                        <th>Locations</th>
                        <th>Port</th>
                        <th>FCL</th>
                        <th>Charge Count</th>

                        <th>Inspectors</th>
                        <th>RMB Deposit</th>
                        <th>Translator</th>
                        <th>Custom House</th>
                        <th>Containers</th>
                        <th>Observations</th>

                        <th>Coordinator</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {programmings.map( (p, index) => <tr key={p.id}>
                    <td className={`center ${p.parent ? 'yellow' : ''} ${p.hasChanged ? 'red' : ''}`}>{(perPage*(page-1)) + index + 1}</td>
                    <td>{p._country?.name}</td>
                    <td className={`noWrap `}><small>{p._company?.name}</small></td>
                    <td>{p.pos.map( po => <div key={po.id}><small>{po.po_nbr}</small></div>)}</td>
                    <td style={{minWidth:'200px'}}><small>{p._provider?.name}</small></td>
                    <td>{p.reference}</td>
                    <td className={`noWrap center `}>
                        {p.dates.map( dt => <div key={dt}><small>{moment(dt).format('D/MM/YY')}</small></div>)}
                    </td>
                    <td className={`${p.changes.includes('Locations') ? 'yellow' : ''}`} style={{minWidth:'120px'}}>
                        {p.locations?.map( i => <div key={i.id}><small>{i.name}</small> </div>)}
                    </td>
                    <td className={`center `}>{p._port?.name}</td>

                    <td className={`center ${(p.inspection?.noContainers && p.inspection?.noContainers!== p.chargeCount) ? 'red' : ''}`}>{p.inspection?.noContainers ? <b>{p.inspection.noContainers}</b> :  <small>{p.noContainers}</small>}</td>
                    <td className={`center ${(p.inspection?.noContainers && p.inspection?.noContainers!== p.chargeCount) ? 'red' : ''}`}>{p.chargeCount}</td>

                    <td className={`${p.changes.includes('Inspectors') ? 'yellow' : ''}`} style={{minWidth:'120px'}}>
                        {p.inspectors?.map( i => <div key={i.id}><small>{i.name}</small> </div>)}
                    </td>
                    <td className="noWrap"><small>{p.rmbDeposit && p.rmbDeposit.toFixed(2)} {p.rmbDepositCurrency && ` (${p.rmbDepositCurrency.code})`}</small></td>
                    <td>{p.inspection?.translator?.name}</td>
                    <td>{p.inspection?.customBroker?.name}</td>
                    <td>{p.containers?.map( c => <div key={c.id}>{c.container_nbr}</div>)}</td>
                    <td style={{minWidth:'200px'}}><small className="multiLine">{p.notes}</small></td>

                    <td>{p._coordinator?.name}</td>
                    <td><Link to={`/daily-arrangement/${p.id}`}><button>edit</button></Link></td>
                </tr>)}
                </tbody>
            </table>
        </main>
    </div>
}

export default DailyArrangementsConsolidated