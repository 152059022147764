import { useCallback, useEffect, useState } from "react"
import sendRequest from "../sendRequest";

const UserSelector = ( {value, onChange, kind, list }) => {

    const [users, setUsers] = useState(list || [])

    const getUsers = useCallback( async () => {
        if(list) return false
        try{
            const {users} = await sendRequest('GET',`users/kind/${kind}`);
            setUsers(users)
        }catch(er){ console.log(er) }
    
    },[list,kind])
    useEffect( () => {
        getUsers()   
    },[getUsers])

    return <select multiple={false} value={value} onChange={ e => onChange( e.target.value)}>
            <option value="">Select {kind.charAt(0).toUpperCase() + kind.slice(1)}</option>
            {users?.map( user => <option key={user.id} value={user.id}>{user.name} {user?.country?.name && `(${user?.country?.name})` }</option>)}
        </select>
}

export default UserSelector;