import { Link } from "react-router-dom"
import InspectionForm from "../components/InspectionForm"

const CreateInspection = () => {
    return <div className="page">
        <header>
            <h3>Create Inspection</h3>
            <Link to="/inspections"><button className="link">back</button></Link>
        </header>
        <main className="p20 scrollable">
            <InspectionForm />
        </main>
    </div>

}

export default CreateInspection