
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import CountrySelector from "../../shared/components/CountrySelector";
import CurrencySelector from "../../shared/components/CurrencySelector";
import sendRequest from "../../shared/sendRequest";
import KindSelector from "../components/KindSelector";

const InspectorPage = () => {

    const {id} = useParams()

    const navigate = useNavigate();

    const [inspector, setInspector] = useState({
        name:'',
        kind:'',
        manager:'',
        email:'',
        country:'',
        currency:'',
        city:'',
        dailyFee:'',
        notes:''
    })

    const updateInspector = async e =>{
        e.preventDefault()
        await sendRequest('PUT',`inspectors/${inspector.id}`,inspector)
        navigate('/inspectors')
    }

    const [managers, setManagers] = useState([])
    
    const getManagers = async() => {
        const response = await sendRequest('GET','inspectors/managers');
        setManagers(response.managers)
    }

    useEffect( () => {
        getManagers()
    },[])


    const getInspector = useCallback(async() => {
        if(!id) return false;
        const response = await sendRequest('GET','inspectors/' + id);
        setInspector(response.inspector)
    },[id])

    useEffect( () => {
        getInspector()
    },[getInspector])

    return <div className="page">
        <header>
            <h3>Inspector</h3>
            <Link to="/inspectors"><button className="link">{'<'} back</button></Link>
        </header>
        <main className="scrollable">
            {inspector?.id && <form onSubmit={updateInspector} className="card">
                <label>
                    Name
                    <input placeholder="Name" value={inspector.name} onChange={ e => setInspector( state => ({...state, name:e.target.value}))} />
                </label>
                <label>
                    Email
                    <input placeholder="Email" type="email" value={inspector.email} onChange={ e => setInspector( state => ({...state, email:e.target.value}))} />
                </label>
                <label>
                    Kind
                    <KindSelector value={inspector.kind} onChange={ kind => setInspector( state => ({...state,kind}) )}  />
                </label>
                <label>
                    Country
                    <CountrySelector inspectables value={inspector.country} onChange={ country => setInspector( state => ({...state,country}) )} />
                </label>
                <label>
                    City (tmp)
                    <input placeholder="City" value={inspector.city} onChange={ e => setInspector( state => ({...state, city:e.target.value}))} />
                </label>
                <label>
                    Manager
                    <select placeholder="Manager" value={inspector.manager} onChange={ e => setInspector( state => ({...state, manager:e.target.value}))}>
                        <option value="">Choose a Manager</option>
                        {managers?.map( manager => <option key={manager.id} value={manager.id}>{manager.level} - {manager.user?.name}</option>)}
                    </select>
                </label>

                <label>
                    Currency
                    <CurrencySelector value={inspector.currency} onChange={ currency => setInspector( state => ({...state,currency}) )} />
                </label>
                <label>
                    Daily Fee
                    <input placeholder="Daily Fee" value={inspector.dailyFee} onChange={ e => setInspector( state => ({...state, dailyFee:e.target.value}))} />
                </label>
                <label>
                    Notes
                    <textarea placeholder="Notes" value={inspector.notes} onChange={ e => setInspector( state => ({...state, notes:e.target.value}))} />
                </label>
                
                
                <button type="submit">Update Inspector</button>
            </form>}

        </main>
    </div>
}

export default InspectorPage;