import moment from "moment"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import sendRequest from "../../shared/sendRequest"

const InspectorsPage = () => {
    const [inspectors, setInspectors] = useState([])
    
    const getInspectors = async() => {
        const response = await sendRequest('GET','inspectors');
        setInspectors(response.inspectors)
    }

    useEffect( () => {
        getInspectors()
    },[])
    return <div className="page">
        <header>
            <h3>Inspectors</h3>
            <Link to="/inspector-managers"><button>Inspector Managers</button></Link>
            <Link to="/create-inspector"><button className="blue">+ Create Inspector</button></Link>
        </header>
        <main className="scrollable">
            

            <table className="card">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Kind</th>
                        <th>Manager</th>
                        <th>Country</th>
                        <th>City</th>
                        <th>Currency</th>
                        <th>Daily Fee</th>
                        <th>Created</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {inspectors.map( inspector => <tr key={inspector.id}>
                    <td>{inspector.name}</td>
                    <td>{inspector.email}</td>
                    <td>{inspector.kind?.name}</td>
                    <td>
                        {inspector.manager && <span>
                            {inspector.manager?.level}:<br/>
                            {inspector.manager?.user?.name}
                        </span>}
                    </td>
                    <td>{inspector.country?.name}</td>
                    <td>{inspector.city}</td>
                    <td> {inspector.currency?.name} ({inspector.currency?.code})</td>
                    <td>{inspector.dailyFee} {inspector.currency?.symbol}</td>
                    <td>{inspector.createdAt && moment(inspector.createdAt).format('DD/MMM/Y')}</td>
                    <td><Link to={`/inspector/${inspector.id}`}><button>edit</button></Link></td>
                </tr>)}
                </tbody>
            </table>
        </main>
    </div>
}

export default InspectorsPage