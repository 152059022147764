
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import sendRequest from "../../shared/sendRequest";

const CountryPage = () => {

    const {id} = useParams()

    const navigate = useNavigate();

    const [country, setCountry] = useState({
        name:'',
        city:'',
        body:''
    })

    const updateCountry = async e =>{
        e.preventDefault()
        await sendRequest('PUT',`countries/${country.id}`,country)
        navigate('/countries')
    }



    const getCountry = useCallback(async() => {
        if(!id) return false;
        const response = await sendRequest('GET','countries/' + id);
        setCountry(response.country)
    },[id])

    useEffect( () => {
        getCountry()
    },[getCountry])

    return <div className="page">
        <header>
            <h3>Country</h3>
            <Link to="/countries"><button className="link">{'<'} back</button></Link>
        </header>
        <main className="scrollable">
            {country?.id && <form onSubmit={updateCountry} className="card">
                <label>
                    Name
                    <input placeholder="Name" value={country.name} onChange={ e => setCountry( state => ({...state, name:e.target.value}))} />
                </label>
    
                <label>
                    Inspectable
                    <input type="checkbox" checked={country.inspectable} onChange={ e => setCountry( state => ({...state, inspectable:!country.inspectable}))} />
                </label>

                <label>
                    Notes
                    <textarea placeholder="Notes" value={country.notes} onChange={ e => setCountry( state => ({...state, notes:e.target.value}))} />
                </label>
                
                
                <button type="submit">Update country</button>
            </form>}

        </main>
    </div>
}

export default CountryPage;