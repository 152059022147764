import moment from "moment"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import sendRequest from "../../shared/sendRequest"

const CompaniesPage = () => {
    const [companies, setCompanies] = useState([])
    
    const getCompanies = async() => {
        const {companies} = await sendRequest('GET','companies');
        setCompanies(companies)
    }

    useEffect( () => {
        getCompanies()
    },[])
    return <div className="page">
        <header>
            <h3>Companies</h3>
            {false && <Link to="/create-company"><button className="blue">+ Create Company</button></Link> }
        </header>
        <main className="scrollable">
            

            <table className="card">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Client</th>
                        <th>Note</th>
                        <th>Created</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {companies.map( company => <tr key={company.id}>
                    <td>{company.name}</td>
                    <td>{company.client?.name}</td>
                     <td><small>{company.note}</small></td>

                    <td>{company.createdAt && moment(company.createdAt).format('DD/MMM/Y')}</td>
                    {false && <td><Link to={`/company/${company.id}`}><button>edit</button></Link></td>}
                </tr>)}
                </tbody>
            </table>
        </main>
    </div>
}

export default CompaniesPage