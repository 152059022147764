import { useEffect, useState } from "react"
import Card from "../../../shared/components/UIElements/Card"
import { Button } from "../../../shared/components/UIElements/Dumbs"
import Modal from "../../../shared/components/UIElements/Modal"
import sendRequest from "../../../shared/sendRequest"

const ObservationsContainers = ({inspectionId,observations,onUpdate, editable}) => {
    const [_observations,setObservations ] = useState([])


    const [edito,setEditO ] = useState();
    const [editoI,setEditOI ] = useState();
    const [container,setContainer ] = useState();
    const [newObservation,setNewObservation ] = useState('');
    const [showModal,setShowModal ] = useState(false);
    const editObservationC = async c => {
        setContainer(c)
        setShowModal(true)
    }
    const EditObservationHandler = async e => {
        e.preventDefault()
    }
    const updateWasRepaired = async () => {
        await _preserveChanges({...container,itWasRepaired:!container.itWasRepaired})
    }
    const addObservation = async () => {
        if(newObservation){
            await _preserveChanges({...container,observations:[...container.observations,newObservation]})
            setNewObservation('')
        }
    }
    const editObservation = (i) => {
        setEditOI(i)
        setEditO(container.observations[i])
    }
    const deleteObservation =  async () => {
        if(!window.confirm('confirm?')) return false
        let observations = [...container.observations];
        observations.splice(editoI, 1);
        await _preserveChanges({...container,observations})
        setEditOI(null)
        setEditO(null)
    }
    const updateObservation =  async () => {
        let observations = [...container.observations];
        observations[editoI] = edito;
        await _preserveChanges({...container,observations})
        setEditOI(null)
        setEditO(null)
    }
    const _preserveChanges = async (cont) => {
        setContainer(cont)
        let containers = [..._observations.filter( c => c._container!==container._container),cont]
        await sendRequest('PUT',`translator/inspections/${inspectionId}/observations`,{
            containers:containers.sort((a,b) => (a.number > b.number) ? 1 : ((b.number > a.number) ? -1 : 0))
        })
        onUpdate()
    }
    useEffect( () => {
       setObservations(observations)    
    },[observations])
    return <>
    <Card>
        <h4 className=" mt0">Containers observations<br/><small style={{fontWeight:'normal'}}><i>Was it damaged or repaired? / Other comments about containers</i></small></h4>
        {observations?.map( c => <div key={c.id}>
            <b>{c.number} ({c.status}{c.itWasRepaired && ', it was repaired'}) </b> {editable && <Button onClick={() => editObservationC(c)}>edit</Button>}
            <ol>
                {c.observations?.map( (o,i) => <li key={i}>{o}</li>)}
            </ol>
        </div>)}

    </Card>
        {showModal && <Modal onSubmit={EditObservationHandler} hideModal={() => setShowModal(false)}>
            <h3>Container observations "{container.number}"</h3>
           <label style={{flexDirection:'row', alignItems:'center'}}>Status: <b> {container.status}</b></label> 
           <label style={{flexDirection:'row', alignItems:'center'}}>
               It was repaired?
               <input type="checkbox" checked={container.itWasRepaired} onChange={updateWasRepaired} />
           </label>
           <ol>
                {container.observations?.map( (o,i) => <li key={i}>{o} <Button onClick={ () => editObservation(i)} className="link">edit</Button></li>)}
            </ol>
           {edito ?
           <>
           <textarea style={{width:"100%"}} rows={3} value={edito} onChange={ e => setEditO(e.target.value)} placeholder="edit observation"/>
           <Button onClick={updateObservation} type="button">Update</Button><Button onClick={deleteObservation} type="button">Delete</Button>
           </>
           :
           <>
           <textarea style={{width:"100%"}} rows={3} value={newObservation} onChange={ e => setNewObservation(e.target.value)} placeholder="add an observation"/>
           <Button onClick={addObservation} type="button">Add</Button>
           </>
            }
        </Modal>}
    </>
}

export default ObservationsContainers