import { useEffect, useState } from "react"

import Modal from "../../shared/components/UIElements/Modal"
import sendRequest from "../../shared/sendRequest"
import { Button } from "../../shared/components/UIElements/Dumbs"

const AnalystModelEditor = ({drId, model,complement, closeModal,onUpdate}) => {
    const [_model,setModel] = useState({})
    const [_complement,setComplement] = useState({})
    const [columns,setColumns] = useState([])
    const getStructure = async () => {
        try {
            const {columns} = await sendRequest('GET','daily-reports/structure')
            setColumns(columns.filter( c => c.editInAnalysis))
        }catch(er){ console.log(er)} 
    }
    const updateComplement = async e =>{
        e.preventDefault()
        let complement
        if(_complement.id){
            complement = await sendRequest('PUT',`translator/inspections/model-complement/${_complement.id}`,_complement)
        }else{
            complement = await sendRequest('POST',`translator/inspections/model-complement/${drId}`,_complement)
        }
        onUpdate(complement)
        closeModal()
    }
    const signDR = async e =>{
        if(!window.confirm("An item should not be edited once signed!")) return false
        let complement
        if(_complement.id){
            complement = await sendRequest('PUT',`translator/inspections/model-complement/${_complement.id}`,_complement)
        }else{
            complement = await sendRequest('POST',`translator/inspections/model-complement/${drId}`,_complement)
        }
        await sendRequest('PUT',`daily-reports/analyst-sign/${complement._dailyReport}`)
        onUpdate(complement)
        closeModal()
    }

    useEffect( () => {
        setModel(model)
        setComplement(complement || {})//fill structure with columns
    },[model,complement])
    useEffect( () => {
        getStructure()
    },[])
    return <>
        {_model && <Modal className="scrollable" onSubmit={updateComplement} hideModal={closeModal}>
            Model Editor 
            <hr />

            { columns?.map( c => <Input key={c.id} struc={c} 
                                    model={_model?._model?.[c.model]} 
                                    complement={_complement?.[c.destiny] || null} 
                                    onChange={ v => setComplement({..._complement, [c.destiny]:v })}
                                />)}
            <Button type="submit">Save changes</Button>
            <Button type="button" onClick={signDR}>Save and Sign</Button>
    
        </Modal>}
    </>
    
}

const Input = ({struc,model,complement, onChange}) => {
    const [value, setValue] = useState('')
    useEffect( () => {
        setValue(complement || model)
    },[model,complement])

    return <label>
        {struc.name}
        <input type={struc.type} defaultValue={value} onChange={ e => onChange(e.target.value)} />
    </label>
}
export default AnalystModelEditor