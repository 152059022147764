import { useContext, useEffect, useState } from "react";
import InspectionContext from "../context/InspectionContext";

import Modal from "../../shared/components/UIElements/Modal";
import './LoadingOrder.scss'

const LoadingOrder = ({containers}) => {

    const {inspection, setInspection} = useContext(InspectionContext)

    const [showModal,setShowModal ] = useState(false);
    const [showModalOrder,setShowModalOrder ] = useState(false);
    const [orderContainers,setOrderContainers ] = useState();
    const [container,setContainer ] = useState();

    const editOrder = (container) => {
        setContainer(container)
        setShowModalOrder(true)
    }
    const setTipo = (id,value) => {
        let index = (orderContainers.findIndex( c => c.id === id));
        containers = [...orderContainers]
        containers[index].tipo = value
        setOrderContainers(containers)
    }
    const setStatus = (id,value) => {
        let index = (orderContainers.findIndex( c => c.id === id));
        containers = [...orderContainers]
        containers[index].estatus = value
        setOrderContainers(containers)
    }

    const upOrder = (po) => {
        const pos = [...container.po_order] 
        const index = pos.findIndex( p => p===po)
        if(index > 0){
            const tmp = pos[index];
            pos[index] = pos[index - 1]
            pos[index -1] = tmp
        }
        setContainer( {...container,po_order:pos})
    }
    const downOrder = (po) => {
        const pos = [...container.po_order] 
        const index = pos.findIndex( p => p===po)
        if(index < pos.length -1){
            const tmp = pos[index];
            pos[index] = pos[index + 1]
            pos[index +1] = tmp
        }
        setContainer( {...container,po_order:pos})
    }
    const saveOrder = () => {
        let index = (orderContainers.findIndex( c => c.id === container.id));
        containers = [...orderContainers]
        containers[index] = container
        setOrderContainers(containers)
        setShowModalOrder(false)
    }

    useEffect( () => {
        if(containers){
            setOrderContainers(containers)
        }
    },[containers])
    return <>
        {false && <div>
            <h1>Global Inspection</h1>
            <pre>{JSON.stringify(inspection)}</pre>
            <button onClick={() => setInspection({...inspection,newfield:'im testing'})}>Update</button>
        </div>}
        
         <div className="table-responsive">
            <table className="table condensed">
                <thead>
                    <tr>
                        <th> Contenedor</th>
                        <th> Sello </th>
                        <th> Tipo Contenedor </th>
                        <th> Total Ctns</th>
                        <th> Orden de carga </th>
                        <th> Estatus contenedor </th>
                    </tr>
                </thead>
                <tbody>
                    { orderContainers && orderContainers.map( m => <tr key={m.container_nbr} style={{borderBottom:'solid 1px black'}}>
                        <td>{m.container_nbr}</td>
                        <td style={{cursor:'pointer'}} onClick={() => setShowModal(true)}> {m.seal_nbr || <small>Agregar Sello</small>} </td>
                        <td style={{padding:0}}>
                            <div className="tipo_contenedor">
                                <div className={`tipo_contenedor__tipo ${m.tipo === "20'ST" ? 'selected' : ''} `} onClick={ e => setTipo(m.id,"20'ST") }>20'ST</div>
                                <div className={`tipo_contenedor__tipo ${m.tipo === "40'ST" ? 'selected' : ''} `} onClick={ e => setTipo(m.id,"40'ST") }>40'ST</div>
                                <div className={`tipo_contenedor__tipo ${m.tipo === "40'HC" ? 'selected' : ''} `} onClick={ e => setTipo(m.id,"40'HC") }>40'HC</div>
                                <div className={`tipo_contenedor__tipo ${m.tipo === "45'HC" ? 'selected' : ''} `} onClick={ e => setTipo(m.id,"45'HC") }>45'HC</div>
                            </div>
                        </td>
                        <td>{m.total_ctns}</td>
                        <td style={{cursor:'pointer'}} onClick={() => editOrder(m)} >{m.po_order && m.po_order.map( (po,i) => <li key={i}><small>{po}</small></li>)}</td>
                        <td style={{padding:0}}>
                            <div className="tipo_contenedor">
                                <div className={`tipo_contenedor__status ${m.estatus === "Bueno" ? 'selected' : ''} `} onClick={ e => setStatus(m.id,"Bueno") }>Bueno</div>
                                <div className={`tipo_contenedor__status ${m.estatus === "Dañado" ? 'selected' : ''} `} onClick={ e => setStatus(m.id,"Dañado") }>Dañado</div>
                                <div className={`tipo_contenedor__status ${m.estatus === "D. Serio" ? 'selected' : ''} `} onClick={ e => setStatus(m.id,"D. Serio") }>D. Serio</div>
                            </div>
                        </td>
                    </tr>)}
                </tbody>
            </table> 
            </div>   
        
        {showModal && <Modal onSubmit={ e => e.preventDefault()} hideModal={() => setShowModal(false)}>
            Agregar Sello
        </Modal>}
        {showModalOrder && <Modal onSubmit={ e => e.preventDefault()} hideModal={() => setShowModalOrder(false)}>
            Orden
            <table>
                <tbody>
                    {container && container.po_order && container.po_order.map( po => <tr key={po}>
                        <td><button onClick={ () => upOrder(po)}>^</button> <button onClick={ () => downOrder(po)}>v</button></td>
                        <td>{po}</td>
                    </tr>)}
                </tbody>
            </table>
            <button onClick={saveOrder}>Guardar cambios</button>
        </Modal>}
    </>
}

export default LoadingOrder;