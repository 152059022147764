import { useNavigate } from "react-router";
import sendRequest from "../../shared/sendRequest";

const ReferenceGenerator = ({inspection, keyPort, keyCustomer}) => {

    const navigate = useNavigate()

    const generateReference = async () => {
        if(!inspection.loading_date) alert('Es necesario definir la fecha de inspección');
        else if(!inspection.port) alert('Es necesario elegir un puerto');
        else if(!keyPort) alert('Es necesario que el puerto tenga una Clave');
        else if(!inspection.customer) alert('Es necesario elegir un cliente');
        else if(!keyCustomer) alert('Es necesario que el cliente tenga una Clave');
        else {
            try{
                const response = await sendRequest('POST','api/inspections',inspection);
                navigate('/inspection/'+response.inspection.reference)
            }catch(er){
                console.log(er)
            }
        }
    }
    return <>
    <button onClick={generateReference}>Generar referencia ({inspection.customer && keyCustomer}{inspection.port && keyPort}-{inspection.loading_date && inspection.loading_date.slice(0,7)}-...)</button>
    </>
}
export default ReferenceGenerator;