import { useEffect, useState } from "react"
import sendRequest from "../../shared/sendRequest"
import CountrySelector from "../../shared/components/CountrySelector"
import MultiDate from "./MultiDate"
import MultiLocations from "./MultiLocations"
import ReferenceGenerator from "./ReferenceGenerator"
import SelectCustomer from "./SelectCustomer"
import SelectPort from "./SelectPort"
import SelectProvider from "./SelectProvider"
import InspectorsSelector from "../../shared/components/InspectorsSelector"


const dummy = {
    programmed_at: null,
    programming : null, //Object

    loading_date:'',
    inspection_dates:[],
    
    origin_country:'',

    location:'',
    locations:[],

    customer: '',
    provider: '',
    reference: '',
    port: '', 

    inspector:'',
    inspectors:[],

    containers:[],
    pos:[],
    images:[]
};


const InspectionForm = ({insp}) => {

    const [keyCustomer, setKeyCustomer] = useState();
    const [keyPort, setKeyPort] = useState();
    const [inspection, setInspection] = useState(insp ?? dummy);

    const updateInspection = async() => {
        console.log('updating...')
        let payload = {
            origin_country: inspection.origin_country,
            port: inspection.port,
            customer: inspection.customer,
            provider: inspection.provider,
            location: inspection.location,
            locations: inspection.locations,
            inspector: inspection.inspector,
            inspectors: inspection.inspectors,
            inspection_dates: inspection.inspection_dates,
            loading_date: inspection.loading_date
        }
        try {
            const response = await sendRequest('PUT','api/inspections/' + inspection.id,payload)
            console.log(response)
        }catch(er){ console.log(er)} 
    }


    useEffect( () => {
        if(insp)
            setInspection(insp)
    },[insp])

    if(!inspection) return <p>Loading...</p>
    return <div>
        <h1>Reporte de Inspección</h1>
        <div>
            <h3>Información general</h3>

            <label>Fecha de Inspección</label> 
            <MultiDate dates={inspection.inspection_dates} date={inspection.loading_date} 
                onChange={ e => setInspection({...inspection, loading_date: e.date, inspection_dates: e.dates })} 
            />
            <hr />

            <label>Pais de Origen</label> 
            <CountrySelector inspectables value={inspection.origin_country} onChange={ origin_country => setInspection({...inspection, origin_country })} />
            <hr />
            <label>Lugar de Inspección</label> 
            <MultiLocations locations={inspection.locations} location={inspection.location} 
                onChange={ e => setInspection({...inspection, ...e })} 
            />
            
            <label className="my10">
                Inspector
                <InspectorsSelector inspectors={inspection.inspectors} inspector={inspection.inspector} 
                    onChange={ e => setInspection({...inspection, ...e })} 
                />
            </label> 
            <hr />
            <label>Puerto</label> 
            <SelectPort country={inspection.origin_country} value={inspection.port} onChangeKey={ k => setKeyPort(k)} onChange={ e => setInspection({...inspection, port: e})} />
            <hr />

            <label>Nombre del Cliente</label> 
            <SelectCustomer customer={inspection.customer} onChangeKey={ k => setKeyCustomer(k)} onChange={ e => setInspection({...inspection, customer: e})} />
            <hr />

            <label>Nombre del Proveedor</label> 
            <SelectProvider provider={inspection.provider} onChange={ e => setInspection({...inspection, provider: e})} />
            <hr />

            <label>Numero de Referencia</label> 
            {!inspection.id ? <ReferenceGenerator keyPort={keyPort} keyCustomer={keyCustomer} inspection={inspection} /> : inspection.reference}
            <hr />

            {inspection.id && <button onClick={updateInspection}>Actualizar Información General</button>}
            {false && <pre>{JSON.stringify(inspection,null,2)}</pre>}
        </div>
    </div>
}
export default InspectionForm