import moment from "moment"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import sendRequest from "../../shared/sendRequest"

const CountriesPage = () => {
    const [countries, setCountries] = useState([])
    
    const getCountries = async() => {
        const {countries} = await sendRequest('GET','countries');
        setCountries(countries)
    }

    useEffect( () => {
        getCountries()
    },[])
    return <div className="page">
        <header>
            <h3>Countries</h3>
            <Link to="/create-country"><button className="blue">+ Create Country</button></Link>
        </header>
        <main className="scrollable">
            

            <table className="card">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Inspectable</th>
                        <th>Notes</th>
                        <th>Created</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {countries.map( country => <tr key={country.id}>
                    <td>{country.name}</td>
                    <td>{country.inspectable ? 'yes' : 'no'}</td>
                    <td><small>{country.notes}</small></td>
                    <td>{country.createdAt && moment(country.createdAt).format('DD/MMM/Y')}</td>
                    <td><Link to={`/country/${country.id}`}><button>edit</button></Link></td>
                </tr>)}
                </tbody>
            </table>
        </main>
    </div>
}

export default CountriesPage