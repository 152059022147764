import { useCallback, useEffect, useState } from "react"
import sendRequest from "../sendRequest";

const CurrencySelector = ({ value, onChange}) =>{
    
    const [currencies, setCurrencies] = useState([])

    const getCurrencies = useCallback( async () => {
        try{
            const response = await sendRequest('GET',`currencies`);
            setCurrencies(response.currencies)
        }catch(er){ console.log(er) }

    },[])
    useEffect( () => {
        getCurrencies()
    },[getCurrencies])
    
    return <select value={value} onChange={ e => onChange( e.target.value)}>
        <option value="">Choose a Currency</option>
        {currencies.map( currency => <option key={currency.id} value={currency.id}>{currency.symbol} {currency.name} ({currency.code})</option>)}
    </select>
}

export default CurrencySelector