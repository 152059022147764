import { useEffect, useState } from "react"
import Card from "../../../shared/components/UIElements/Card"
import { Button } from "../../../shared/components/UIElements/Dumbs"
import Modal from "../../../shared/components/UIElements/Modal"
import sendRequest from "../../../shared/sendRequest"

const defaultS = {
    observations:[]
}
const Observations = ({inspectionId,observations,onUpdate}) => {
    
    const [_observations,setObservations ] = useState(observations || {...defaultS})


    const [edito,setEditO ] = useState();
    const [editoI,setEditOI ] = useState();
    const [newObservation,setNewObservation ] = useState('');
    const [showModal,setShowModal ] = useState(false);

    const editObservationC = async () => {
        setShowModal(true)
    }
    const EditObservationHandler = async e => {
        e.preventDefault()
    }

    const addObservation = async () => {
        if(newObservation){
            await _preserveChanges({..._observations,observations:[..._observations.observations,newObservation]})
            setNewObservation('')
        }
    }
    const editObservation = (i) => {
        setEditOI(i)
        setEditO(_observations.observations[i])
    }
    const deleteObservation =  async () => {
        if(!window.confirm('confirm?')) return false
        let observations = [..._observations.observations];
        observations.splice(editoI, 1);
        await _preserveChanges({..._observations,observations})
        setEditOI(null)
        setEditO(null)
    }
    const updateObservation =  async () => {
        let observations = [..._observations.observations];
        observations[editoI] = edito;
        await _preserveChanges({..._observations,observations})
        setEditOI(null)
        setEditO(null)
    }

    const _preserveChanges = async (other) => {
        await sendRequest('PUT',`translator/inspections/${inspectionId}/observations`,{ other})
        onUpdate()
    }
    useEffect( () => {
       setObservations({...defaultS,...observations})    
    },[observations])
    return <>
    <Card>
            

        <h4 className=" mt0">Other observations</h4>

        {_observations && <div>
            <ol>
                {_observations.observations?.map( (o,i) => <li key={i}>{o}</li>)}
            </ol>
        </div>}
        <Button onClick={() => editObservationC()}>edit</Button>


    </Card>
        {showModal && <Modal onSubmit={EditObservationHandler} hideModal={() => setShowModal(false)}>
            <h3>Other observations</h3>

           <ol>
                {_observations.observations?.map( (o,i) => <li key={i}>{o} <Button onClick={ () => editObservation(i)} className="link">edit</Button></li>)}
            </ol>

           {edito ?
           <>
           <textarea style={{width:"100%"}} rows={3} value={edito} onChange={ e => setEditO(e.target.value)} placeholder="edit observation"/>
           <Button onClick={updateObservation} type="button">Update</Button><Button onClick={deleteObservation} type="button">Delete</Button>
           </>
           :
           <>
           <textarea style={{width:"100%"}} rows={3} value={newObservation} onChange={ e => setNewObservation(e.target.value)} placeholder="add an observation"/>
           <Button onClick={addObservation} type="button">Add</Button>
           </>
            }
        </Modal>}
    </>
}

export default Observations